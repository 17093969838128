.reimburserdArea {
  .none {
    display: none;
  }
  .option {
    label {
      margin-bottom: 0px;
    }
  }
}
