@import '../../../../../../../../styles/themes/themes.scss';

.personalArea {
  .fontGray12 {
    @include themify('color', 'tertiary');
    font-size: 14px;
  }
  .none {
    display: none;
  }
  .options {
    margin-bottom: 6px;
    .option {
      label {
        margin-bottom: 0px;
      }
    }
  }
}

.labelTitle {
  font-size: 16px;
  @include themify('color', 'subtitle');
  margin-bottom: 24px;
}

.checkBox{
  margin: 0px;
}

.labelTitleCheckbox {
  font-size: 16px;
  @include themify('color', 'subtitle');
}

.disclaimerCheckbox {
  font-size: 16px;
  @include themify('color', 'subtitle');
  margin-bottom: 24px;
}

.noTopMargin{
  margin-top: 0px;
}
