@import '../../../../styles/colors';
@import '../../../../styles/themes/themes.scss';
@import '../../../../styles/fonts';

.containerDownloadFooter {
  .buttonStore {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    a {
      margin-top: 30px;

      &:first-child {
        margin-right: 5px;
      }
      &:last-child {
        margin-left: 5px;
      }

      img {
        max-width: 150px;
      }
    }

    @media screen and (max-width: 992px) {
      justify-content: center;

      a {
        img {
          max-width: 120px;
        }
      }
    }
  }
}

.container {
  flex: 1 1 auto;
  padding-top: 10rem;
  padding-bottom: 170px;
  margin-bottom: 20px;

  @include themify-half-color-gradient();

  .title {
    width: 100%;
    @include themify('color', 'text-inverse_title');
    font-size: 50px;
    font-weight: 300;
    padding: 0 10rem;
    margin-bottom: 20px;
  }

  .subtitle {
    font-family: $font-regular;
    width: 100%;
    @include themify('color', 'text-inverse_title');
    font-size: 18px;
    padding: 0 10rem;
    margin-bottom: 45px;
    display: block;
  }

  .appointmentsArea {
    padding: 0 10rem;
    .subHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
      button {
        margin-bottom: 40px;
        width: fit-content;
      }
    }
    .requestLink {
      margin-bottom: 24px;
    }
  }
}

.loading {
  text-align: center;
  @include themify('color', 'primary-primary');
  margin-bottom: 50px;
  margin-top: 50px;
}

@media (max-width: 990px) {
  .container {
    padding-top: 100px;

    .title {
      padding: 0 3rem;
      font-size: 40px;
    }
    .subtitle {
      padding: 0 3rem;
      font-size: 16px;
    }
    .appointmentsArea {
      padding: 0 3rem;

      .requestLink {
        margin-bottom: 12px;
      }
    }
  }
}
