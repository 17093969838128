@import '../../styles/style.scss';

.container {
  width: 70%;

  .containerHeader {
    max-width: 700px;

    h1 {
      @include themify('color', 'primary-primary');
      font-size: 48px;
      line-height: 120%;
      margin-bottom: 18px;
    }
    p {
      @include themify('color', 'primary-primary');
      font-size: 18px;
      line-height: 120%;
      margin-bottom: 18px;
      text-align: justify;
    }
  }
}

@media (max-width: $width-large) {
  .container {
    width: 100%;

    .containerHeader {
      h1 {
        font-size: 36px;
      }
      p {
        font-size: 16px;
      }
    }
  }
}
