@import '../../styles/colors';
@import '../../styles/media_queries';
@import '../../styles/themes/themes.scss';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  padding: 0rem 1rem;
  z-index: 990;
  width: 100%;
  animation: appear ease-out 1.5s;
  overflow: hidden;
  background-color: #eaa8b3;

  @media screen and (min-width: 400px) and (max-width: 1280px) {
    padding: 1.6rem 2rem;
  }

  @media screen and (min-width: 1280px) {
    padding: 1.6rem 9rem;
  }

  p {
    color: #000;
    margin: 0;
    font-size: 2rem;

    @media screen and (max-width: $width-extra-large) {
      font-size: 1.6rem;
    }
  }
}

@keyframes appear {
  0% {
    height: 0px;
  }
  100% {
    height: 4rem;
    @media screen and (max-width: $width-medium) {
      height: 6rem;
    }

    @media screen and (max-width: $width-large) {
      height: 4rem;
    }
  }
}
