.textDeletedCard{
  margin-bottom: 20px;
}
.boxAdvancedSearch{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  .listCards {
    width: 300px;
    margin-top: 10px;
  }
  .searchButton {
    width: 180px;
    margin-right: 20px;
  }

  @media screen and (max-width: 600px) {
    .searchButton,
    .listCards {
      width: 100%;
    }
    .searchButton {
      margin-top: 15px;
    }
  }
}
