@import "../../../styles/themes/themes.scss";
@import "../../../styles/fonts";

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-top: 0.25rem;
}

.label {
  margin-bottom: 12px;
  font-size: 12px;
  font-weight: bold;
  font-family: $font-regular;
  @include themify('color', 'primary-primary');
}