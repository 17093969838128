@import '../../../../styles/style.scss';

.container {
  position: relative;
  font-family: $font-light;
  min-height: 340px;

  .banner {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .title {
    width: 100%;
    @include themify('color', 'text_and_background');
    font-size: 50px;
    font-weight: 300;
    padding: 0 9rem;
    margin-bottom: 20px;
  }

  .subtitle {
    font-family: $font-regular;
    width: 100%;
    @include themify('color', 'text_and_background');
    font-size: 18px;
    padding: 0 9rem;
    margin-bottom: 125px;
    display: block;
  }
  .noHaveClaim {
    font-size: 18px;
  }

  .itens {
    padding: 0 9rem;
    z-index: 1;
    width: 100%;

    .subTitleRecentClaims {
      @include themify('color', 'primary');
      font-weight: normal;
      margin-bottom: 2rem;
      font-size: 2rem;
    }

    .subTitleRecentClaims2 {
      @include themify('color', 'tertiary');
      font-weight: normal;
      margin-bottom: 2rem;
      font-size: 2rem;
    }

    ul {
      list-style: none;
    }

    .allCLaims {
      @include themify('color', 'primary');
      width: 100%;
      display: block;
      text-align: right;
      font-family: $font-semibold;
      font-size: 16px;
      font-weight: 900;
      text-decoration: none;

      svg {
        margin-left: 8px;
        padding-top: 2px;
      }
    }
    @media (min-width: 1200px) {
      max-width: 1920px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .subtitleSimpleBanner {
    width: 100%;
    @include themify('color', 'primary');
    font-size: 26px;
    font-weight: 300;
    padding: 0 10rem;
  }

  .loadingContainer {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 990px) {
  .container {
    .title {
      top: 60px;
      padding: 0 3rem;
    }

    .subtitle {
      top: 140px;
      padding: 0 3rem;
    }

    .subtitleSimpleBanner {
      padding: 0 3rem;
    }

    .itens {
      padding: 0 3rem;
      top: 230px;
    }
  }
}
