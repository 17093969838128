@import '../../../../styles/style.scss';

.container {
  margin-top: 40px;
  @include themify('color', 'primary');

  .titleContainer {
    margin-bottom: 30px;

    h2 {
      font-size: 26px;
      @include themify('color', 'primary');
    }
  }

  .subtitleContainer {
    margin-bottom: 30px;

    h4 {
      font-size: 18px;
      @include themify('color', 'subtitle');
    }
  }

  .labelTitle {
    margin-bottom: 15px;
    font-size: 16px;
    font-family: $font-regular;
    @include themify('color', 'link');
  }

  .footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-top: 15px;
    margin-top: 15px;

    .divButton {
      width: 180px;
      height: 53px;
    }

    @media (max-width: 575px) {
      .divButton {
        button {
          width: 95%;
        }
      }

      .divButton+.divButton {
        display: flex;
        justify-content: flex-end;
      }

      .divButton {
        width: 100%;
      }
    }
  }
}