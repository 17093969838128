@import '../../styles/style.scss';

.bannerCinepolis {
  display: flex;
  width: 100%;
  background-image: url('../../assets/images/cinepolis/Banner_cinepolis_background.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 8rem 10rem 4rem;
  max-height: 340px;
  margin: 2rem 0;

  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    color: #fff;

    .title {
      font-size: 40px;
      font-family: $font-semibold;
      max-width: 50%;
      margin-bottom: 20px;
    }

    footer {
      display: flex;
      flex-direction: row-reverse;
      padding-top: 20px;
    }
  }
}

@media screen and (max-width: 1450px) {
  .bannerCinepolis {
    padding: 5rem 5rem 3rem;
  }
}

@media screen and (max-width: $width-large) {
  .bannerCinepolis {
    .content {
      .title {
        font-size: 30px;
        max-width: 100%;
      }

      footer {
        flex-direction: row;
        img {
          width: 150px;
        }
      }
    }
  }
}

@media screen and (max-width: $width-small) {
  .bannerCinepolis {
    .content {
      .title {
        font-size: 26px;
      }

      footer {
        img {
          width: 120px;
        }
      }
    }
  }
}
