@import '../../styles/_colors.scss';
@import '../../styles/_fonts.scss';
@import '../../styles/themes/themes.scss';

.menuLeft {
  display: flex;
  width: 360px;
  min-height: 100%;
  -webkit-box-shadow: 7px 0px 12px -6px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 7px 0px 12px -6px rgba(0, 0, 0, 0.2);
  box-shadow: 7px 0px 12px -6px rgba(0, 0, 0, 0.2);

  position: relative;
}

.menuList {
  display: flex;
  width: 100%;
  height: fit-content;
  position: sticky;
  top: 30px;
  overflow: auto;
  padding: 10rem 1rem 5rem 9rem;

  ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    width: 100%;

    li {
      display: flex;
      padding-bottom: 15px;
      font-weight: 300;
      flex-direction: column;

      a {
        @include themify('color', 'primary-primary');
        font-weight: 300;
        font-size: 16px;
      }

      .icon {
        margin-right: 20px;
      }

      .iconClaimProfile {
        margin-left: -3px;
        margin-right: 23px;
      }
    }
  }

  .menuActive {
    font-weight: bold;
  }
}

.submenuList {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 10px;

  ul {
    list-style: none;

    li {
      display: flex;
      padding: 8px 2px 8px 20px;
      justify-content: flex-start;
      align-items: center;
      display: flex;

      a {
        @include themify('color', 'primary-primary');
        font-weight: 300;
        font-size: 16px;
        width: 100%;
        padding-left: 11px;
        border-left: 1px solid transparent;
      }

      .icon {
        margin-right: 15px;
      }

      .iconClaimProfile {
        margin-left: -3px;
        margin-right: 18px;
      }
    }
  }
  .submenuActive {
    border-left: 1px solid;
    @include themify('border-color', 'secondary-secondary');
    font-weight: bold;
  }
}

@media (max-width: 992px) {
  .menuLeft {
    display: none;
  }
  .submenuList {
    padding-right: 20px;
    ul {
      display: flex;
      width: 100vw;
      overflow-x: auto;
      white-space: nowrap;
      margin-bottom: 0;
      padding-bottom: 0;
      padding-right: 20px;
      li {
        padding-left: 20px;
        a {
          padding-left: 0;
        }
      }
    }
    .submenuActive {
      border-left: none;
      text-decoration: none;
    }
  }
}


