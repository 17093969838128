@import '../../styles/style.scss';
@import '../../styles/utils';

.container {
  margin-right: 10px;
  @media screen and (max-width: 992px) {
    margin-right: 0px;
  }

  .searchInputs {
    display: flex;
    align-items: center;

    .innerMobileContainer {
      background-color: #FFFFFF;
      position: absolute;
      width: 240px;
      top: 80px;
      right: 10%;
      display: flex;
      align-items: center;
      @media (max-width: 992px) {
        left: 10%;
        width: 80%;
      }

      input {
        font-size: 15px;
        padding: 15px;
        border: 0;
        height: 45px;
        font-family: $font-light;
        background-color: #FFFFFF;
        flex: 1 1;
        &:focus {
          outline: none;
        }
      }

      .closeIcon {
        @include themify('color', 'primary-primary');
        width: 20px;
        font-size: 20px;
        margin-right: 10px;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .mobileFiltered {
    position: absolute;
    top: calc(80px + 45px + 5px);
    width: 240px;
    right: 10%;

    @media (max-width: 992px) {
      left: 10%;
      width: 80%;
    }

    .filteredData {
      position: relative;
      @include themify('background-color', 'surface-surface');
      @include themify('color', 'text-paragraph_subtle');
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      overflow: hidden;
      overflow-y: auto;

      .dataItem {
        width: 100%;
        min-height: 35px;
        margin-top: 5px;
        display: flex;
        align-items: center;
        @include themify('color', 'text-paragraph_subtle');

        &:hover {
          @include themify('color', 'primary-primary');
          font-weight: bold;
          border-bottom: none !important;
        }

        span {
          margin-left: 10px;
          font-size: 15px;
        }
      }

      .emptyMessage {
        height: 50px;
        width: 100%;
        display: flex;
        align-items: center;
        span {
          margin-left: 10px;
          font-size: 15px;
        }
      }
    }
  }
}

.filteredData::-webkit-scrollbar {
  display: none;
}
