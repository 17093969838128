@import '../../../styles/themes/themes.scss';
@import '../../../styles/media_queries';

.personalInformation {
  footer {
    padding-top: 40px;
  }
}

@media (max-width: $width-small) {
  .personalInformation {
    footer {
      .wrapperButton {
        width: 100%;
      }
    }
  }
}
