.formTeleconsultation {
  .footerBtns {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    padding: 0px 15px;
    @media (max-width: 760px) {
      flex-direction: column;
    }
    .back {
      min-width: 102.88px;
      @media (max-width: 768px) {
        width: 100%;
        margin-bottom: 10px;
        button {
          width: 100%;
          max-width: 100%;
        }
      }
    }
    .continue {
      min-width: 158.61px;
      @media (max-width: 768px) {
        width: 100%;
        margin-bottom: 10px;
        button {
          width: 100%;
          max-width: 100%;
        }
      }
    }
    .claimBtn {
      button {
        text-transform: initial !important;
        height: 49px !important;
        font-size: 17.5px !important;
      }
    }
  }
}
