@import '../../styles/style.scss';

.fileUploaderContainer {
  border-radius: 8px;
  border: 2px solid $gray_10;
  width: 100%;

  .label {
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
  }

  .fileUploaderBody {
    display: flex;
    flex-direction: column;
    gap: 32px;
    align-self: stretch;
    width: 100%;

    background: $white;
    padding: 48px;

    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;

    &.borderNoLabel {
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
    }

    .bodyHeader {
      h5 {
        @include themify('color', 'primary');
        font-size: 23px;
        font-weight: bold;
        line-height: 120%;
        font-family: $font-semibold;
      }
      p {
        color: #000;
        font-size: 16px;
        line-height: 150%;
        font-family: $font-regular;
        white-space: pre-line;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      gap: 16px;

      ul {
        list-style-type: none;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        margin: 0;
      }
    }

    .actions {
      .wrapperButton {
        width: fit-content;
        button {
          padding: 12px 24px;
        }
      }
    }

    @media screen and (max-width: $width-medium) {
      padding: 24px;
    }

    @media screen and (max-width: $width-medium) {
      padding: 12px;
    }
  }
}
