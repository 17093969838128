@import '../../../styles/media_queries';
@import '../../../styles/fonts';
@import '../../../styles/themes/themes.scss';

.container {
  display: flex;
  flex-direction: column;
  padding: 0 9rem;
  margin-bottom: 10rem;

  .title {
    @include themify('color', 'primary');
    font-size: 35px;
    font-weight: 300;
    margin: 2rem 0;
    font-family: $font-light;
  }
  .loadingContainer{
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 992px) {
  .container {
    padding: 0 2rem;

    .title {
      font-size: 28px;
    }
  }
}

@media(min-width: 1200px) {
  .container {
    max-width: 1760px;
    margin-left: auto;
    margin-right: auto;
  }
}
