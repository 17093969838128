@import '../../styles/colors';
@import '../../styles/fonts';
@import '../../styles/themes/themes.scss';
@import '../../styles/media_queries';

.tooltipText {
  padding: 0 0.75rem;
  font-size: 14px;
  font-weight: 400;
  @include themify('color', 'link');
  text-align: center;
  background-color: transparent !important;
  cursor: default;
  position: relative;

  img {
    width: 20px;
    height: 20px;
  }
}

[data-tooltip] {
  &:hover {
    &:before,
    &:after {
      max-width: 280px !important;
      font-size: 15px !important;
      text-align: left !important;
      font-family: $font-light;
      background-color: $white !important;
      display: block;
      position: absolute;
      right: 0px !important;
      top: 62px !important;
      color: #000;
      font-size: 0.7rem;
      -webkit-box-shadow: 0px 9px 11px 5px rgba(0, 0, 0, 0.18);
      -moz-box-shadow: 0px 9px 11px 5px rgba(0, 0, 0, 0.18);
      box-shadow: 0px 9px 11px 5px rgba(0, 0, 0, 0.18);
      white-space: pre-line;
      z-index: 5;
    }
    &:before {
      border-radius: 0.2em;
      content: attr(datatitle);
      margin-top: -1.5em;
      padding: 0.4em;
      width: 300px;
      @media screen and (max-width: $width-large) {
        width: 200px;
      }
    }
    &:after {
      margin-top: -2.1em;
      margin-left: 1em;
      border-style: solid;
      border-color: transparent;
      border-top-color: black;
      border-width: 0.5em 0.5em 0 0.5em;
    }
  }
}
