@import '../../../../../../styles/style.scss';

.uploadContainer {
  padding: 25px 30px;
  min-height: 104px;
  margin: 15px 0 0;
  -webkit-box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1);
  background: $white;
  border-radius: 3px;

  @media screen and (max-width: $width-medium) {
    padding: 12px;
  }

  @media screen and (max-width: $width-small) {
    padding: 8px;
  }
  
  @media (forced-colors: active) {
    border: 1px solid;
  }

  list-style: none;
}
