@import '../../styles/media_queries';
@import '../../styles/colors';
@import '../../styles/themes/themes.scss';

.loadingContainer {
  width: 100%;
  display: flex;
  height: 100px;
  align-items: center;
}

.container {
  display: flex;
  width: 100%;
  min-height: 100px;
  flex-direction: column;

  .contentTitle {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3rem;

    .title {
      @include themify('color', 'primary');
      font-size: 25px;
      margin: 0;
    }

    button {
      width: fit-content;
      .titleAddWeb {
        display: flex;
      }
      .titleAddMobile {
        display: none;
      }
    }
  }
}

.changePositionCards {
  margin-left: 0 !important;
}

@media (max-width: 768px) {
  .container {
    .contentTitle {
      .title {
        font-size: 20px;
      }

      button {
        .titleAddWeb {
          display: none;
        }
        .titleAddMobile {
          display: flex;
        }
      }
    }
  }
}
