@import '../../../styles/style.scss';

.benefitsContainer {
  margin-top: 150px;

  h3 {
    @include themify('color', 'primary');
    font-size: 40px;
    font-family: $font-semibold;
    text-align: center;
    margin-bottom: 30px;
  }

  ul {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;

    li {
      @include themify('background', 'const_light_gray');
      margin: 1%;
      padding: 10px 15px 5px;
      border-radius: 10px;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      flex: 1;
      flex-basis: 22%;

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        flex-basis: 30%;
      }

      p {
        color: #000;
        font-size: 23px;
        margin: 0;
        text-align: center;
        max-width: 80%;
        font-family: $font-semibold;
      }
    }
  }
}

@media (max-width: 1150px) {
  .benefitsContainer {
    margin-top: 100px;
    h3 {
      font-size: 25px;
    }

    ul{
      li{
        p{
          font-size: 18px;
        }
      }
    }
  }
}

@media(max-width: 500px){
  .benefitsContainer{
    ul{
      li{

        img{
          height: 40px;
        }
        p{
          font-size: 14px;
        }
      }
    }
  }
}
