@import '../../styles/fonts';
@import '../../styles/colors';
.main {
  // background: $color-blue;
  width: 100vw;
  height: calc(100vh - 270px);
  display: flex;
  align-items: center;
  justify-content: center;
  div {
    text-align: center;
  }
}
