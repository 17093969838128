@import '../../styles/media_queries';

.dashboard {
  margin: 3% 0;
  padding: 0 9rem;

  @media (max-width: $width-large) {
    padding: 0 2rem;
    margin: 30px 0;
  }
}
