@import '../../styles/colors';
@import '../../styles/fonts';
@import '../../styles/themes/themes.scss';

.acordeonSession {
  box-shadow: 0px 0px 38px 1px rgba(0, 0, 0, 0.18);
  margin-bottom: 18px;
  -webkit-box-shadow: 0px 0px 38px 1px rgba(0, 0, 0, 0.18);
  -moz-box-shadow: 0px 0px 38px 1px rgba(0, 0, 0, 0.18);
  cursor: pointer;
  @include themify('background', 'text_and_background');
  padding: 26px 35px;
  border-radius: 3px;

  @media (forced-colors: active) {
    border: 1px solid;
  }

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      margin-right: 1%;
      .icon {
        @include themify('color', 'text_active_element');
        @include themify('stroke', 'secondary-secondary');
        height: 20px;
        width: 20px;
      }
      .iconAXA {
        @include themify('color', 'const_axa_blue');
        @include themify('stroke', 'secondary-secondary');
        height: 20px;
        width: 20px;
      }
    }
    h6 {
      @include themify('color', 'primary');
      display: inline;
      font-size: 16px;
      font-weight: normal;
      line-height: 24px;
      font-family: $font-regular;
      font-weight: 600;
    }
    h6.axa {
      @include themify('color', 'const_axa_blue');
    }
  }

  @media (max-width: 975px) {
    border: 0;
    -webkit-box-shadow: 0;
    -moz-box-shadow: 0;
    box-shadow: 0;
    &.contentForm {
      -webkit-box-shadow: 0px;
      -moz-box-shadow: 0px;
      box-shadow: 0px;
    }
  }

  @media (min-width: 1200px) {
    max-width: 1760px;
    margin-left: auto;
    margin-right: auto;
  }
}

.acordeonOpen {
  padding: 40px;
  .title {
    h6 {
      font-size: 26px !important;
      @include themify('color', 'primary');
      font-family: $font-light !important;
      font-weight: normal;
    }
    h6.axa {
      @include themify('color', 'const_axa_blue');
      font-family: $font-regular !important;
    }
  }
  .containerForm {
    border-top: 1px solid;
    @include themify('border-color', 'const_gray');
    margin-top: 15px;

    .mandatoryMessage {
      font-size: 12px;
      margin-bottom: 34px;
    }

    .footer {
      width: 100%;
      display: flex;
      justify-content: space-between;
      border-top: 1px solid;
      @include themify('border-color', 'const_gray');
      padding-top: 15px;
      margin-top: 46px;

      .divButton {
        button {
          width: 180px;
        }
      }

      .hiddenButton {
        opacity: 0;
      }
    }
  }
}

@media (max-width: 992px) {
  .acordeonSession {
    padding: 20px 4%;
  }
  .acordeonOpen {
    padding: 20px;
  }
}

@media (max-width: 575px) {
  .acordeonSession {
    padding: 4%;
  }
  .acordeonOpen {
    .title {
      h6 {
        font-size: 22px !important;
      }
    }
    .containerForm {
      .footer {
        .divButton {
          button {
            width: 95%;
          }
        }
        .divButton + .divButton {
          display: flex;
          justify-content: flex-end;
        }
        .divButton {
          width: 100%;
        }
      }
    }
  }
}
