@import '../../../../../styles/style.scss';

.marginBottom {
  margin-bottom: 40px;

  @media (max-width: 540px) {
    margin-bottom: 60px;
  }

  @media (max-width: 720px) {
    margin-bottom: 50px;
  }
}

.labelCard{
  font-size: 16px;
  @include themify('color', 'const_axa_blue');
  font-family: $font-regular;
}
