@import '../../../../../styles/themes/themes.scss';

p.titleVerification {
  font-size: 15px;
  margin: 5px 0;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;

  table {
    border: 1px solid;
    @include themify('border-color', 'const_gray');
    font-size: 15px;

    tr {
      border-bottom: 1px solid;
      @include themify('border-color', 'const_gray');

      td,
      th {
        padding: 5px;

        &:first-child {
          border-right: 1px solid;
          @include themify('border-color', 'const_gray');
        }
      }
      .travellers {
        text-align: left;
      }
      .amount {
        text-align: right;
      }
    }
  }
}
