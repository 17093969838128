@import '../../../styles/style.scss';

$prismicThemes: (
  afluent: 'Affluent',
  master: 'Master',
  portoBank: 'Affluent'
);

@mixin themify-theme() {
  @each $theme, $colors in $themes {
    .theme.theme-#{$theme} {
      ul {
        li div {
          border-color: get-theme-color($theme, 'secondary-secondary') !important;
          font-size: 16px;
        }
      }
      a {
        color: get-theme-color($theme, 'primary-primary');
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      .productName {
        color: get-theme-color($theme, 'primary-primary');
      }
      .divisor {
        border: 1px solid get-theme-color($theme, 'secondary-secondary');
      }

      .blueRectangle {
        background-color: get-theme-color($theme, 'primary-primary');
      }
      .blueRectangle button{
        background-color: get-theme-color($theme, 'secondary-inverse');
        border-color: get-theme-color($theme, 'secondary-inverse');
        a {
          color: black !important;
        }
      }

      .blueBoxBackground {
        background-color: get-theme-color($theme, 'primary-primary');
        padding: 30px 30px 10px 30px;

        .divisor{
          border: 1px solid get-theme-color($theme, 'secondary-inverse');
        }

        p {
          color: get-theme-color($theme, 'text-inverse_paragraph') !important;
        }
        h2 {
          color: get-theme-color($theme, 'secondary-inverse') !important;
          display: flex;
          align-self: start;
        }
      }

      .productInlineBorder {
        border-color: get-theme-color($theme, 'secondary-secondary') !important;
      }

      .themeItemTitle {
        color: get-theme-color($theme, 'primary-primary') !important;
      }

      @each $curr-theme, $colors in $themes {
        @if $theme != $curr-theme {
          .theme#{map-get($prismicThemes, $curr-theme)} {
            display: none;
          }
        } @else {
          .theme#{map-get($prismicThemes, $curr-theme)} {
            display: inline!important;
          }
        }
      }
    }
  }
}

.terms {
  font-size: 16px;
  margin-top: 50px;
  font-style: italic;
  color: #6b6b6b;
  p {
    line-height: 1.5;
    margin-bottom: 2rem;
  }
  .fakeLink {
    font-weight: bold;
    &:hover {
      text-decoration: underline !important;
      cursor: pointer;
    }
  }
}

.whiteLink{
  color: white !important;
}

.note{
  font-size: 16px;
  font-style: italic;
  color: #6b6b6b;
  margin-top: 0px !important;
  p {
    line-height: 1.5;
    margin-bottom: 2rem;
    text-align: left !important;
  }
}

.center {
  text-align: center !important;
  .cardsImg{
    
    @media (max-width: 631px) {
      max-width: 392.7px;
      height: auto;
    }

  }
}

.justify {
  text-align: justify !important;
}

@include themify-theme();

.prismicContent,
.insidePrismic {
  font-family: 'visa_dialect_light', Helvetica, 'Helvetica Neue';

  .componentContainer {
    padding-bottom: 60px;
  }

  .lastContainer {
    padding-bottom: 10px !important;
  }

  @media (min-width: 1200px) {
    max-width: 1760px;
    margin-left: auto;
    margin-right: auto;
  }

  .title {
    font-size: 35px;
    text-align: center;
    font-family: 'visa_dialect_bold';
  }

  .minorTitle {
    font-size: 18px;
    font-family: 'visa_dialect_bold';
    color: #021e4c;
  }

  .subtitle {
    display: flex;
    justify-content: center;
    font-weight: bold;
    color: #021e4c;
    margin-bottom: 30px;
  }

  .imageWithItemsContainer {
    justify-content: space-evenly !important;
    li{
      background-color: #F0F0F0;
      margin-bottom: 10px !important;
      border-radius: 10px;
      img{
        height: 48px;
        width: 48px;
      }
    }
    img{
      max-width: 360px;
      max-height: 619px;
    }

    @media (max-width: 576px) {
      display: block !important;
      text-align: center;
      ul{
        align-items: center !important;
      }
    }
  }

  table {
    border-collapse: collapse;
  }
  th,
  td {
    border-bottom: 1px solid #eaeef0;
    padding: 8px;
  }

  .list-with-points {
    justify-content: flex-start;
    flex-direction: column;
    padding-left: 20px;

    li {
      font-size: 16px;
      margin-bottom: 10px;
      line-height: 32px;
    }
  }

  .description,
  .description-center {
    color: #000;
    font-size: 18px;
    margin-bottom: 30px;
    line-height: 32px;
  }
  .description {
    text-align: justify;
  }

  .description-center {
    text-align: center;
  }

  .brand {
    display: flex;
    justify-content: center;

    .brandImage {
      height: auto;
    }
  }
  .note {
    color: #6b6b6b;
    font-size: 18px;
    margin-bottom: 30px;
    font-style: italic;
  }

  .text {
    color: #000;
    font-size: 14px;
  }

  .highlightContainer {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 900px;
    margin: 50px auto 0px;
    .highlightBox {
      display: flex;
      align-items: center;
      flex-basis: 50%;
      background: #f6f4f3;
      padding: 20px;
      border-radius: 10px;
      max-width: 400px;
      margin: 0px 20px 30px;

      -webkit-box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.1);
      -moz-box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.1);
      box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.1);

      .icon {
        width: 45px;
        height: 45px;
        margin-right: 25px;
      }
      .text {
        font-weight: bold;
        color: #000;
        font-family: 'visa_dialect_regular';
        text-align: left;
      }
    }
  }

  .raw-list {
    justify-content: flex-start;
    flex-direction: column;
    padding-left: 40px;

    li {
      font-size: 16px;
      margin-bottom: 10px;
      line-height: 32px;
    }
  }

  ul {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 0;

    .productItem {
      .productContent {
        flex: 1 1;
        border: 2px solid;
      }
    }

    .productItemInline {
      display: block !important;
      min-width: 25% !important;
    }

    .mediumSquare {
      width: 82px;
      height: 82px;
    }

    .threeColumnRow{
      width: 30%;
      p{
        margin-top: 15px;
        font-size: 18px;
      }
      @media (max-width: 576px){
        width: 70%;
      }
    }

    .flexStartAlign{
      align-self: flex-start;
    }

    .benefitItem,
    .productItem {
      display: flex;
      flex-direction: column;
      align-items: center;

      min-width: 30%;
      max-width: 33%;
      list-style-type: none;
      padding: 20px;
      text-align: center;

      &.largerContainer {
        max-width: 35% !important;
        @media (max-width: 576px){
          width: -webkit-fill-available;
          .mobileSVGCards{
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
          }
        }
      }
      &.largerItem {
        min-width: 40% !important;
      }

      &.smallerItem {
        min-width: 25% !important;
      }

      img {
        width: 82px;
        height: 82px;
      }
      
      .logoImg{
        width: auto !important;
        height: auto !important;
        margin-bottom: 10px;
      }

      .smallerImage {
        width: 50px;
        height: 50px;
      }

      .benefitContent,
      .productContent {
        display: flex;
        flex-direction: column;
        padding: 12px;

        p {
          display: flex;
          align-items: baseline;
          justify-content: center;

          font-size: 20px;
          font-family: $font-bold;
          margin: 0 0 10px;
        }

        span {
          flex: 1 1;
          color: #000;
        }
      }
    }

    &.checkedList {
      list-style-type: none;
      justify-content: flex-start;

      .checkedItem {
        display: flex;
        flex-basis: 50%;
        margin-bottom: 20px;
        height: fit-content;

        img {
          width: 20px;
          height: 20px;
          margin: 2px 10px 0 0;
        }
        p {
          display: inline;
          font-size: 18px;
          color: #000;
          margin: 0;
        }
      }
      .checkedItem-fullwidth {
        display: flex;
        flex-basis: 100%;
        margin-bottom: 20px;
        height: fit-content;

        img {
          width: 20px;
          height: 20px;
          margin: 2px 10px 0 0;
        }
        p {
          display: inline;
          font-size: 18px;
          color: #000;
          margin: 0;
        }
        .itemDisclaimerContainer {
          display: flex;
          flex-direction: column;
          .disclaimer {
            font-size: 16px;
            font-style: italic;
            color: #6b6b6b;
          }
        }
      }
      .checkedItem-margin {
        display: flex;
        flex-basis: 100%;
        margin-bottom: 20px;
        padding-left: 60px !important;
        height: fit-content;

        img {
          width: 20px;
          height: 20px;
          margin: 2px 10px 0 0;
        }
        p {
          display: inline;
          font-size: 18px;
          color: #000;
          margin: 0;
        }
      }
    }

    &.orderedList {
      list-style-type: none;
      flex-direction: column;
      align-items: flex-start;

      .orderedItem {
        display: flex;
        align-items: center;
        padding: 10px;
        width: 100%;
        margin-bottom: 18px;

       @media (max-width: 576px){
          text-align: left;
          max-width: 90%;
        }

        .orderedNumber {
          font-size: 50px;
          width: 25px;
          height: 40px;

          font-family: $font-semibold;
        }
        .orderedIcon {
          width: 50px;
          height: 40px;
        }
        .circularIcon {
          width: 70px;
          margin-right: 20px;
        }
        .divisor {
          height: 60px;
          margin: 0 40px 0 20px;
          &.noMarginRight {
            margin: 0 40px 0 0px;
          }
        }
        .imageContainer {
          height: 50px;
          width: 50px;
        }
        .fakeLink {
          font-weight: bold;
          &:hover {
            text-decoration: underline;
            cursor: pointer;
          }
        }
        p {
          font-size: 18px;
          margin: 0;
          color: #000;
        }

        .itemContentDiv {
          min-width: 80%;
        }
        .itemContentDivResp {
          @media (max-width: 576px){
            flex-direction: column;
            display: flex;
            .itemTitle{
              text-align: center;
            }
            .itemDescription{
              text-align: justify;
            }
          }
        }
        .itemTextLine {
          flex-direction: row;
          display: flex;
          justify-content: space-between;
          padding-right: 30px;
          .itemDescription {
            margin-bottom: 8px;
            font-size: 16px;
          }
          .itemValue {
            font-size: 16px;
          }
        }

        .itemTitle {
          font-family: $font-semibold;
          font-size: 22px;
          margin-bottom: 10px;
        }
      }
    }

    &.inlineList {
      list-style-type: none;
      flex-wrap: wrap;

      .inlineItem {
        flex: 1;
        min-width: 170px;
        display: flex;
        flex-direction: column;
        justify-content: baseline;
        align-items: center;
        padding: 0 10px;
        margin: 10px 0;

        img {
          flex: 1;
          max-width: 65px;
        }

        .itemTitle {
          display: flex;
          align-items: center;
          margin: 10px 0 0;
          font-family: $font-semibold;
        }
      }
    }
  }

  .disclaimer {
    margin-top: 20px;
    color: #000;
  }

  .benefits {
    text-align: center;
    margin: 100px 0 30px;
  }

  h2,
  h3 {
    font-size: 30px;
    font-family: $font-semibold;
    margin-bottom: 30px;
    text-align: center;
  }

  .benefitsContainer {
    max-width: 1000px;
    margin: 0 auto 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    .imageInfo {
      width: 40%;
    }
    .benefitArea {
      list-style-type: none;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      .benefitBox {
        position: relative;
        background: #f6f4f3;
        margin: 25px;
        padding: 15px 15px 15px 50px;
        text-align: left;
        width: 230px;
        .imageArea {
          background: #e7ebee;
          position: absolute;
          top: -30px;
          left: -30px;
          padding: 15px;
          border-radius: 50%;
          border: 5px solid #fff;
          .iconBenefit {
            width: 30px;
            height: 30px;
          }
        }
        .contentArea {
          h4 {
            font-size: 14px;
            font-family: 'visa_dialect_semibold';
          }
          p {
            font-size: 12px;
            font-family: 'visa_dialect_regular';
          }
          ul {
            display: block;
          }
        }
        .contentArea::after {
          content: '';
          position: absolute;
          bottom: -32px;
          left: 20px;
          width: 0;
          height: 0;
          border-top: 20px solid #f6f4f3;
          border-right: 20px solid #f6f4f3;
          border-bottom: 20px solid transparent;
          border-left: 20px solid transparent;
        }
      }
      .benefitBox.fullbox {
        flex-basis: 100%;
        padding: 15px 15px 15px 70px;
        margin: 40px 25px;

        &:first-child {
          margin-top: 60px;
        }

        .imageArea {
          top: -40px;
          left: -50px;
          .iconBenefit {
            width: 60px;
            height: 60px;
          }
        }
        .contentArea {
          h4 {
            font-size: 18px;
          }
          ul {
            list-style-type: none;
            li {
              margin-top: 20px;
              padding-left: 20px;
              border-left: 1px solid #c2a161;
            }
          }
        }
      }
    }
  }
  .listWithList{
    p {
      padding-bottom: 10px;
    }
    .greyColor{
      color: #54606C !important;
      font-size: 15px !important;
    }
  }
  .retreatedLeft{
    display: block;
    padding-left: 5%;
    list-style-type: disc;
    li::marker {
      color: black;
  }
  }

  .displayBlockMobile{
    @media (min-width: 576px) {
        picture {
          align-self: flex-start;
        }
      }
    @media (max-width: 576px) {
      display: block !important;
      .circularIcon{
        margin-bottom: 10px;
      }
    }
  }

  .bannerRectangle{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;

    img{
      width: 213.25px;
      height: 230px;
      overflow: hidden;
      position: relative;
    }
  }
  .blueRectangle{
    width: 734px;
    height: 230px;
    padding: 35px 0px 0px 20px;

    h2{
      color: white;
      font-size: 23.04px;
      font-weight: 600;
      line-height: 27.65px;
      text-align: left;
    }

    p{
      color: white;
      margin-top: 10px;
    }

    button{
      background-color: transparent;
      border-color: white;
      margin-top: 10px;
      padding: 12px 24px;
    }

    a{
      size: 16px;
      color: white;
    }
  }
  @media (max-width: 1600px) {
    .blueRectangle{
      width: 534px;
    }
  }
  @media (max-width: 1300px) {
    .blueRectangle{
      width: 734px;
    }
  }

  @media (max-width: 768px) {
    .bannerRectangle {
      flex-direction: column;
    }

    .blueRectangle {
      width: 500px;
      height: auto;
      padding: 20px;
    }
    .bannerRectangle img {
      width: 500px;
      height: auto;
    }

    .blueRectangle h2 {
      font-size: 18px;
      line-height: 22px;
    }

    .blueRectangle p {
      font-size: 14px;
    }
  }
  @media (max-width: 500px) {
    .blueRectangle {
      width: 300px;
      height: auto;
      padding: 20px;
    }
    .bannerRectangle img {
      width: 300px;
      height: auto;
    }
  }
  @media (max-width: 300px) {
    .blueRectangle {
      width: auto;
      height: auto;
      padding: 20px;
    }
    .bannerRectangle img {
     display: none;
    }
  }

  .noMarginBottom{
    margin-bottom: 0 !important;
  }

  @media (max-width: 1650px) {
    .highlightContainer {
      .highlightBox {
        max-width: 40%;
      }
    }

    .benefitsContainer {
      .imageInfo {
        width: 30%;
      }

      .benefitArea {
        .benefitBox {
          width: 200px;
          padding: 10px 10px 10px 50px;
          margin: 20px 15px 20px;
        }
      }
    }
  }

  @media (max-width: 1300px) {
    .benefitsContainer {
      .benefitArea {
        .benefitBox {
          width: 230px;
          margin: 25px;
        }
      }
    }
  }

  @media (max-width: 992px) {
    .title {
      font-size: 28px;
    }

    .description {
      font-size: 16px;
    }

    .highlightContainer {
      .highlightBox {
        max-width: 400px;
        flex-basis: 100%;
        .text {
          font-size: 14px;
        }
      }
    }

    h2 {
      font-size: 26px;
    }

    .benefitsContainer {
      flex-direction: column;
      align-items: center;

      .imageInfo {
        width: 230px;
      }

      .benefitArea {
        justify-content: center;
      }
    }
  }

  @media (max-width: 576px) {
    ul {
      flex-direction: column;
      align-items: center;

      .benefitItem,
      .productItem {
        max-width: none;
        &.largerContainer {
          max-width: none !important;
        }
      }

      &.checkedList {
        align-items: flex-start;
        .checkedItem {
          flex-basis: 100%;
        }
      }
      &.orderedList {
        .orderedItem {
          .circularIcon {
            width: 50px;
          }
        }
        .columnItem {
          align-items: center;
          flex-direction: column;
          display: flex;
          .circularIcon {
            width: 50px;
            margin-right: 0px;
            margin-bottom: 10px;
          }
        }
      }

      &.inlineList {
        .inlineItem {
          margin-bottom: 30px;
        }
      }
    }
  }
}

.spacer {
  height: 10px;
}
