@import '../../../../styles/colors';
@import '../../../../styles/fonts';
@import '../../../../styles/themes/themes.scss';

.boxContactDetails {
  width: 100%;
}

.titleContactDetails {
  @include themify('color', 'primary');
  font-size: $font-light;
  font-size: 2.5rem;
  margin-bottom: 3.8rem;
}

.editButton {
  margin-left: -8px;
}