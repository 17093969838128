@import '../../styles/colors';
@import '../../styles/fonts';
@import '../../styles/themes/themes.scss';

.divisor {
  height: 1px;
  width: 100%;
  @include themify('background', 'secondary-secondary');
  margin: 30px 0px 50px;

  @media(forced-colors: active){
    border: 1px solid;
  }
}
