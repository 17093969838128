@import '../../styles/style.scss';

.alertContainer {
  position: fixed;
  right: 0;
  top: 50%;

  z-index: 999;
  box-shadow: 0px 0px 38px 1px rgba(255, 255, 255, 0.18);

  .feedbackButton {
    @include themify('background-color', 'primary');
    padding: 10px 5px;
    border-radius: 5%;

    .textButton {
      color: white;
      writing-mode: vertical-lr;
      transform: rotate(180deg);
    }
  }
}

@media (max-width: $width-large) {
  .alertContainer {
    .feedbackButton {
      padding: 2px;
    }
  }
}

@media (max-width: $width-medium) {
  .content {
    padding: 0 20px;
  }
}