@import '../../../styles/fonts';
@import '../../../styles/media_queries';

.container {
  height: 600px;
  background-position: center;
  background-size: cover;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 150px 90px 20px;

  .content {
    .title {
      color: white;
      font-family: $font-regular;
      font-size: 50px;
      margin-bottom: 30px;
      max-width: 50%;

      text-shadow: -2px 2px 10px rgba(0, 0, 0, 0.2);
    }

    button {
      @media (max-width: 520px) {
        width: 100%;
      }
    }
  }

  footer {
    padding-bottom: 30px;
    a {
      color: #fff;
    }
  }
}

@media (max-width: 1200px) {
  .container {
    .content {
      .title {
        font-size: 40px;
      }
    }
  }
}

@media (max-width: $width-large) {
  .container {
    padding: 150px 20px 20px;
    .content {
      .title {
        font-size: 36px;
      }
    }
  }
}

@media (max-width: 720px) {
  .container {
    .content {
      .title {
        text-align: center;
        font-size: 30px;
        max-width: none;
      }
    }
    footer {
      text-align: center;
    }
  }
}

.shortContainer {
  background-position: center 40%;
  background-size: cover;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px 100px;
  max-width: 1760px;
  margin: 0 auto;

  .content {
    .title {
      color: white;
      font-family: $font-regular;
      font-size: 32px;
      margin-bottom: 10px;
      max-width: 50%;

      text-shadow: -2px 2px 10px rgba(0, 0, 0, 0.2);
    }

    button {
      @media (max-width: 520px) {
        width: 100%;
      }
    }
  }

  @media (max-width: $width-large) {
    .content {
      .title {
        max-width: none;
      }
    }
  }

  @media (max-width: $width-small) {
    padding: 30px 30px;
    .content{
      .title{
        font-size: 24px;
      }
    }
  }
}
