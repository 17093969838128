@font-face {
  font-family: 'visa_dialect_regular';
  src: url('../assets/fonts/visaDialect/VisaDialectUI-Regular.woff2') format('woff2'),
  url('../assets/fonts/visaDialect/VisaDialectUI-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'visa_dialect_medium';
  src: url('../assets/fonts/visaDialect/VisaDialectUI-Medium.woff2') format('woff2'),
  url('../assets/fonts/visaDialect/VisaDialectUI-Medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'visa_dialect_bold';
  src: url('../assets/fonts/visaDialect/VisaDialectUI-Bold.woff2') format('woff2'),
  url('../assets/fonts/visaDialect/VisaDialectUI-Bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'visa_dialect_semibold';
  src: url('../assets/fonts/visaDialect/VisaDialectUI-SemiBold.woff2') format('woff2'),
  url('../assets/fonts/visaDialect/VisaDialectUI-SemiBold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'visa_dialect_light';
  src: url('../assets/fonts/visaDialect/VisaDialectUI-Light.woff2') format('woff2'),
  url('../assets/fonts/visaDialect/VisaDialectUI-Light.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


//FONTS
$font-light: 'visa_dialect_light';
$font-regular: 'visa_dialect_regular';
$font-medium: 'visa_dialect_medium';
$font-semibold: 'visa_dialect_semibold';
$font-bold: 'visa_dialect_bold';
