@import '../../../../styles/style.scss';

.benefitItem {
  .item {
    @include themify('background','surface-surface');
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    padding: 5%;
    margin: 0 0 7% 0;
    height: 204px;
    display: flex;
    flex-direction: column;

    &.absolute {
      width: 100%;
      height: auto !important;
    }

    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;

      .category {
        @include themify('color','text-paragraph_subtle');
        font-family: $font-semibold;
        font-size: 16px;
        line-height: 22px;
      }
    }

    .center {
      h6 {
        @include themify('color','primary-primary');
        font-size: 25px;
        @media screen and (max-width: 1400px) {
          font-size: 20px;
        }
      }
    }

    .content {
      h4 {
        font-size: 16px;
        font-weight: bold;
        padding: 0 2rem;
        line-height: 24px;
        margin-bottom: 1.5rem;
      }

      h5 {
        font-size: 16px;
        font-weight: bold;
        padding: 0 2rem;
        margin-top: 3rem;
      }
      &.none {
        display: none !important;
      }

      ul {
        list-style-type: none !important;
        margin-top: 5%;

        li {
          border-left: 1px solid;
          @include themify('border-left-color','secondary-secondary');
          margin-bottom: 4%;
          padding-left: 6%;
        }
      }

      p span, .chevronWrapper {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 5%;

        button {
          background-color: transparent;
          border: 0;
          @include themify('color','secondary');
          outline: none;
        }
      }

      .actions {
        padding-top: 6%;
      }
    }

    .bottom {
      display: flex;
      justify-content: flex-end;
      bottom: 16px;
      position: absolute;
      width: calc(100% - 10%);

      &.none {
        display: none;
      }

      .generate {
        display: block;
        width: 100%;

        a {
          @include themify('color', 'primary-primary');
          font-family: $font-regular;
          font-size: 16px;
          font-weight: 600;

          display: flex;
          align-items: center;

          a:hover{
            text-decoration: underline;
          }

          img {
            margin-left: 3%;
          }
        }
      }

      .actions {
        button {
          background-color: transparent;
          border: 0;
          @include themify('color','secondary-secondary');
          outline: none;
          width: 30px;
        }
      }
    }
  }
}

.iconChevron {
  margin-left: auto;
}
