@import '../../../styles/colors';
@import '../../../styles/fonts';
@import '../../../styles/media_queries';
@import '../../../styles/themes/themes.scss';

.container {
  display: flex;
  flex-direction: row;
  height: 230px;
  width: 95%;
  margin: 10px auto;
  @include themify('background', 'text_and_background');
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  @media (forced-colors: active) {
    border: 1px solid;
  }

  .imgBenefits {
    position: relative;
    width: 160px;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    .blackCover {
      position: absolute;
      top: 0;
      height: 100%;
      left: 0;
      width: 100%;
      background: linear-gradient(45deg, #000, #555);
      opacity: 0.2;
    }
  }

  .contentInfo {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 100%;
    padding: 1.6rem;

    .title {
      font-size: 22px;
      font-weight: 300;
      line-height: 30px;
      flex: 1;
      text-transform: capitalize;
      @include themify('color', 'subtitle');
    }

    .link {
      margin-left: -8px;
    }
  }
}

@media (max-width: 500px) {
  .container {
    .imgBenefits {
      width: 45%;
    }

    .contentInfo {
      padding: 15px;

      .icon {
        display: none;
      }
    }
  }
}
