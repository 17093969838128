@import '../../styles/_colors.scss';
@import '../../styles/_fonts.scss';
@import '../../styles/themes/themes.scss';

.boxSlider {
  width: 100%;
  overflow: hidden;
  position: relative;
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1);
  @include themify('background', 'text_and_background');
  padding: 60px 9rem 45px;
  @media screen and (max-width: 992px) {
    padding: 25px 20px 45px;
  }
  .boxCards {
    position: unset !important;
    padding-right: 0;
  }
}

.boxTitleCard {
  @media screen and (min-width: 992px) {
    margin-left: 0px;
  }
  .subTitleCard {
    font-size: 1.6rem;
    margin-bottom: 1.6rem;
    display: block;
    @include themify('color', 'subtitle');
  }
  .titleCard {
    font-size: 3.3rem;
    @include themify('color', 'primary');
    @media screen and (min-width: 992px) {
      font-size: 33px;
    }
  }

  a {
    margin-left: -8px;
  }
}

.sliderItem {
  background: transparent;
  border: none;

  &:hover {
    .boxImg {
      transition: all linear 0.2s;
      opacity: 1;
    }
  }

  .sliderContent {
    position: relative;
    margin: 0px 5px;
    text-align: left;
  }
}

.boxImg {
  max-width: 274px;
  width: 100%;
  height: auto;
  opacity: 0.4;
}

.boxCards :global(.slick-current) img {
  opacity: 1;
}
.productActive {
  font-size: 26px;
  font-weight: 300;
}
.linkContent {
  flex-direction: column;
  display: none;
  @media screen and (min-width: 992px) {
    display: flex;
  }
}

.linkContentMobile {
  display: flex;
  overflow-x: auto;

  a {
    @include themify('border-color', 'tertiary');
    border: 1px solid;
    @include themify('color', 'primary');
    font-family: $font-regular;
    padding: 10px 15px;
    font-weight: 600;
    margin: 0 10px;
    white-space: nowrap;

    &:first-child {
      margin-left: 0;
    }
  }

  @media screen and (min-width: 992px) {
    display: none;
  }
}

.link {
  margin-top: 0.8rem !important;
}

.seeAllProducts {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 0.8rem;
}

.boxMainCard {
  width: 100%;
  padding: 0px 1rem;
  margin-top: 30px;

  @media screen and (min-width: 992px) {
    display: flex;
    padding: 0px;
  }
  .mainCard {
    width: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: 992px) {
      margin-bottom: 20px;
    }
    img {
      width: 100%;
      height: fit-content;
      max-width: 274px;
    }
  }
}

.maskCard {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  z-index: 5;
}

.cardName,
.bankName {
  font-weight: normal;
  width: 100%;
  max-width: 274px;
}
.cardName {
  margin-top: 15px;
  margin-bottom: 5px;
  font-size: 2.3rem;
  @include themify('color', 'primary');
}
.cardType {
  margin-top: 8px;
  font-size: 14px;
  width: 100%;
  max-width: 274px;
  color: #727678;
}
.bankName {
  font-size: 1.3rem;
  @include themify('color', 'subtitle');
}

.cardNameSlider {
  @include themify('color', 'subtitle');
  font-size: 1.8rem;
}

.bankNameSlider {
  font-size: 1.2rem;
}

.listCards :global(.slick-slider) {
  max-width: 100vw;
  width: 100%;
  overflow: hidden;
}
@media screen and (min-width: 992px) {
  .cardName,
  .bankName {
    text-align: left;
  }
}

.iconArrow {
  position: absolute;
  top: 0px;
  background: transparent;
  border: none;
}

.iconArrowRight {
  right: 0px;
  cursor: pointer;
}
.iconArrowLeft {
  left: 0px;
  cursor: pointer;
}

.slider {
  padding-top: 48px;
}
