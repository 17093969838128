@import '../../../styles/_colors.scss';
@import '../../../styles/themes/themes.scss';
@import '../../../styles/_fonts.scss';

.boxContainer {
  width: 411px;
  height: fit-content;
  @include themify('background', 'primary');
  padding: 30px 50px;
  @include themify('color', 'text_and_background');

  position: sticky;
  top: 20%;
  margin-right: 100px;
  float: right;
  @media (forced-colors: active) {
    border: 1px solid;
  }

  @media (min-width: 1200px) {
    margin-right: 5%;
  }

  .title {
    font-size: 20px;
    margin-bottom: 30px;
    margin-top: 15px;
    font-family: $font-semibold;
    text-align: center;
  }

  .titleDownloadApp {
    font-size: 30px;
    text-align: center;
    margin-top: 15px;
    font-family: $font-semibold;
  }

  .steps {
    .stepItem {
      display: flex;
      align-items: center;
      margin: 30px 0;

      span {
        padding-left: 10px;
        font-family: $font-regular;
      }
    }
  }

  .buttonArea {
    margin: 40px 0 10px;
  }

  .extraInfo {
    text-align: center;
    font-family: $font-light;
    font-size: 12px;
    margin: 20px 0;
  }

  .notAdditional {
    display: flex;

    svg {
      width: 15px;
      height: 15px;
      margin-right: 5px;
    }
    p {
      font-size: 12px;
      text-align: center;
    }
  }
}

.imageLink{
  margin: 16px 0;
  display: flex;
  justify-content: center;
}

@media (max-width: 1400px) {
  .boxContainer {
    width: 360px;
    padding: 30px;
  }
}

@media (max-width: 1280px) {
  .boxContainer {
    width: 100%;
    position: static;
    float: none;

    .notAdditional {
      justify-content: center;
    }
  }
}

.optionButton {
  width: 100%;
}
