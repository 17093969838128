@import '../../../../../../../../../styles/style.scss';
.container {
  .titleArea {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    h2 {
      margin: 0;
      font-size: 25px;
      @include themify('color', 'primary-primary');
    }
  }
}

.headerActions {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1.6rem;

  .headerAddButton {
    margin-right: -1rem;
  }
}

.cardItem.selectedCard {
  @include themify('background', 'const_light_gray');
}

.cardItem {
  border-bottom: 1px solid;
  @include themify('border-color', 'subtitle');
  padding: 24px;

  &:last-child {
    border-bottom: none;
    margin-bottom: 0;
  }

  .footer {
    margin-top: 1.6rem;
    display: flex;
    width: 100%;
    gap: 0.8rem;
    margin-left: -1rem;

    @media (max-width: 560px) {
      flex-direction: column;
    }
  }
}

@media (max-width: 768px) {
  .container {
    .titleArea {
      h2 {
        font-size: 20px;
      }
    }
  }
}

.emptyMessage {
  @include themify('color', 'subtitle');
  font-size: 16px;
}

.wrapperLabel {
  margin-bottom: 1.6rem;
}