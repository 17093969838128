.editCardHolder {
  .footerBtns {
    margin-top: 25px;
    display: flex;
  }
}

@media (max-width: 1199.98px) {
  .section {
      padding: 2rem;
  }
}
