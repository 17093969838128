@import '../../../../../../../../styles/colors';
@import '../../../../../../../../styles/themes/themes.scss';

.incidentAreal {
  margin-top: 0.7rem;
  margin-bottom: 0.5rem;
  h3 {
    color: #333;
    font-size: 1.175rem;
    font-weight: 300;
    margin-top: 0.1rem;
    margin-bottom: 0;
  }
}
.labelTitle {
  @include themify('color', 'primary');
  font-size: 14px;
  margin-left: 15px;
}
