@import '../../../../styles/style.scss';

.container {
  margin-top: 40px;
  @include themify('color', 'primary-primary');

  .termsArea {
    margin-bottom: 30px;
    h2 {
      font-size: 26px;
      @include themify('color', 'primary-primary');
    }
    
    .maxCoverageArea {
      margin-top: 30px;
      h3 {
        @include themify('color', 'primary-primary');
        font-weight: bold;
      }
    }

    .pdf {
      display: flex;
      margin-top: 30px;
      margin-left: 15px;

      .pdfIcon {
        margin-right: 30px;
      }

      .pdfInfo {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
      }
    }
  }

  .acceptArea {
    margin: 40px 0 40px;
    padding: 25px 0 25px 15px;
  }

  .footer {
    display: flex;
    justify-content: flex-end;
  }
}
