@import '../../../styles/colors';
@import '../../../styles/themes/themes.scss';

.boxContainer {
  width: 411px;
  height: fit-content;
  @include themify('background-color', 'primary-primary');
  padding: 30px 30px 20px 30px;
  @include themify('color', 'surface-surface');
  position: relative;

  .darkenFilter {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, .065);
    z-index: -1;
    background-blend-mode: darken;
  }

  position: sticky;
  top: 20%;
  margin-right: 100px;
  float: right;

  .title {
    font-size: 26px;
    margin: 15px 0 45px;
  }
  .description {
    margin-bottom: 30px;
  }
  .divButton {
    width: 180px;
    height: 53px;
  }
  .buttonArea {
    margin: 30px 0;
    button, a {
      width: 100%;
    }
  }
}

@media (max-width: 1200px) {
  .boxContainer {
    width: 320px;
    padding: 20px 20px 10px 20px;

    .title {
      margin-bottom: 20px;
    }
    .description {
      margin-bottom: 30px;
    }
    .divButton {
      width: 100%;
    }
  }
}

@media (max-width: 580px) {
  .boxContainer {
    width: calc(100% - 40px);
    margin: 20px;
    float: none;
  }
}
