.buttonArea {
  display: flex;
  justify-content: center;
  button {
    width: 320px;
    text-transform: capitalize;
    letter-spacing: normal;
  }
}

@media (max-width: 992px) {
  .buttonArea {
    display: flex;
    justify-content: center;

    button {
      width: 180px;
      text-transform: capitalize;
      letter-spacing: normal;
    }
  }
}

.form {
  margin-top: 4rem;
}
