@import '../../styles/fonts';
@import '../../styles/colors';
@import '../../styles/media_queries';
@import '../../styles/themes/themes.scss';

.container {
  padding: 0 9rem;
  margin-bottom: 8rem;
}

@media(min-width: 1200px) {
  .container {
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 990px) {
  .container {
    padding: 0 3rem;
  }
}

.boxCards {
  flex-direction: column;
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.claimListBox {
  display: flex;
  flex-direction: row;
}

.center {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 50px;

  .emptyMessage {
    @include themify('color', 'subtitle');
  }
}
