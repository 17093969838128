@import '../../../../styles/colors';
@import '../../../../styles/fonts';
@import '../../../../styles/media_queries';

.priorityGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: 3.2rem;
  grid-column-gap: 2rem;
}

.commonGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: 2rem;
  grid-column-gap: 2rem;
}

.wrapBanner {
  margin: 20px 0;
}

.emptyBenefits {
  padding: 0 30px 64px;
}

.banner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
  background-image: url('../../../../assets/images/full-width-scuba-diver.jpg');
  background-size: cover;
  padding: 3rem;
  margin: 2rem 0;
  span {
    align-self: center;
    font-style: normal;
    font-weight: 300;
    font-size: 33px;
    text-align: center;
    color: $white;
  }
}

.bannerUpselling {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 200px;
  background: #021e4c;
  margin: 2rem 0;
  padding: 3rem 10rem;
  flex-wrap: wrap;
  .textUpselling {
    margin-bottom: 10px;
    h2 {
      color: $white;
      font-size: 3.2rem;
      margin-bottom: 2px;
      font-weight: 300;
    }
    h3 {
      color: $white;
      font-size: 2.8rem;
      font-family: 'visa_dialect_regular';
    }
  }
  .button {
    width: 250px;
  }

  @media (max-width: $width-small) {
    padding: 30px 30px;
    .textUpselling {
      h2 {
        font-size: 24px;
      }
      h3 {
        font-size: 18px;
      }
    }
    .button {
      width: 100%;
      button {
        width: 100%;
      }
    }
  }
}

.btnArea {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  button {
    @media (max-width: 540px) {
      width: 100%;
    }
  }
}

@media (max-width: $width-medium) {
  .priorityGrid {
    grid-template-columns: repeat(2, 1fr);
  }

  .commonGrid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: $width-small) {
  .priorityGrid {
    grid-template-columns: repeat(1, 1fr);
  }

  .commonGrid {
    grid-template-columns: repeat(1, 1fr);
  }
}
