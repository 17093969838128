@import '../../../../styles/style.scss';

.instructionItem {
  display: flex;
  gap: 24px;

  .textArea {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h3 {
      @include themify('color', 'primary-primary');
      font-size: 19px;
      font-family: $font-semibold;
      line-height: 120%;
      margin-bottom: 8px;
    }
    p {
      font-size: 16px;
      text-align: justify;
      font-family: $font-regular;
      line-height: 150%;
      color: #000;
      margin-bottom: 0;
    }
  }
}
