@import '../../../../../../../../styles/themes/themes.scss';

.personalArea {
  .labelTitle {
    font-size: 16px;
    @include themify('color', 'subtitle');
    margin-bottom: 24px;
  }
  .none {
    display: none;
  }
  .option {
    label {
      margin-bottom: 0px;
    }
  }
}
