@import '../../styles/colors';
@import '../../styles/themes/themes.scss';

.container {
  padding: 25px 30px;
  min-height: 104px;
  margin: 15px 0 0;
  -webkit-box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1);
  background: $white;
  border-radius: 3px;

  @media (max-width: 992px) {
    padding: 10px 20px;
  }
  @media (forced-colors: active) {
    border: 1px solid;
  }

  list-style: none;

  .containerItem {
    display: flex;
    @include themify('color', 'const_gray');

    .description {
      flex: 1 1;
      padding-right: 1%;
      white-space: pre-line;
    }

    .upload {
      display: flex;
      align-items: center;
      min-height: 45px;
      flex-wrap: wrap;
      justify-content: space-between;

      .name {
        max-width: 250px;
        overflow-wrap: break-word;
        margin-right: 10px;
      }

      @media screen and (max-width: 1100px) {
        margin-top: 15px;
        padding: 0;
        justify-content: flex-end;

        button {
          width: 100%;
        }
      }

      @media screen and (max-width: 620px) {
        flex-direction: column;

        button {
          margin: 10px 0;
        }
      }
    }

    @media screen and (max-width: 1100px) {
      flex-direction: column;
    }
  }
}

.boldText {
  @include themify('color', 'const_gray');
  font-weight: 600;
}

.requiredPill {
  @include themify('color', 'const_gray');
  font-weight: 600;
  align-items: center;
  border-radius: 40px;
  padding: 6px 13px;
  background-color: rgb(245, 183, 177);
}

.optionalPill {
  @include themify('color', 'const_gray');
  font-weight: 600;
  align-items: center;
  border-radius: 40px;
  padding: 6px 13px;
  background-color: rgb(215, 219, 221);
}

.optionDivider {
  width: 2px;
  height: 26px;
  border-left: 1px solid;
  @include themify('border-left-color', 'secondary');
  margin: 0 25px 0 20px;

  @media screen and (max-width: 620px) {
    display: none;
  }
}
