@import '../../../../styles/media_queries';
@import '../../../../styles/colors';
@import '../../../../styles/fonts';
@import '../../../../styles/themes/themes.scss';

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex: 1;
  height: 270px;
  margin: 0 3rem;
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  padding: 40px;
  position: relative;
  @include themify('background', 'text_and_background');

  @media(forced-colors: active){
    border: 1px solid;
  }

  .icon {
    margin-bottom: 27px;
    svg {
      width: 85px;
    }
  }

  .contentInfo {
    display: flex;
    flex-direction: column;
    height: 8rem;
    justify-content: space-between;

    .title {
      font-size: 25px;
      font-weight: 300;
      margin-left: 8px;
      @include themify('color', 'primary');
    }

    .info {
      font-size: 18px;
      font-weight: 600;
      margin-top: 1.5rem;
      @include themify('color', 'primary');
    }

    .buttonLink {
      position: absolute;
      bottom: 16px;
    }

    .link {
      position: absolute;
      bottom: 24px;
      margin-left: 8px;
    }
  }
}

@media (max-width: 1400px) {
  .container {
    .icon {
      margin-bottom: 15px;
    }
    .contentInfo {
      .buttonLink {
        bottom: 12px;
      }
      .link {
        bottom: 18px;
      }
    }
  }
}

@media (max-width: 992px) {
  .container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    height: 120px;
    padding: 20px 15px;
    margin: 0 0 20px 0;

    .contentInfo {
      padding-top: 8px;

      .contentInfo {
        .buttonLink {
          bottom: 21px;
        }
        .link {
          bottom: 27px;
        }
      }
    }

    .icon {
      margin: 0;
      img {
        width: 60px;
      }

      svg {
        width: 55px;
        margin-right: 10px;
      }
    }
  }
}

@media (max-width: $width-small) {
  .container {
    .contentInfo {
      .title {
        font-size: 22px;
      }
    }
  }
}

@media (max-width: 400px) {
  .container {
    .contentInfo {
      .buttonLink,
      .link {
        font-size: 14px;
        bottom: 15px;
      }
    }
  }
}


@media (max-width: 375px) {
  .container{
    min-height: 120px;
    height: auto !important;
  }
}

@media (min-width: 992px) {
  .container{
    max-width: 400px;
  }
}
