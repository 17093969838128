@import '../../../styles/themes/themes.scss';

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.city {
  @include themify('color', 'text-paragraph_subtle');
}