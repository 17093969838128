@import '../../styles/themes/themes.scss';
@import '../../styles/fonts';
@import '../../styles/colors';

.boxBannerMarketing {
  width: 100%;
  max-height: 321px;
  padding: 0;
  @media screen and (max-width: 992px) {
    height: auto;
  }
  img{
    display: block;
    max-width: 100%;
    min-width: 100%;
  }
  cursor: pointer;
  background-color: $const_light_gray;
}
.bannerContainer {
  position: relative;
}
.closeIcon{
  position: absolute;
  right: 1vh;
}
.containerBannerMarketing {
  background-repeat: no-repeat;
  background-position: 21px 103px;
  width: 100%;
  height: 100%;
  display: flex;
  max-width: 1800px;
  margin: 0 auto;
  @media screen and (max-width: 992px) {
    background-image: none !important;
  }
}

.contentBannerMarketing {
  display: flex;
  flex-direction: column;
  position: relative;

  @media screen and (min-width: 800px) and (max-width: 1200px) {
    padding: 40px 0px 0px 20px;
  }

  @media screen and (min-width: 1200px) {
    padding: 40px 0px 0px 80px;
  }

  @media screen and (min-width: 1100px) {
    max-width: 60%;
  }
}

.titleAppBanner {
  .title {
    @include themify('color', 'primary');
    font-family: $font-regular;
    .logoVisa {
      margin: 0px 20px 0px 10px;
    }
    span {
      display: block;
      font-size: inherit;
    }
    img {
      width: 110px;
      height: 35px;
    }
  }
}

.buttonStore {
  display: flex;
  margin-top: 25px;
  img {
    width: 130px;
    height: 42px;
    margin-right: 25px;
  }
}

.imageAppBanner {
  display: flex;
  align-items: flex-end;
  img {
    width: 100%;
    height: fit-content;
    max-height: 220px;
    @media screen and (min-width: 1385px) {
      margin-left: 50px;
    }
    @media screen and (max-width: 992px) {
      display: none;
    }
  }
}

@media screen and (min-width: 992px) and (max-width: 1385px) {
  .containerBannerMarketing {
    background-image: none !important;
  }
  .containerBullets {
    justify-content: center;
    .boxBullets {
      margin-bottom: 20px;
    }
  }
  .buttonStore {
    display: flex;
    margin-top: 20px;
    justify-content: center;
    padding-bottom: 0px;
    img {
      width: 130px;
      margin-right: 25px;
    }
    img:last-child {
      margin-right: 0px;
    }
  }
}

@media screen and (max-width: 992px) {
  .containerBannerMarketing {
    background-image: none !important;
  }
  .contentBannerMarketing {
    padding: 30px;
    background: #f6f6f6;
    margin: 0 auto;
  }
  .imageAppBanner {
    display: none;
  }

  .buttonStore {
    display: flex;
    margin-top: 20px;
    justify-content: center;
    margin-bottom: 0px;
    img {
      width: 130px;
      margin-right: 25px;
    }
    img:last-child {
      margin-right: 0px;
    }
  }
}

@media screen and (max-width: 800px) {
  .boxBannerMarketing {
    padding: 0px;
  }
}

.qrCODE {
  position: absolute;
  top: 50px;
  right: -20%;
  max-width: 120px;
  display: none;
  @media screen and (min-width: 1100px) {
    display: block;
  }

  img {
    max-width: 120px;
  }
}
