@import '../../styles/colors';
@import '../../styles/themes/themes.scss';

.container {
  header {
    display: flex;
    height: 8rem;
    align-items: center;
    z-index: 100;
    transition: all linear 0.4s;
    padding: 0 1rem;
    @media screen and (min-width: 400px) and (max-width: 1280px) {
      padding: 0 3rem;
    }

    @media screen and (min-width: 1280px) {
      padding: 0 8rem;
    }
  }

  .container {
    width: 100%;
    height: 100%;
  }
}
