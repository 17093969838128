@import '../../../../../../styles/style.scss';


.containerItem {
    display: flex;
    justify-content: space-between;
    @include themify('color', 'const_gray');

    padding: 24px;
    min-height: 104px;
    margin: 15px 0 0;
    -webkit-box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 0px 50px rgba(0  , 0, 0, 0.1);
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1);
    background: $white;
    border-radius: 3px;

    @media screen and (max-width: $width-medium) {
      padding: 12px;
    }

    @media screen and (max-width: $width-small) {
      padding: 8px;
    }

    .infoDocuments {
      width: 30%;

      .attachName {
        max-width: 88%;
        overflow-wrap: break-word;
      }
    }
    .textDocument {
      flex: 1 1;
      display: flex;
      align-items: center;
      padding-right: 1%;
      white-space: pre-line;
      max-width: 50%;
    }
    .upload {
      display: flex;
      align-items: center;
      min-height: 45px;
      flex-wrap: wrap;
      justify-content: space-between;

      hr {
        width: 2px;
        height: 26px;
        border-left: 1px solid;
        @include themify('border-left-color', 'secondary');
        margin: 0 25px 0 20px;
      }

      .statusArea {
        padding: 0;
        display: flex;
        justify-content: center;
      }
      @media screen and (max-width: 1100px) {
        margin-top: 15px;
        padding: 0;
        button {
          width: 100%;
        }
      }
      @media screen and (max-width: 620px) {
        flex-direction: column;
        hr {
          display: none;
        }
        button {
          margin: 10px 0;
        }
      }
    }
    @media screen and (max-width: 1100px) {
      flex-direction: column;
    }
  }
.hideInput {
  opacity: 0;
  position: absolute;
  top: 0px;
  left: 0px;
  display: none;
}
.widthButtonUpload {
  width: 113px;
}

.backGroundBlue {
  @include themify('background-color', 'primary');
}

.infoDocumentsLink{
  font-weight: bold;
  @include themify('color', 'primary');
  display: flex;

}


.buttonDownload{
  cursor: pointer;
  margin: -3px 0px 0px 5px;
}
