@import '../../../../styles/style.scss';

.visaBenefits {
  h2 {
    @include themify('color', 'primary');
    font-family: $font-bold;
    margin-bottom: 30px;
  }
  p {
    color: #000;
    font-size: 25px;
  }
}
