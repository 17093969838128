@import '../../../styles/themes/themes.scss';

.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 150px;
}

.lastUpdated {
  @include themify('color', 'subtitle');
  padding-bottom: 3rem;
  font-size: 12px;
}
