@import '../../styles/fonts';
@import '../../styles/themes/themes.scss';

.button {
  font-family: $font-medium;
  font-weight: 500;
  line-height: 24px;
  background-color: transparent;
  border: 2px solid;
  border-color: transparent;
  width: fit-content;
  text-wrap: nowrap;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  &:hover {
    text-decoration: none;
  }

  &:focus {
    outline: none;
  }

  &:focus-visible {
    outline-width: 2px;
    outline-style: solid;
    outline-offset: 2px;
  }

  &:not(:active):not(:hover):not(:focus) {
    transition: all linear 0.2s;
  }

  &:disabled, &.disabled {
    cursor: default;
  }
}

.small {
  height: 40px;
  padding: 12px 16px;
}

.large {
  height: 56px;
  padding: 12px 24px;
}

.primary, .secondary {
  min-width: 120px;
}

.tertiary {
  padding: 12px 8px;
}
.icon {
  padding: 0;
}

.icon {
  width: 40px;
  height: 40px;
}

.trailingIconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

// Colors
.onSurface {
  &.primary {
    @include themify('background-color', 'primary-primary');
    @include themify('color', 'text-inverse_title');

    &:hover, &:focus-visible {
      @include themify('background-color', 'primary-hover');
    }

    &:focus-visible {
      @include themify('outline-color', 'primary-primary');
    }

    &:active {
      @include themify('background-color', 'surface-inverse_pressed');
    }

    &:disabled, &.disabled {
      @include themify('color', 'disabled-graphics');
      @include themify('background-color', 'disabled-surface');

      .trailingIconContainer {
        @include themify('stroke', 'disabled-graphics');
      }
    }

    .trailingIconContainer {
      @include themify('stroke', 'icon-inverse-secondary');
    }
  }

  &.secondary {
    @include themify('border-color', 'primary-primary');

    &:disabled, &.disabled {
      @include themify('border-color', 'disabled-graphics');
    }
  }

  &.secondary, &.tertiary, &.icon {
    @include themify('color', 'text-title');

    &:hover, &:focus-visible {
      @include themify('background-color', 'surface-hover');
    }

    &:focus-visible {
      @include themify('outline-color', 'primary-primary');
    }

    &:active {
      @include themify('background-color', 'surface-pressed');
    }

    &:disabled, &.disabled {
      @include themify('color', 'disabled-graphics');
      background-color: transparent !important;

      .trailingIconContainer {
        @include themify('stroke', 'disabled-graphics');
      }
    }

    .trailingIconContainer {
      @include themify('stroke', 'icon-on_surface-primary');
    }
  }
}

.inverse {
  &.primary {
    @include themify('background-color', 'secondary-inverse');
    @include themify('color', 'text-paragraph');

    &:hover, &:focus-visible {
      @include themify('background-color', 'secondary-inverse_hover');
    }

    &:focus-visible {
      @include themify('outline-color', 'secondary-inverse_hover');
    }

    &:active {
      @include themify('background-color', 'secondary-inverse_pressed');
    }

    &:disabled, &.disabled {
      @include themify('color', 'disabled-graphics');
      @include themify('background-color', 'disabled-surface');

      .trailingIconContainer {
        @include themify('stroke', 'disabled-graphics');
      }
    }

    .trailingIconContainer {
      @include themify('stroke', 'icon-on_surface-primary');
    }
  }

  &.secondary {
    @include themify('border-color', 'surface-surface');

    &:disabled, &.disabled {
      @include themify('border-color', 'disabled-surface');
    }
  }

  &.secondary, &.tertiary, &.icon {
    @include themify('color', 'text-inverse_title');

    &:hover, &:focus-visible {
      @include themify('background-color', 'surface-inverse_hover');
    }

    &:focus-visible {
      @include themify('outline-color', 'surface-surface');
    }

    &:active {
      @include themify('background-color', 'surface-inverse_pressed');
    }

    &:disabled, &.disabled {
      @include themify('color', 'disabled-surface');
      @include themify('background-color', 'surface-inverse');

      .trailingIconContainer {
        @include themify('stroke', 'disabled-surface');
      }
    }

    .trailingIconContainer {
      @include themify('stroke', 'icon-inverse-primary');
    }
  }
}