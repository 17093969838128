
@import '../../styles/colors';
@import '../../styles/themes/themes.scss';

.strokePrimaryColor {
  @include themify('stroke', 'primary');
}

.strokeSecondaryColor {
  @include themify('stroke', 'secondary');
}

.fillPrimaryColor {
  @include themify('fill', 'primary');
}

.fillSecondaryColor {
  @include themify('fill', 'secondary');
}

.fillNone {
  fill: none
}

.fillWhiteColor {
  color: #fff
}

.DisabledColor {
  color: #cad1d6
}

.InactiveColor {
  color: #808EA5
}
