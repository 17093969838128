@import '../../../../../../../../styles/themes/themes.scss';
@import '../../../../../../../../styles/fonts';

.questionsArea {
  margin-top: 1.2rem;
  .elementRadio {
    @include themify('color', 'tertiary');
    font-size: 14px;
    .labelTitle {
      @include themify('color', 'primary');
      font-size: 16px;
      font-weight: 300;
      margin-bottom: 15px;
      font-family: $font-regular;
    }
  }
  .none {
    display: none;
  }
}
