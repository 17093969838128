@import '../../styles/colors';
@import '../../styles/fonts';
@import '../../styles/themes/themes.scss';

.bodyContent {
  @include themify-half-color-gradient();

  @media (max-width: 500px) {
    height: auto;
  }

  .title {
    @include themify('color', 'text-inverse_paragraph');
    font-size: 45px;
    font-weight: 500;
    margin-bottom: 2%;
    @media (max-width: 920px) {
      padding: 0 10px;
      font-size: 36px;
    }
  }

  .infoBox {
    @include themify('background-color', 'surface-surface');
    padding: 2.4rem;
    border-radius: 5px;
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1);

    .headerContainer {
      margin-bottom: 20px;
      .header {
        font-size: 26px;
        font-weight: 400;
        @include themify('color', 'primary-primary');
        margin-bottom: 10px;
      }
    }
  }
}
