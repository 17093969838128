@import '../../styles/colors';
@import '../../styles/themes/themes.scss';

.container {
  width: 100%;
  display: flex;

  .info {
    width: 45%;

    .boxInfo {
      max-width: 412px;
      height: 100%;

      display: flex;
      flex-direction: column;
      justify-content: center;

      h1 {
        @include themify('color', 'primary');
        font-size: 45px;
        margin-bottom: 20px;
      }

      p {
        font-size: 16px;
        line-height: 24px;
        @include themify('color', 'subtitle');
        margin-bottom: 50px;
      }

      .footer {
        display: flex;
        gap: 8px;

        .button:not(:only-child) {
          flex: 1;
        }
      }
    }
  }

  @media (max-width: 1090px) {
    flex-direction: column;
    align-items: center;

    .info {
      width: 100%;
      display: flex;
      justify-content: center;

      .boxInfo {
        max-width: none;

        h1 {
          font-size: 30px;
          margin-bottom: 5px;
        }

        .footer {
          margin-bottom: 50px;
        }
      }
    }

    img {
      width: 400px;
    }
  }

  @media (max-width: 520px) {
    .info {
      .boxInfo {
        p {
          font-size: 14px;
          margin-bottom: 10px;
        }

        .footer {
          flex-direction: column;
          .button {
            margin-top: 5px;
            margin-right: 0;
            width: 100%;
          }
        }
      }
    }

    img {
      width: 80%;
    }
  }

  @media(min-width: 1200px) {
    justify-content: center;
    max-width: 1760px;
    margin-left: auto;
    margin-right: auto;
  }
}