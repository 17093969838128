@import '../../styles/fonts';
@import '../../styles/themes/themes.scss';

a.link,
// Override bootstrap defaults
a.link:not([href]):not([tabindex]), 
a.link:not([href]):not([tabindex]):hover
a.link:not([href]):not([tabindex]):focus,
button.link {
  font-family: $font-regular;
  font-weight: 400;
  line-height: 150%;
  text-decoration-line: underline;
  word-wrap: break-word;
  cursor: pointer;
  background-color: transparent;
  border: none;

  display: inline;

  &:focus {
    outline: none;
  }

  &:focus-visible {
    outline-width: 2px;
    outline-style: solid;
  }

  &.disabled {
    cursor: default;
    pointer-events: none;
  }

  // Icon
  svg {
    margin-left: 4px;
    margin-bottom: 4px;
  }

  // Colors
  &.onSurface {
    @include themify('color', 'primary-primary');

    &:hover,
    &:focus-visible {
      @include themify('background-color', 'surface-hover');
      @include themify('color', 'primary-hover');
    }

    &:focus-visible {
      @include themify('outline-color', 'primary-primary');
    }

    &:active {
      @include themify('color', 'primary-pressed');
      @include themify('background-color', 'surface-pressed');
    }

    &.disabled,
    &.disabled:hover,
    &.disabled:focus-visible,
    &.disabled:active {
      @include themify('color', 'disabled-graphics');
      background-color: transparent;
    }
  }

  &.inverse {
    @include themify('color', 'primary-inverse');

    &:hover,
    &:focus-visible {
      @include themify('background-color', 'surface-inverse_hover');
    }

    &:focus-visible {
      @include themify('outline-color', 'primary-inverse');
    }

    &:active {
      @include themify('color', 'primary-inverse_pressed');
      @include themify('background-color', 'surface-inverse_pressed');
    }

    &.disabled,
    &.disabled:hover,
    &.disabled:focus-visible,
    &.disabled:active {
      @include themify('color', 'disabled-surface');
      background-color: transparent;
    }
  }
}
