@import '../../../../../../../styles/style.scss';

.containerDownloadItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}

.title {
  margin-bottom: 8px;
  font-size: 12px;
  font-weight: bold;
  font-family: $font-regular;
  @include themify('color', 'primary-primary');
}

.filename {
  flex: 1;
  vertical-align: middle;
  margin: 0;
}