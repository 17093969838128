@import '../../styles/colors';
@import '../../styles/fonts';
@import '../../styles/themes/themes.scss';

.Input {
  margin-top: 22px;
  position: relative;
  width: 100%;
  min-width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;

  input {
    background: none;
    border: none;
    border-bottom: 1px solid;
    @include themify('border-color', 'text-paragraph_subtle');
    font-size: 1.6rem;
    line-height: 1;
    padding: 0;
    font-family: $font-light;
    width: 30%;
    border-radius: 0;
    @include themify('color', 'text-paragraph_subtle');
    height: 56px;
    text-align: center;
    min-width: 40px;

    &:focus {
      outline: none;
      box-shadow: none;
      @include themify('border-color', 'primary-primary');
      border-bottom: 2px solid;
    }

    &::placeholder {
      line-height: 16px;
      overflow: visible;
    }

    &:-webkit-autofill {
      background: red;
    }

    &:disabled {
      background: none;
    }
  }
  .error {
    border-color: $error-red-alert;
    &:focus {
      border-bottom-color: $error-red-alert;
    }
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
}

.label {
  position: absolute;
  top: -24px;
  font-size: 12px;
  font-weight: bold;
  font-family: $font-regular;
  @include themify('color', 'primary-primary');
}

.errorLabel {
  font-size: 0.75em;
  color: $error-red-alert;
}

.value {
  @include themify('color', 'primary-primary')
}

.buttonChange {
  min-width: initial;
  aspect-ratio: 1;
}
