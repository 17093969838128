@import '../../../../../../../../styles/colors';
@import '../../../../../../../../styles/themes/themes.scss';

.incidentAreal {
  margin-top: 0.7rem;
  margin-bottom: 0.5rem;
  p {
    @include themify('color', 'primary');
    font-size: 1.2rem;
    font-weight: 400;
    margin-top: 0.1rem;
    margin-bottom: 0;
  }
  .elementRadio {
    @include themify('color', 'const_gray');
    font-size: 14px !important;
    margin-bottom: 1rem;
    .labelTitle {
      @include themify('color', 'subtitle');
      font-size: 1.4rem;
      font-weight: 300;
      margin-bottom: 10px;
    }
  }
  .none {
    display: none;
  }
  .option {
    label {
      margin-bottom: 0px;
    }
  }
}
.marginBottom {
  padding: 0px !important;
  .buttonArea {
    max-width: 180px;
    @media (max-width: 575px) {
      max-width: none;
    }
  }
}

.add {
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
  button {
    margin-right: -8px;
  }
}

.footer {
  display: flex;
  button {
    margin-left: -8px;
    margin-bottom: 24px;
  }
}
