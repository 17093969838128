@import '../../../../../../../../styles/colors';
@import '../../../../../../../../styles/themes/themes.scss';

.rentalPeriodlArea {
  margin-top: 0.7rem;
  h3 {
    color: #333;
    font-size: 1.175rem;
    font-weight: 300;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.labelTitle {
  font-size: 16px;
  @include themify('color', 'subtitle');
  margin-bottom: 0px;
}
