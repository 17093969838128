@import '../../../../styles/fonts';
@import '../../../../styles/themes/themes.scss';

.container {
  .title {
    font-size: 30px;
    margin-top: 80px;
    margin-bottom: 20px;
    @include themify('color', 'const_axa_blue');
    font-family: $font-semibold;

    @media (max-width: 992px) {
      font-size: 26px;
    }
  }

  .wrapLoading {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }

  .subtitleText {
    font-family: $font-regular;
    width: 100%;
    font-size: 18px;
    margin-bottom: 45px;
    display: block;
    @include themify('color', 'const_axa_blue');
  }

  .listExtensions {
    max-height: 500px;
    overflow-y: auto;
    padding: 20px 10px 10px;
  }
}
