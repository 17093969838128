@import '../../../../../../styles/style.scss';

.appointmenQuestions {
  margin-bottom: 3%;
  ul {
    list-style-type: none;
    padding-left: 2%;
    li {
      @include themify('color', 'subtitle');
      min-height: 35px;
      &.active {
        @include themify('background-color', 'text_and_background');
        color: black;
        font-weight: bold;
      }
    }
  }
}
