@import '../../styles/colors';
@import '../../styles/media_queries';
@import '../../styles/fonts';
@import '../../styles/themes/themes.scss';

.containerLink {
  display: flex;
  padding: 0 9rem;
  justify-content: flex-end;

  @media (max-width: $width-small) {
    justify-content: center;
    padding: 0 30px;
    margin-top: 30px;
  }
}
.naturalContainerLink {
  display: flex;

  @media (max-width: $width-small) {
    justify-content: center;
    padding: 0 30px;
    margin-top: 30px;
  }
}

.disablePadding {
  padding: 0;
}
