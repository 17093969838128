@import '../../styles/colors';
@import '../../styles/fonts';
@import '../../styles/themes/themes.scss';

.Textarea {
  margin-top: 22px;
  margin-bottom: 22px;
  position: relative;
  width: 100%;
  textarea {
    background: none;
    border: 1px solid;
    @include themify('border-color', 'const_gray');
    @include themify('border-bottom-color', 'text-paragraph_subtle');
    font-size: 16px;
    line-height: 1;
    padding: 6px;
    font-family: $font-light;
    width: 100%;
    border-radius: 0;
    height: 75px !important;
    @include themify('color', 'text-paragraph_subtle');
    margin: 0;
    resize: none;

    &.marginToIcon {
      background-position-x: calc(100% - 31px) !important;
    }
    &:focus {
      outline: none;
      box-shadow: none;
      @include themify('border-bottom-color', 'secondary-secondary');
      border-bottom-width: 2px;
    }
    &::placeholder {
      @include themify('color', 'text-paragraph_subtle');
      line-height: 16px;
    }
    &:-webkit-autofill {
      background: $error-red-alert;
    }
    &:disabled {
      @include themify('background-color', 'tertiary');
      cursor: not-allowed;
    }
  }
  textarea.error {
    border-bottom-color: $error-red-alert;
    &:focus {
      border-bottom-color: $error-red-alert;
    }
  }
}

.errorLabel {
  font-size: 0.75em;
  color: $error-red-alert;
  font-family: $font-regular;
}

.inputGroup {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
  .errorLabel {
    margin-top: 3px;
    width: 100%;
    border-bottom-color: $error-red-alert;
  }
}

.groupText {
  position: absolute;
  right: 0;
  top: 3px;
  padding: 0 0.75rem 7px;
  background-color: transparent !important;
  border-radius: 0;
  cursor: default;
  img {
    height: 20px;
  }
}

.label {
  position: absolute;
  top: -24px;
  font-size: 12px;
  font-weight: bold;
  font-family: $font-regular;
  @include themify('color', 'primary-primary');
}
.label.axa{
  @include themify('color', 'const_axa_blue');
}

.labelFlex {
  display: flex;
}

.errorLeft {
  float: left;
}

.notColorful {
  border-bottom-color: $error-red-alert !important;
  @include themify('border-color', 'subtitle');
}
