@import '../../styles/fonts';
@import '../../styles/colors';
@import '../../styles/media_queries';
@import '../../styles/themes/themes.scss';

.footer {
  margin-bottom: 2%;
  padding: 0 9rem;

  .separator {
    @include themify('background-color', 'secondary-secondary');
    height: 1px;
    margin-bottom: 8%;
    width: 100%;
  }

  ul {
    list-style: none;
    li {
      margin-bottom: 15px;
    }
  }

  .visa {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;

    @media screen and (max-width: $width-medium) {
      justify-content: flex-start;
    }
  }

  .copyright {
    color: #6a727b;
    display: block;
    font-family: $font-regular;
    font-size: 12px;
    line-height: 14px;
    margin-top: 5%;
    text-align: right;
  }

  @media screen and (max-width: $width-medium) {
    .separator {
      margin-bottom: 15%;
    }
    .logoFooter {
      margin: 10% 0 6% 0;
    }
    .firstSm {
      margin-bottom: 5%;
    }

    .copyright {
      margin-bottom: 7%;
      text-align: left;
    }
  }

  @media screen and (max-width: $width-large) {
    padding: 0 2rem;
  }
}

.logo {
  @include themify('color', 'primary-primary');
  cursor: pointer;
}