@import '../../../../../styles/themes/themes.scss';
@import '../../../../../styles/fonts';
@import '../../../../../styles/colors';
@import '../../../../../styles/media_queries';

.divider {
  margin-bottom: 1.8rem;
}

.container {
  display: flex;
  flex-direction: row;
  margin-bottom: 2rem;
  height: auto;

  @media screen and (max-width: $width-medium) {
    flex-direction: column;
  }

  .sideTitle {
    font-size: 1.6rem;
    margin: 0em 0 1.6rem;
    text-align: left;
    @include themify('color', 'secondary-secondary');
  }

  .text {
    font-size: 1.6rem;
    margin-bottom: 0.2rem;
    text-align: left;
    @include themify('color', 'primary-primary');
    font-weight: 400;
  }
}

.airportContainer {
  flex: 1;
}

.leftContainer {
  display: flex;
  flex-direction: row;
  flex: 1;
}

.rightContainer {
  padding-left: 24px;

  @media screen and (max-width: $width-medium) {
    padding-left: 0;
  }
}
