@import '../../styles/colors';

.background {
  background: $white;
  opacity: 0.8;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

.boxLoading {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 10;
  .loading {
    width: 100%;
    height: 100%;
    max-width: 1240px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

@media screen and (max-width: 992px) {
  .boxLoading {
    position: fixed;
  }
}
