//Const colors
$dark-blue: #19236d;
$white: #fff;
$disabled: #6a727b;
$error-red-alert: #c64b60;
$const_axa_blue: #00008f;
$const_gray: #cad1d6;
$const_light_gray: #f6f4f3;

$tea_green: #d6f2c4;
$green_70: #3a8b61;
$green_100: #1b402d;
$pink: #ffb0d4;
$red_70: #c0495e;
$red_100: #59222b;
$orange_70: #e19305;
$sorbet_yellow: #ffef99;
$visa_yellow_100: #6a5109;
$light_blue: #c7edff;
$blue_70: #2787df;
$blue_100: #123e67;
$gray_10: #eeeff0;
$gray_20: #caced1;
$gray_60: #54606c;
$gray_100: #23282d;
$text_title_error: #963949;

$genericColors: (
  dark-blue: #19236d,
  white: #fff,
  disabled: #6a727b,
  error-red-alert: #c64b60,
  const_axa_blue: #00008f,
  const_gray: #cad1d6,
  const_light_gray: #f6f4f3,
  tea_green: #d6f2c4,
  green_70: #3a8b61,
  green_100: #1b402d,
  pink: #ffb0d4,
  red_70: #c0495e,
  red_100: #59222b,
  orange_70: #e19305,
  sorbet_yellow: #ffef99,
  visa_yellow_100: #6a5109,
  light_blue: #c7edff,
  blue_70: #2787df,
  blue_100: #123e67,
  gray_10: #eeeff0,
  gray_20: #caced1,
  gray_60: #54606c,
  gray_100: #23282d,
  text_title_error: #963949,
);

//Themes
$secondary-master: #fcc015;
$secondary-afluent: #c2a161;
$secondary-portoBank: #546ac0;

$primary-master: #1434cb;
$primary-portoBank: #0a0047;
$primary-afluent: #021e4c;

$link-master: #1434cb;
$link-afluent: #021e4c;

$tertiary-master: #ffffff;
$tertiary-afluent: #ffffff;
