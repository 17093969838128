@import '../../../styles/style.scss';

.loadingCenter {
  display: flex;
  justify-content: center;
}

.container {
  margin: 0 2rem;
}

.sectionTitle {
  @include themify('color', 'primary');
  font-size: $font-light;
  font-size: 2.5rem;
  margin-bottom: 3.8rem;
}

.checkbox {
  padding-left: 15px;
  padding-right: 15px;
  @include themify('color', 'const_gray');
  font-weight: 400;
  margin-bottom: 40px;

  .itemCheckBox {
    margin-bottom: 27px;
  }
}

.informationArea {
  padding-left: 15px;
  padding-right: 15px;
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 1rem;
  @include themify('color', 'subtitle');
}

.buttonContainer {
  padding-top: 20px;
  width: 180px;
}

.claimTitle {
  @include themify('color', 'primary');
  font-size: 33px;
  margin-bottom: 1.5rem;
}

.emptyMessage {
  @include themify('color', 'subtitle');
  font-size: 16px;
}
