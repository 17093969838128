@import '../../../styles/colors';
@import '../../../styles/fonts';
@import '../../../styles/media_queries';
@import '../../../styles/themes/themes.scss';

.knowBenefits {
  position: relative;
  margin: 6% 0;
  width: 100%;

  .containerKnowBenefits {
    display: flex;

    .image {
      flex: 1 1;
      background-size: 100%;
      background-repeat: no-repeat;
    }

    .knowBenefitsInfo {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      h2 {
        @include themify('color', 'text_and_background');
        font-family: $font-regular;
        font-size: 52px;
        margin: 5% 0 20px;
        text-align: center;
        padding: 0 20px;
      }
      button {
        max-width: 200px;
      }
    }
  }

  .blackCover {
    position: absolute;
    top: 0;
    height: 100%;
    left: 0;
    width: 100%;
    background: #000;
    opacity: 0.3;
  }

  @media screen and (max-width: 1350px) {
    .containerKnowBenefits {
      .knowBenefitsInfo {
        h2 {
          font-size: 42px;
        }
      }
    }
  }

  @media screen and (max-width: $width-large) {
    .containerKnowBenefits {
      flex-direction: column;
      .image {
        flex: none;
      }
      .knowBenefitsInfo {
        h2 {
          font-size: 45px;
        }
      }
    }
  }

  @media screen and (max-width: $width-small) {
    .containerKnowBenefits {
      .knowBenefitsInfo {
        h2 {
          font-size: 30px;
        }
      }
    }
  }
}
