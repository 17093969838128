@import '../../../../../../../styles/style.scss';

.btnChangeBank {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
}

.noMargin {
  margin: 0px !important;
}

.footer {
  margin-top: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;

  @media (max-width: $width-medium) {
    button {
      flex: 1;
    }
  }
}

@media (max-width: 1199.98px) {
  .section {
    padding: 2rem;
  }
}

@media (max-width: 768px) {
  .bankInformation {
    padding: 0 2rem;
  }
}