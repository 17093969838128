.container {
  display: flex;
  background: #fff;
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  margin-bottom: 15px;
  padding: 30px 21px;

  @media(forced-colors: active){
    border: 1px solid;
  }

  img {
    margin: 0 4%;
    width: 50px;
  }

  .textContent {
    flex: 1 1;
    display: flex;

    .contentArea {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1 1;
    }

    .productName {
      font-weight: bold;
    }

    .label {
      margin-right: 2px;
    }
  }
}

@media screen and (max-width: 1300px) {
  .label {
    margin-bottom: 20px;
  }
  .container {
    flex-direction: row;
    img {
      margin-left: 6%;
      margin-right: 10px;
      width: 70px;
      height: fit-content;
    }
    .textContent {
      flex-direction: column;
      flex-wrap: wrap;
      align-items: center;
      margin-right: 6%;
    }
  }
}

@media screen and (max-width: 500px) {
  .container {
    img {
      width: 50px;
      margin-top: 8%;
    }
    .textContent {
      .productName{
        width: 100%;
        text-align: center;
      }
      .validity{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .label{
          width: 100%;
          text-align: center;
        }
        .label:first-child{
          margin-top: 20px;
          margin-bottom: 5px;
        }
      }
    }
  }
}
