@import '../../../../styles/colors';
@import '../../../../styles/fonts';

.container {
  margin-top: 120px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  padding: 60px 5%;
  @media(forced-colors: active){
    border: 1px solid;
  }

  .loadingContainer {
    display: flex;
    justify-content: center;
  }

  .content {
    .title {
      color: $const_axa_blue;
      font-family: $font-semibold;
      font-size: 30px;
      margin-bottom: 40px;
      text-align: center;
    }

    .productsArea {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }

    .footer {
      display: flex;
      justify-content: center;
      margin-top: 20px;
      
      button {
        @media (max-width: 520px) {
          width: 100%;
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .container {
    .content {
      .productsArea {
        justify-content: flex-start;
      }
    }
  }
}

@media (max-width: 992px) {
  .container {
    .content {
      .title {
        font-size: 26px;
      }
    }
  }
}
