@import '../../../../styles/media_queries';
@import '../../../../styles/colors';
@import '../../../../styles/fonts';
@import '../../../../styles/themes/themes.scss';

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 90%;
  margin: 10px auto;
  height: 230px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  @media screen and (max-width: 1300px) {
    margin: 0 auto;
  }
  padding: 40px;
  @include themify('background', 'tertiary');

  .title {
    font-size: 26px;
    font-weight: 300;
    @include themify('color', 'subtitle');
  }
  .content {
    @include themify('color', 'subtitle');
    p {
      font-weight: 300;
    }
  }
  .footer {
    margin-left: -8px;
  }
}

@media (max-width: $width-large) {
  .container {
    width: 90%;
  }
}

@media (max-width: 400px) {
  .container {
    padding: 20px;
    width: 90%;
  }
}
