@import '../../styles/themes/themes.scss';
@import '../../styles/fonts';

.container {
  padding: 140px 90px;
  .title {
    @include themify('color', 'primary');
    font-family: $font-regular;
    font-weight: bold;
    font-size: 33px;
  }

  .body {
    .subtitle {
      @include themify('color', 'subtitle');
      font-family: $font-regular;
      font-weight: bold;
      margin: 30px 0 15px;
      font-size: 25px;
    }
  }

  footer {
    margin-top: 50px;
    font-family: $font-regular;
  }
}

@media (max-width: 992px) {
  .container {
    padding: 140px 20px 50px;

    .title {
      font-size: 26px;
    }

    .body {
      .subtitle {
        font-size: 22px;
      }
    }
  }
}
