@import '../../../../../../../styles/themes/themes.scss';

.placesArea {
  .none {
    display: none !important;
  }
}
.blueInfo {
  font-size: 14px;
  @include themify('color', 'primary');
  padding-top: 5px;
}
