@import '../../../../../../../../styles/themes/themes.scss';

.personalArea {
  .fontGray12 {
    @include themify('color', 'tertiary');
    font-size: 14px;
    .labelTitle {
      @include themify('color', 'subtitle');
      font-size: 1.2rem;
      font-weight: 300;
    }
  }
  .none {
    display: none;
  }
  .options {
    margin-bottom: 6px;
    .option {
      label {
        margin-bottom: 0px;
      }
    }
  }
}
.elementRadio {
  padding: 7px 0;
}
