@import './colors_afluent.scss';
@import './colors_master.scss';
@import './colors_portoBank.scss';
@import './colors_axa.scss';

$themes: (
  afluent: $afluent,
  axa: $axa,
  master: $master,
  portoBank: $portoBank
);

@mixin themify($property, $key, $postfix:"", $is-important: false) {
  @each $theme, $colors in $themes {
    &.theme-#{$theme}#{$postfix} {
      @if $is-important {
        #{$property}: map-get($colors, $key) !important;
      } @else {
        #{$property}: map-get($colors, $key);
      }
    }
  }
}

@function get-theme-color($theme, $color) {
  @return map-get(map-get($themes, $theme), $color);
}

@mixin themify-repeating-linear-gradient($color, $postfix:"") {
  @each $theme,
  $colors in $themes {
    &.theme-#{$theme}#{$postfix} {
      background: repeating-linear-gradient(
        117deg,
        transparent 0px,
        transparent 6px,
        #{map-get($colors, $color)} 8px
      );
    }
  }
}

@mixin themify-half-color-gradient($height: 420px, $color: "surface-inverse", $secondary-color: "surface-surface") {
  @each $theme, $colors in $themes {
    &.theme-#{$theme} {
      background: linear-gradient(
        to bottom,
        get-theme-color($theme, $color) 0%,
        get-theme-color($theme, $color) $height,
        get-theme-color($theme, $secondary-color) $height,
        get-theme-color($theme, $secondary-color) 100%
      );
    }
  }
}