@import '../../styles/style.scss';

.hiddenLinkContainer {
  position: absolute;
  left: 0;
  z-index: 1000;

  @include themify('background', 'primary');

  padding: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  transition: top .2s ease-in;

  &:hover {
    cursor: pointer;
  }

  .anchorLink {
    @include themify('color', 'tertiary');
    font-family: $font-regular;
    text-decoration: underline;
  }
}
