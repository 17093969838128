@import '../../styles/style.scss';

.container {
  .loadingContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 5rem 0;
  }

  padding-top: 100px;
  .contentClaims {
    padding: 0 9rem;
    margin-bottom: 8rem;
    
    @media(min-width: 1200px) {
      max-width: 1920px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .contentAllClaims {
    margin-top: 10%;
    padding: 0 10rem;
    .status {
      border-bottom: 1px solid;
      @include themify('border-bottom-color', 'secondary');
      padding-bottom: 3%;
      margin-bottom: 5%;

      .header {
        margin-bottom: 2%;

        h3 {
          @include themify('color', 'link');
          font-size: 28px;
          margin-bottom: 2%;
        }

        p {
          @include themify('color', 'const_gray');
          font-size: 18px;
        }
      }

      .contentStatus {
        margin-bottom: 2%;
      }

      .more {
        text-align: center;

        a {
          @include themify('color', 'secondary');
          font-size: 16px;
          text-decoration: none;
        }
      }
    }
  }

  .contentOffers {
    margin: 0 1%;
  }

  .contentUpgradeMe {
    padding: 0 6%;
    margin-bottom: 100px;
  }

  @media (max-width: 990px) {
    .contentClaims {
      padding: 0 3rem;
    }
    .contentAllClaims {
      padding: 0 3rem;
    }
  }
}
