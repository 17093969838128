@import '../../../styles/media_queries';
@import '../../../styles/fonts';
@import '../../../styles/colors';
@import '../../../styles/themes/themes.scss';

.container {
  position: relative;
  margin-bottom: 200px;

  .divider {
    border-top: 1px solid;
    @include themify('border-color', 'secondary');
    margin: 8rem 0;
  }

  .showMore {
    margin: 3.2rem 0;
    display: flex;
    justify-content: center;
  }

  .advancedSearch{
    margin-bottom: 1.6rem;
    .buttonAdvancedSearch{
      display: flex;
      width: 100%;
      justify-content: flex-end;
    }
    .backToCertificates {
      display: flex;
      width: 100%;
      justify-content: flex-start;
      margin-bottom: 2.4rem;
      margin-left: -1rem;
    }
  }
}
