@import '../../../../../../../styles/style.scss';

.noteWrapper{
  margin-bottom: 16px;
}

.titleInformation {
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 24px;
  .boldTitle{
    font-weight: bold;
  }
  @include themify('color', 'subtitle');

  &:last-of-type {
    margin-top: 33px;
    margin-bottom: 16px;
    max-width: 900px;
    line-height: 1.5;
  }
}

@media (max-width: 1199.98px) {
  .section {
      padding: 2rem;
  }
}
