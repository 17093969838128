@import '../../../styles/themes/themes.scss';
@import '../../../styles/colors';

.container {
  display: flex;
  justify-content: space-between;
}

.spacer {
  flex: 1;
}

.green {
  @include themify('color', 'positive-text');
}

.orange {
  @include themify('color', 'warning-text');
}

.alert {
  @include themify('color', 'disabled-text');
}