@import '../../styles/colors';

.react-datepicker__header {
  background: $dark-blue;
  * {
    color: $white;
  }
}

.react-datepicker__input-container {
  width: 100%;
}

.react-datepicker-popper {
  z-index: 9999;
}
.react-datepicker {
  font-size: 0.8em;
}
.react-datepicker__header {
  padding-top: 0.8em;
}
.react-datepicker__month {
  margin: 0.4em 1em;
}
.react-datepicker__day-name,
.react-datepicker__day {
  width: 1.9em;
  line-height: 2.1em;
  margin: 4px;
}
.react-datepicker__current-month {
  font-size: 1.1em;
}
.react-datepicker__navigation {
  top: 1em;
  line-height: 1.7em;
  border: 0.45em solid transparent;
  width: 12px !important;
  height: 12px !important;
}
.react-datepicker__navigation--previous {
  border-right-color: #ccc;
  left: 1em;
}
.react-datepicker__navigation--next {
  border-left-color: #ccc;
  right: 1em;
}

.react-datepicker__triangle {
  left: -50px !important;
}

@media(forced-colors: active){
  .react-datepicker__day {
    border: 1px solid;
  }
  .react-datepicker__day--disabled {
    border: none
  }
}
