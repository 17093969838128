@import "../../../styles/themes/themes.scss";

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-top: 0.25rem;
}

.date {
  @include themify('color', 'text-paragraph_subtle');
  margin-bottom: 2rem;
}