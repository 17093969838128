@import '../../styles/media_queries';
@import '../../styles/colors';
@import '../../styles/themes/themes.scss';

.contentTitle {
  display: flex;
  height: 54px;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid;
  @include themify('border-color', 'secondary');
  padding-top: 10rem;
  margin-bottom: 8rem;
  .title {
    @include themify('color', 'primary');
    font-weight: normal;
  }
  
  @media(min-width: 1200px){
    max-width: 1760px;
    margin-left: auto;
    margin-right: auto;
  }
}

.contentButtonMobile {
  margin-bottom: 3rem;
  display: none;
  padding: 0 10rem;
}

@media screen and (max-width: $width-large) {
  .container {
    padding: 0;
  }
  .contentTitle {
    padding: 4rem 0;
    margin: 0rem;
    button {
      display: none;
    }
  }

  .contentButtonMobile {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 3rem 5rem 3rem;
  }
}
