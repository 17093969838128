@import '../../../styles/colors';
@import '../../../styles/fonts';
@import '../../../styles/themes/themes.scss';

.containerProducts {
  width: 100%;
  height: 600px;
  @include themify('background', 'tertiary');

  .selectedProduct {
    display: flex;
    @include themify('color', 'primary');
    padding: 130px 5% 0;
    margin-bottom: 20px;
    img {
      width: 330px;
      height: 210px;
    }
    .productInfo {
      @media screen and (min-width: 992px) {
        position: relative;
        margin-left: 30px;
      }
      .subtitle {
        font-family: $font-regular;
        font-weight: 400;
        @include themify('color', 'subtitle');
        font-weight: 600;
      }
      .title {
        font-size: 45px;
        margin: 10px 0;
      }
      .description {
        margin: 20px 0;
        line-height: 24px;
        // como proceder se o nome do cartão for muito grande?
        max-width: 680px;
        @include themify('color', 'subtitle');
      }
      .linkArea {
        @media screen and (min-width: 992px) {
          position: absolute;
          bottom: 0;
        }
      }
    }
  }
  .carouselArea {
    margin-left: 5%;
    @media screen and (max-width: 992px) {
      min-height: 250px;
      margin-bottom: 20px;
    }
  }

  .loadingContainer {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
}

@media (max-width: 992px) {
  .containerProducts {
    display: flex;
    flex-direction: column-reverse;
    padding: 100px 0 0%;
    height: auto;
    .selectedProduct {
      padding: 0 5%;
      img {
        display: none;
      }
      .productInfo {
        padding-top: 15px;
        width: 100%;
        max-width: none;
        .title {
          font-size: 33px;
        }
        .description {
          margin: 10px 0 20px 0;
        }
      }
    }
  }
}
