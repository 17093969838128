@import '../../../../styles/colors';
@import '../../../../styles/fonts';
@import '../../../../styles/media_queries';
@import '../../../../styles/themes/themes.scss';

.boxCards {
  flex-direction: column;
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.claimListBox {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.center {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 50px;

  .emptyMessage {
    @include themify('color', 'subtitle');
  }
}
