@import "../../../styles/themes/themes.scss";
@import "../../../styles/fonts";

.container {
  display: flex;
  flex-direction: row;
  margin-top: 0.25rem;
}

.leftContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.rightContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.progressContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 15px 1rem 0;
  align-items: center;
}

.distanceContainer {
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
}

.airport {
  font-size: 28px;
  font-family: $font-semibold;
  @include themify('color', 'primary-primary');
  margin-bottom: 0;
}

.time {
  font-size: 22px;
  font-family: $font-semibold;
  @include themify('color', 'primary-primary');
  margin-bottom: 0;
}

.statusText {
  @include themify('color', 'text-paragraph_subtle');
  margin-bottom: 0;
}

.bottomStatusText {
  @include themify('color', 'text-paragraph_subtle');
}