@import '../../styles/colors';
@import '../../styles/fonts';
@import '../../styles/themes/themes.scss';

.Calendar {
  display: flex;
  flex-direction: column;
  margin-top: 22px;
  margin-bottom: 22px;
  position: relative;
  width: 100%;
  input {
    background: none;
    border: none;
    border-bottom: 1px solid;
    @include themify('border-color', 'text-paragraph_subtle');
    font-size: 1.6rem;
    line-height: 1;
    padding: 6px 0;
    font-family: $font-light;
    width: 100%;
    padding-right: 30px;
    border-radius: 0;
    height: 35px;
    @include themify('color', 'text-paragraph_subtle');

    &:focus {
      outline: none;
      box-shadow: none;
      @include themify('border-color', 'secondary-secondary');
      border-bottom-width: 2px;
    }
    
    &::placeholder {
      line-height: 16px;
      overflow: visible;

      @media (max-width: 575px) {
        position: absolute;
        bottom: 2px;
        white-space: pre-line;
        font-size: 14px;
      }
    }
    &:-webkit-autofill {
      background: $error-red-alert;
    }
    &:disabled {
      background-color: #fafafa;
      cursor: not-allowed;
    }
  }
  .icon {
    max-width: 18px;
    max-height: 18px;
    opacity: 0.3;
    position: absolute;
    right: 6px;
    top: 6px;
  }
  .icon.realign {
    right: 40px;
  }
}

.error {
  input {
    border-bottom-color: $error-red-alert;
  }
}

.value {
  input {
    @include themify('color', 'primary');
    border-bottom: 1px solid;
    @include themify('border-color', 'primary');
  }
}

.errorLabel {
  font-size: 0.75em;
  color: $error-red-alert;
}

.label {
  position: absolute;
  top: -16px;
  font-size: 12px;
  font-weight: bold;
  font-family: $font-regular;
  @include themify('color', 'primary');
}
.label.axa {
  @include themify('color', 'const_axa_blue');
}

.inputGroup {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
  .errorLabel {
    margin-top: 3px;
    width: 100%;
    border-bottom-color: $error-red-alert;
  }
}

.inputGroupText {
  position: absolute;
  right: 0;
  top: 3px;
  padding: 0 0.75rem 7px;
  background-color: transparent !important;
  border-radius: 0;
  cursor: default;
  img {
    height: 20px;
  }
}
