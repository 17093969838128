@import '../../styles/style.scss';

.container {
  padding-top: 100px;

  .content {
    padding: 0 9rem;

    @media (max-width: $width-large) {
      padding: 0 2rem;
    }

    .contentBenefits {
      margin-top: 80px;
      padding-bottom: 40px;
      @media(min-width: 1200px){
        max-width: 1760px;
        margin-left: auto;
        margin-right: auto;
      }
    }

    .contentUpgradeMe {
      margin-bottom: 100px;
    }
    .marginTop{
      margin-top: 60px;
    }
  }
}
