.titleArea {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
}

.addButton {
  margin-right: -1rem;
}
