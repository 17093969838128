@import '../../styles/style.scss';

.container {
  .publicBanner {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 0 8rem 10rem;
    background-position: center center;
    background-size: cover;
    background-repeat: none;
    height: 50rem;
    @include themify('color', 'text_and_background');

    h1 {
      font-weight: 300;
      font-size: 45px;
    }

    p {
      font-weight: 300;
      font-size: 16px;
    }

    button {
      margin-left: -8px;
      text-wrap: wrap;
      text-align: left;
    }
  }

  .privateBanner {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 0 8rem 10rem;
    height: 42rem;
    @include themify('color', 'text_and_background');
    @include themify('background', 'primary');

    h1 {
      font-weight: 300;
      font-size: 50px;
      margin-bottom: 20px;
    }

    p {
      font-weight: 300;
      font-size: 18px;
      font-family: $font-regular;
    }
  }

  .content {
    padding: 3% 8rem 0;
    .title {
      @include themify('color', 'primary');
      font-size: 33px;

      @media (max-width: $width-small) {
        font-size: 28px;
      }
    }

    .cardsArea {
      width: 100%;
      padding: 20px 0;
      margin: 20px 0;
    }
    
    @media(min-width: 1760px){
      max-width: 1760px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

@media (max-width: 750px) {
  .container {
    .publicBanner {
      padding: 0 2rem 4rem;

      h1 {
        font-size: 40px;
      }
    }

    .privateBanner {
      padding: 0 2rem 4rem;

      h1 {
        font-size: 40px;
      }
    }

    .content {
      padding: 2rem 2rem 0;
    }
  }
}

@media (max-width: $width-small) {
  .container {
    .publicBanner {
      h1 {
        font-size: 35px;
      }
    }
  }
}

.upgradeMeContainer {
  padding: 0 9rem;

  @media (max-width: $width-large) {
    padding: 0 2rem;
  }
}
