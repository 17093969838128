@import '../../../../styles/style.scss';
@import '../../../../styles/fonts';

.container {
  margin-top: 40px;
  @include themify('color', 'primary');
  .termsArea {
    h2 {
      font-size: 2.6rem;
      font-family: $font-regular;
      @include themify('color', 'const_axa_blue');
    }
    .pdf {
      display: flex;
      margin-top: 30px;
      margin-left: 15px;

      .pdfIcon {
        margin-right: 30px;
        margin-top: -2px;
      }

      .pdfInfo {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }
  .acceptArea {
    margin: 80px 0 40px;
    padding: 25px 0 25px 15px;
    border-top: 1px solid;
    @include themify('border-color', 'tertiary');
    border-bottom: 1px solid;
  }

  .footer {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .buttonArea {
      width: 180px;
      
      button {
        width: 100%;
      }
    }

    @media (max-width: 580px) {
      .buttonArea {
        button {
          width: 95%;
        }
      }
      .buttonArea + .buttonArea {
        display: flex;
        justify-content: flex-end;
      }
      .buttonArea {
        width: 100%;
      }
    }
  }
}
