@import '../../styles/colors';
@import '../../styles/fonts';
@import '../../styles/themes/themes.scss';

.Input {
  margin-top: 22px;
  margin-bottom: 22px;
  position: relative;
  width: 100%;

  input {
    background: none;
    border: none;
    border-bottom: 1px solid;
    font-size: 1.6rem;
    line-height: 1;
    padding: 6px 0;
    font-family: $font-light;
    width: 100%;
    border-radius: 0;
    @include themify('color', 'text-paragraph_subtle');
    height: 35px;
    margin: 0;

    &:focus {
      outline: none;
      box-shadow: none;
    }

    &::placeholder {
      line-height: 16px;
      overflow: visible;

      @media (max-width: 575px) {
        position: absolute;
        bottom: 2px;
        white-space: pre-line;
        font-size: 14px;
      }
    }
    &:-webkit-autofill {
      background: red;
    }
    &:disabled {
      background-color: #fafafa;
      cursor: not-allowed;
    }
  }

  .error {
    border-color: $error-red-alert;
    &:focus {
      border-bottom-color: $error-red-alert;
    }
  }

  &:focus-within {
    input {
      border-bottom: 2px solid;
      @include themify('border-color', 'primary-primary');
    }
    .focusToltip {
      border-bottom: 2px solid;
      @include themify('border-color', 'primary-primary');
    }
  }
}

.value {
  @include themify('color', 'primary-primary', '', true)
}

.errorLabel {
  font-size: 0.75em;
  color: $error-red-alert;
}

.bottomLabel {
  font-size: 0.75em;
  font-family: $font-regular;
}

.inputGroup {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
  .errorLabel {
    margin-top: 3px;
    width: 100%;
    border-bottom-color: $error-red-alert;
  }
}

.label {
  position: absolute;
  top: -16px;
  font-size: 12px;
  font-weight: bold;
  font-family: $font-regular;
  @include themify('color', 'primary-primary');
}

.labelFlex {
  display: flex;
}

@media (max-width: 600px) {
  .textTooBig {
    position: relative;
    margin-bottom: 0;
    top: 5px;
    width: 100%;
  }
}

.errorLeft {
  float: left;
}

.notColorful {
  border-bottom-color: transparent !important;
}

.inputGroupText {
  display: flex;
  align-items: center;
  padding: 0 0.75rem;
  background-color: transparent !important;
  border-bottom: 1px solid;
  @include themify('border-color', 'text-paragraph_subtle');
  border-radius: 0;
  cursor: default;
  &.errorGroupText {
    border-bottom: 1px solid $error-red-alert;
  }
  img {
    height: 20px;
  }
}

.icon {
  position: absolute;
  right: 2px;
  top: 0;
}

[data-tooltip] {
  &:hover {
    &:before,
    &:after {
      display: block;
      position: absolute;
      right: 0.1%;
      top: 180%;
      font-size: 14px;
      @include themify('color', 'primary-primary');
      -webkit-box-shadow: 0px 9px 11px 5px rgba(0, 0, 0, 0.18);
      -moz-box-shadow: 0px 9px 11px 5px rgba(0, 0, 0, 0.18);
      box-shadow: 0px 9px 11px 5px rgba(0, 0, 0, 0.18);
      white-space: normal;
    }
    &:before {
      border-radius: 0.2em;
      content: attr(datatitle);
      @include themify('background-color', 'surface-surface');
      margin-top: -2.5em;
      padding: 0.4em;
      width: 35%;
      top: 70px !important;
    }
    &:after {
      margin-top: -2.1em;
      margin-left: 1em;
      border-style: solid;
      border-color: transparent;
      border-top-color: black;
      border-width: 0.5em 0.5em 0 0.5em;
    }
  }
}
