@import '../../../styles/colors';
@import '../../../styles/fonts';
@import '../../../styles/themes/themes.scss';

.containerOffer {
  position: relative;
  width: 100%;
  padding-top: 10%;
  padding-bottom: 15%;
  padding: 0 9rem;
  @include themify('background', 'text_and_background');

  .image {
    background-repeat: no-repeat;
    background-position: 0% 100%;
    background-size: contain;

    flex: 1 1;
    width: 85%;
    height: 940px;
    object-fit: cover;
  }

  .infoArea {
    position: absolute;
    bottom: -12%;
    right: 90px;

    display: flex;
    flex-direction: column;
    justify-content: center;

    padding: 4% 4%;
    width: 55%;
    min-width: 300px;
    max-width: 720px;
    height: 60%;
    min-height: 420px;
    max-height: 660px;

    @include themify('background', 'primary');

    .info {
      font-size: 33px;
      @include themify('color', 'text_and_background');
      font-family: $font-light;
      margin-bottom: 12%;
      line-height: 55px;
    }
  }
}

@media (max-width: 1500px) {
  .containerOffer {
    .image {
      height: 582px;
    }
  }
}

@media (max-width: 1200px) {
  .containerOffer {
    .image {
      height: 445px;
    }
  }
}

@media (max-width: 1000px) {
  .containerOffer {
    padding: 0;
    margin-bottom: 250px;
    .image {
      width: 95%;
    }
    .infoArea {
      height: 30%;
      min-height: 280px;
      right: 0;
      bottom: -30%;
      .info {
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 20px;
      }
    }
  }
}

@media (max-width: 720px) {
  .containerOffer {
    @include themify('background', 'tertiary');
    .infoArea {
      .info {
        font-size: 22px;
        margin-bottom: 20px;
      }
    }
  }
}

@media (max-width: 540px) {
  .containerOffer {
    .image {
      height: 350px;
    }

    .infoArea {
      padding: 8% 4%;
      bottom: -50%;
      .info {
        font-size: 18px;
        line-height: 24px;
      }
    }
  }
}

@media (max-width: 400px) {
  .containerOffer {
    .image {
      height: 270px;
    }
    .infoArea {
      bottom: -70%;
    }
  }
}
