@import '../../../styles/media_queries';
@import '../../../styles/colors';
@import '../../../styles/fonts';
@import '../../../styles/themes/themes.scss';

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 3rem;

  .image {
    display: flex;
    width: 100%;
    max-width: 300px;
    margin-bottom: 2rem;
  }

  .contentInfo {
    display: flex;
    flex-direction: column;
    max-width: 300px;
    width: 100%;
    padding-left: 5px;
    .cardInfo {
      min-height: 150px;
    }

    .action {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;
      min-height: 40px;

      h5 {
        font-size: 18px;
        font-weight: bold;
        // padding-bottom: 5px;
        border-bottom: transparent;
        margin-bottom: 0;
        @include themify('color', 'subtitle');
      }
      img {
        cursor: pointer;

        &:hover {
          opacity: 0.5;
        }
      }
      svg {
        cursor: pointer;
        &:hover {
          opacity: 0.5;
        }
      }
    }

    .cardType {
      font-family: $font-regular;
      font-weight: 600;
    }
    .cardName {
      font-size: 16px;
      @include themify('color', 'subtitle');
    }

    .cardActive {
      border-bottom: 1px solid;
      @include themify('border-color', 'secondary');
    }

    .mainCard {
      @include themify('color', 'subtitle');
      margin-top: 1.6rem;
      font-size: 15px;
      font-weight: 600;
      font-family: $font-regular;
    }

    .changeCardButton {
      margin-top: 0.8rem;
      margin-left: -1rem;
    }
  }
}

.containerAddNewCard {
  display: flex;
  height: 300px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  @include themify('background', 'const_light_gray');
  border: 1px solid;
  @include themify('border-color', 'const_light_gray');
  @include themify('color', 'primary');
  padding: 4rem;
  cursor: pointer;

  h1 {
    margin-bottom: 2rem;
  }
  .icon {
    height: 35px;
    width: 35px;
  }
  svg {
    height: 100%;
    width: 100%;
  }
}

@media (max-width: 1500px) {
  .container {
    .image {
      max-width: 250px;
    }
    .contentInfo {
      max-width: 250px;
    }
  }
  .containerAddNewCard {
    width: 250px;
  }
}

@media (max-width: 992px) {
  .container {
    .contentInfo {
      .action {
        h5 {
          font-size: 14px;
        }
      }

      .cardName {
        font-size: 14px;
      }

      span {
        font-size: 16px;
      }
    }
  }
}
