@import '../../../styles/style.scss';

.container {
  .content {
    position: relative;
    margin-top: -400px;
    padding: 0 90px;
    bottom: 100px;

    display: flex;

    .infoContent {
      flex: 1;
      padding-top: 600px;
      padding-right: 90px;

      .visaBenefits {
        h2 {
          @include themify('color', 'primary');
          font-size: 52px;
          font-family: $font-bold;
          margin-bottom: 30px;
        }
        p {
          color: #000;
          font-size: 25px;
        }
      }
    }
  }
}

@media (max-width: 1760px) {
  .container {
    .content {
      .infoContent {
        padding-right: 2%;
      }
    }
  }
}

@media (max-width: 1300px) {
  .container {
    .content {
      flex-direction: column-reverse;
      margin-top: 0;
      bottom: 0;

      .infoContent {
        padding-top: 100px;
        padding-right: 0;

        .visaBenefits{
          h2{
            font-size: 32px;
          }
          p{
            font-size: 18px;
          }
        }
      }
    }
  }
}

@media(max-width: 992px){
  .container{
    .content{
      padding: 0 20px;
    }
  }
}

@media(max-width: 500px){
  .container{
    .content{
      .infoContent{
        .visaBenefits{
          h2{
            font-size: 25px;
          }
        }
      }
    }
  }
}

.containerHightLight{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 900px;
  margin: 80px auto 0px;
}

.boxHightlight{
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
}

.boxInfo {
  margin-top: 100px;
}



