@import '../../../styles/style.scss';

.cardItem {
  width: 39rem;
  margin: 0 auto 2rem;
  height: auto;
  border-radius: 2px;
  border: 2px dashed;
  @include themify('border-color', 'secondary-secondary');
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 1.3rem;
  padding: 2rem 0rem 2rem 0rem;
  background: transparent;

  @include themify('color', 'text-paragraph');

  @media screen and (max-width: 600px) {
    width: 95%;
  }
  svg {
    margin: 0px 1.2rem;
  }
}

.borderGold {
  border: 1px solid;
  @include themify('border-color', 'secondary-secondary');
}

.bin {
  display: flex;
  width: 100%;
  span {
    width: 100%;
    font-size: 1.6rem;
  }
  svg {
    margin-right: 20px;
  }
}

.bank {
  font-size: 1.2rem;
}
