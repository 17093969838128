@import '../../styles/colors';
@import '../../styles/themes/themes.scss';
@import '../../styles/fonts';
@import '../../styles/media_queries';

@mixin themify-container-gradient($point1: 50%, $point2: 50%) {
  @each $theme, $colors in $themes {
    &.theme-#{$theme} {
      background: linear-gradient(90deg, map-get($colors, 'surface-surface') $point1, map-get($colors, 'primary-primary') $point2);
    }
  }
}

.container {
  @include themify-container-gradient();

  &.thirdBlue {
    @include themify-container-gradient(70%, 30%);
  }

  .auth0Header {
    display: flex;
    height: 8rem;
    align-items: center;
    justify-content: space-between;
    z-index: 100;
    transition: all linear 0.4s;
    padding: 0 8rem;

    .logoLink {
      display: flex;
      align-items: center;
      gap: 16px;
    }

    .selectWrapper {
      width: 100px;
      select {
        color: #fff !important;
        border-bottom: none;
        font-family: $font-regular;
        option {
          color: #000;
        }
      }

      @include themify('stroke', 'surface-surface', ' svg path');
    }
  }

  .body {
    width: 100%;
    min-height: calc(100vh - 8rem - 184px);
    display: flex;
    padding: 6rem 8rem 2rem;
  }
}

.footer {
  display: flex;
  align-items: start;
  justify-content: space-between;
  font-family: $font-regular;
  background: #fff;
  padding: 60px 80px 60px 0;
  margin-left: 80px;
  border-top: 1px solid;
  @include themify('border-color', 'secondary-secondary');

  .linkArea {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.6rem;
  }
}

@media (max-width: $width-large) {
  .container,
  .container.thirdBlue {
    @include themify('background', 'surface-surface');

    .auth0Header {
      padding: 0 2rem;

      .selectWrapper {
        @include themify('color', 'primary-primary', ' select', true);
        @include themify('stroke', 'primary-primary', ' svg path');
      }
    }

    .body {
      padding: 4rem 2rem;
    }
  }

  .footer {
    flex-direction: column;
    padding: 30px 20px;
    margin-left: 0;
    gap: 24px;
  }
}
