@import '../../../styles/style.scss';
@import '../../../styles/media_queries';

.wrapperUpload {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 500px;

  @include themify-half-color-gradient();
}

.uploadTitle {
  @include themify('color', 'link');
  font-size: 26px;
  margin-bottom: 25px;
}

.textSubTitle {
  @include themify('color', 'const_gray');
  font-size: 16px;
  text-rendering: optimizeLegibility;
  margin-bottom: 24px;

  span {
    display: block;
  }
}

.bold {
  font-weight: 600;
}

.uploadCompleteAnalysis {
  color: $error-red-alert;
  font-size: 12px;
  text-rendering: optimizeLegibility;
  font-weight: normal;
}

.filerListUpload {
  margin-top: 47px;

  ul {
    margin-top: 20px;
    margin-left: 0px;
    padding-left: 0px;

    .statusArea {
      padding: 0;
      display: flex;
      justify-content: center;
    }

    .hideInput {
      opacity: 0;
      position: absolute;
      top: 0px;
      left: 0px;
      display: none;
    }

    .widthButtonUpload {
      width: 113px;
    }

    .backGroundBlue {
      @include themify('background-color', 'primary');
    }
  }
}

.footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid;
  @include themify('border-color', 'const_gray');
  padding-top: 15px;
  margin-top: 46px;
}

@media (max-width: $width-small) {
  .footer {
    .divButton {
      width: 100%;
      button {
        width: 95%;
      }
    }

    .divButton+.divButton {
      display: flex;
      justify-content: flex-end;
    }
  }
}
