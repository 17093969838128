@import '../../styles/media_queries';
@import '../../styles/themes/themes.scss';

.container {
  .header {
    display: flex;
    height: 54px;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid;
    @include themify('border-color', 'secondary-secondary');
    padding-top: 10rem;
    margin-bottom: 8rem;
    .title {
      @include themify('color', 'primary');
      font-weight: normal;
    }
  }
  .contentButtonMobile {
    display: none;
  }
  
  @media(min-width: 1200px){
    max-width: 1760px;
    margin-left: auto;
    margin-right: auto;
  }
}

.center {
  display: flex;
  justify-content: center;
  width: 100%;
}

@media screen and (max-width: 990px) {
  .container {
    .header {
      padding: 4rem 0;
      margin: 0rem;
      button {
        display: none;
      }
    }

    .contentButtonMobile {
      display: flex;
      justify-content: center;
      margin-bottom: 3rem;
      padding: 0 3rem;
    }
  }
}
