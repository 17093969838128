@import '../../styles/style.scss';
@import '../../styles/utils';

.menuButton {
  @include themify('stroke', 'secondary-inverse');

  &.inverse {
    @include themify('stroke', 'secondary-secondary');
  }
}

.headerMenuMobile {
  display: flex;
  padding: 2.5rem 2.5rem;
  justify-content: space-between;
  border-bottom: 1px solid;
  @include themify('border-bottom-color', 'tertiary');
  align-items: center;
}

.iconMenu {
  @include themify('color', 'secondary');
  font-size: 3rem;
  cursor: pointer;

  height: 18px;
}

.menuMobileList {
  width: 100vw;
  max-width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100vh;
  @include themify('background', 'text_and_background');
  top: 0px;
  left: 0px;
  overflow: auto;

  ul {
    list-style: none;
    li {
      padding: 15px 25px;
      border-bottom: 1px solid;
      @include themify('border-bottom-color', 'tertiary');
      font-size: 2rem;
      @include themify('color', 'link');
      font-family: $font-regular;
      font-weight: 500;
      justify-content: flex-start;
      align-items: center;
      display: flex;

      a {
        @include themify('color', 'link');
        font-family: $font-regular;
        font-weight: 500;
        width: 100%;
      }

      .icon {
        margin-right: 15px;
        width: $min_size_accessible;
        display: inline-block;
      }
      .countryIcon {
        margin-right: 20px;
      }
    }
  }

  .buttonArea {
    margin: 50px 20px;

    button {
      width: 100%;
    }
  }
}

.continentName {
  font-size: 1.8rem;
  display: inline-block;
  width: 100%;
  display: flex;
}

.countriesMenuMobile {
  li {
    display: block !important;
  }
  ul {
    li {
      font-size: 1.6rem;
      border-bottom: none;
      padding: 10px 0px 0px 0px;
      font-weight: normal;
      font-family: $font-light;
      display: block;
    }
  }
}

.iconChevron {
  @include themify('stroke', 'secondary-secondary');
  margin-left: auto;
}
