@import '../../../../styles/colors';
@import '../../../../styles/themes/themes.scss';

.stepThree {
  h2 {
    font-size: 26px;
    font-weight: 400;
    @include themify('color', 'primary-primary');
    @include themify('border-color', 'surface-inverse');
    border-bottom: 1px solid;
    padding-bottom: 38px;
    margin-bottom: 34px;
  }

  .info {
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 24px;
    @include themify('color', 'text-paragraph_subtle');
  }

  .info2 {
    font-size: 16px;
    font-weight: 300;
    @include themify('color', 'text-paragraph_subtle');
    display: block;
  }

  .info3 {
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 24px;
    font-weight: bold;
    @include themify('color', 'text-paragraph_subtle');
  }

  .smaller {
    font-size: 14px !important;
  }

  .btnFooter {
    display: flex;
    justify-content: space-between;
    width: 100%;
    @include themify('border-color', 'surface-inverse');
    border-top: 1px solid;
    padding-top: 25px;
    margin-top: 46px;

    .divButton {
      width: 180px;
      
      button {
        width: 100%;
      }
    }
  }
}

@media (max-width: 575px) {
  .stepThree {
    .btnFooter {
      .divButton {
        button {
          width: 95%;
        }
      }
      .divButton + .divButton {
        display: flex;
        justify-content: flex-end;
      }
      .divButton {
        width: 100%;
      }
    }
  }
}
