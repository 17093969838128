@import '../../styles/style.scss';

.container {
  margin: 8px 0;
  .input {
    display: flex;
    cursor: pointer;
    svg {
      min-width: 15px;
      width: 15px;
      min-height: 15px;
      height: 15px;
      margin: 5px 20px 0 0;
    }
    label {
      font-size: 16px;
      margin-bottom: 0;
    }
    .checked {
      @include themify('color', 'primary-primary');
      font-weight: bold;
    }
    .unchecked {
      color: #6a727b;
    }
  }
}
.errorLabel {
  font-size: 0.75em;
  color: $error-red-alert;
}
