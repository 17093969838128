.alertChat{
  position: fixed;
  bottom: 70px;
  right: 10px;
  background: #fff;
  width: 170px;
  z-index: 999;
  padding: 25px 10px 10px 10px;
  border-radius: 5px;
  border: 1px solid #c4c8cd;
  font-size: 13px;
	box-shadow: 0 0 0 0 rgba(0, 106, 255, 1);
	transform: scale(1);
	animation: pulse 2s infinite;
}

@keyframes pulse {
	0% {
		transform: scale(0.98);
		box-shadow: 0 0 0 0 rgba(0, 106, 255, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 5px rgba(0, 106, 255, 0);
	}

	100% {
		transform: scale(0.98);
		box-shadow: 0 0 0 0 rgba(0, 106, 255, 0);
	}
}

.rotateIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  svg {
    transform: rotate(-90deg) !important;
  }
}

.iconChat{
  position: absolute;
  top: -25px;
  margin-left: 55px;
  background: #1434cb;
  border-radius: 50%;
  padding: 5px;
  width: 38px;
  border: 2px solid #eaf0f6;
}

.iconClose{
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  border: none;
  background-color: inherit;
}
