@import '../../../styles/colors';
@import '../../../styles/media_queries';

.wrapperImprovement{
  margin-top: 40px;
  font-size: 16px;
  .actionButtons{
    margin-top: 20px;
    display: flex;
    gap: 16px;
  
    @media (max-width: $width-large) { 
      button {
        flex: 1;
      }
    }
  }
}

.alertError{
  color: $error-red-alert
}
