@import '../../styles/colors';
@import '../../styles/themes/themes.scss';

.boxListCards {
  width: 100%;
}
.openListCards {
  position: relative;
  span:not(.icon) {
    @media screen and (max-width: 1100px) {
      font-size: 1.5rem;
    }
  }
  img {
    @media screen and (max-width: 400px) {
      width: 4.2rem;
      height: 2.8rem;
      margin: 0px 0.5rem;
    }
    margin: 0px 1.2rem 0px 0px;
    width: 5.3rem;
    height: 3.2rem;
  }
}

.listCards {
  transition: all linear 0.2s;
  width: 45rem;
  border-radius: 5px;
  @include themify('background', 'text_and_background');
  position: absolute;
  left: -80px;
  right: 0px;
  top: 55px;
  z-index: 10;
  box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.1);

  ul {
    list-style: none;
    max-height: 400px;
    overflow-x: auto;
    margin-right: 6px;
    padding-top: 2rem;
  }
}

@media (max-width: 760px) {
  .listCards {
    width: calc(100vw - 20px);
    left: 0;

    ul {
      left: 0;
      margin: 0;
    }
  }
}

.icon {
  margin-left: 1rem;
  font-size: 1.5rem;
  @include themify('color', 'secondary');
}

.buttonCards {
  background: transparent;
  border: none;
  @include themify('color', 'primary');
  font-size: 1.8rem;
  text-align: left;
  display: flex;
  align-items: center;

  @media(forced-colors: active){
    border: 1px solid;
  }

  .name {
    white-space: nowrap;
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  @media screen and (min-width: 400px) and (max-width: 550px) {
    .name {
      max-width: 150px;
    }
  }

  @media screen and (min-width: 550px) and (max-width: 650px) {
    .name {
      max-width: 250px;
    }
  }

  @media screen and (min-width: 650px) and (max-width: 992px) {
    .name {
      max-width: 350px;
    }
  }

  @media screen and (min-width: 992px) and (max-width: 1280px) {
    .name {
      max-width: 150px;
    }
  }

  @media screen and (min-width: 1280px) {
    .name {
      max-width: 60%;
    }
  }

  @media screen and (min-width: 1400px) {
    .name {
      max-width: 100%;
    }
  }
}

.hideMenu {
  display: none;
}

.showMenu {
  display: block;
}
