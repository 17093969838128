@import '../../../styles/themes/themes.scss';
@import '../../../styles/colors';
@import '../../../styles/fonts';

.faqText {
    display: flex;
    flex-direction: column;
    padding: 2rem 0 4rem 0;
    font-size: 16px;
    line-height: 24px;
    text-align: justify;
    @include themify('color', 'subtitle');

    @include themify('color', 'primary-primary', ' a');
    a {
        font-weight: bold;
    }

    ul {
        margin-left: 30px;

        li {
            a {
                color: $dark-blue;
                font-family: $font-regular;
                text-decoration: underline;
            }
        }
    }
}

.feedbackContainer {
    display: flex;
    align-items: flex-end;
    margin-top: 16px;
    gap: 16px;
}

.feedbackLabel {
    @include themify('color', 'subtitle');
    font-size: 12px;
    margin-bottom: 0;
}

.wrapperFaqImprovement{
  width: 100%;
}
