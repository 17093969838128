@import '../../styles/style.scss';

.container {
  width: 100%;
  overflow-x: hidden;

  .body {
    display: flex;
    .menuArea {
      @media(forced-colors: active){
        border-right: 1px solid;
      }
    }
    .content {
      flex: 1;
      padding: 100px 90px 100px 50px;
    }

    @media(min-width: 1200px) {
      max-width: 2100px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.content {
  width: calc(100% - 380px);
  max-width: 100%;
}

@media (max-width: 992px) {
  .container {
    .headerBack {
      display: flex;
      align-items: center;
      flex: 1;
      height: 50px;
      padding: 0 1.4rem;
      a {
        font-size: 18px;
        @include themify('color', 'primary');
      }
    }

    .body {
      flex-direction: column;
      .content {
        width: 100%;
        max-width: 100%;
        padding: 40px 20px;
      }
    }
  }
}
