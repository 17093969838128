@import '../../../styles/style.scss';

.subMenu {
  position: absolute;
  left: 0px;
  top: 80px;
  width: 100vw;
  @include themify('background', 'text_and_background');
  overflow: auto;
  -webkit-box-shadow: inset 0px 5px 11px 0px rgba(0, 0, 0, 0.09);
  -moz-box-shadow: inset 0px 5px 11px 0px rgba(0, 0, 0, 0.09);
  box-shadow: inset 0px 5px 11px 0px rgba(0, 0, 0, 0.09);
}

.title {
  padding: 15px 9rem;
  @media screen and (min-width: 1024px) {
    padding: 15px 10rem;
  }
  display: flex;
  align-items: center;
  img {
    width: 60px;
    margin-right: 20px;
  }
  svg {
    margin-right: 20px;
  }
  h1 {
    font-size: 3.3rem;
    @include themify('color', 'primary');
    margin-bottom: 0px;
  }
}

.divisor {
  width: 100%;
  height: 1px;
  display: block;
  @include themify('background', 'secondary-inverse');
  margin: 5px 0px;
}

.countryList {
  padding: 2rem 9rem 1.8rem;

  @media screen and (min-width: 1024px) {
    padding: 2rem 10rem 1.8rem;
  }
  display: flex;
  justify-content: space-between;
}
