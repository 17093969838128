@import '../../styles/themes/themes.scss';

.Container {
  ul {
    list-style-type: none;

    li {
      position: relative;
      display: flex;
      align-items: center;
      min-height: 70px;
      height: auto;

      p {
        margin: 0 10px;
      }
      .dateTime {
        display: flex;
        flex-direction: column;
        padding-right: 20px;
        font-size: 12px;
      }
      .status {
        padding: 10px 0 10px 20px;
        flex: 1;

        span{
          font-size: 13px;
        }

        @media (max-width: 570px) {
          font-size: 14px;
        }
      }

      &.transparentLine::before {
        background: transparent !important;
      }

      &:first-child {
        &::before {
          height: 50%;
          top: 50%;
        }
      }

      &:last-child {
        font-weight: bolder;
        &::before {
          height: 50%;
          bottom: 50%;
        }
      }

      @include themify('background-color', 'secondary-secondary', '::before');
      &::before {
        content: '';
        position: absolute;
        width: 2px;
        left: 102px;
        height: 100%;
      }
    }

    .timeDot.last {
      @include themify('background', 'secondary-secondary');
      outline: 1px solid;
      outline-offset: 5px;
      @include themify('border-color', 'secondary-secondary');
    }
  }
}

.timeDot {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  left: 98px;
  @include themify('background-color', 'primary-primary');
}
