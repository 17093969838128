@import '../../../styles/style.scss';
@import '../../../styles/media_queries';

.container {
    @media screen and (max-width: $width-extra-large) {
        margin: 0 2.5%;
    }
}

.title {
    width: 100%;
    margin-bottom: 4rem;
    font-size: 3.3rem;
    @include themify('color', 'primary-primary');

    @media screen and (max-width: $width-small) {
        font-size: 2.8rem;
    }
}