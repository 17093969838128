@import '../../../styles/media_queries';
@import '../../../styles/fonts';
@import '../../../styles/colors';
@import '../../../styles/themes/themes.scss';

.container {
  position: relative;
  border: none;
  .blackCover {
    position: absolute;
    top: 0;
    height: 100%;
    left: 0;
    width: 100%;
    background: linear-gradient(45deg, #000, #555);
    opacity: 0.5;
  }
  .banner {
    display: flex;
    flex-direction: row;
    .bannerImg {
      flex: 1 1;
      width: 50%;
      height: 820px;
      background-size: cover;
      background-position-x: center;
      background-position-y: top;
      background-repeat: no-repeat;
    }
  }
  .title {
    position: absolute;
    top: 40%;
    width: 100%;
    text-align: center;
    @include themify('color', 'text_and_background');
    font-size: 50px;
    font-weight: 300;
    font-family: $font-light;
    padding: 0 27%;
  }
}

.childrenArea {
  position: absolute;
  bottom: -16%;
  width: 100%;
  @media screen and (min-width: 1300px) {
    padding: 0 9rem;
  }
}

@media (max-width: $width-large) {
  .container {
    .banner {
      flex-direction: column;
      height: 820px;
      .bannerImg {
        width: 100%;
        height: 50%;
        @media screen and (max-width: 920px) and (min-width: 800px){
          background-position-x: left !important;
        }
      }
      @media screen and (max-width: 500px) {
        height: 600px !important;
      }
    }
    .title {
      top: 30%;
      font-size: 33px;
      padding: 10%;
      text-align: left;
    }
  }
  .childrenArea {
    bottom: -22%;
  }
}

.cointainerSlideMarketing {
  max-height: 820px;
  position: relative;
}

.cointainerSlideMarketing.theme-master
  :global(.slick-dots .slick-active)
  .boxDots {
  border: 2px solid;
  border-color: get-theme-color('master', 'secondary-secondary');
  
  .dots {
    background: get-theme-color('master', 'secondary-secondary');
  }
}

.cointainerSlideMarketing.theme-afluent
  :global(.slick-dots .slick-active)
  .boxDots {
  border: 2px solid;
  border-color: get-theme-color('afluent', 'secondary-pressed');
  
  .dots {
    background: get-theme-color('afluent', 'secondary-pressed');
  }
}

.cointainerSlideMarketing :global(.slick-dots) {
  list-style: none;
  position: absolute;
  right: 0px;
  top: 50%;
}

.cointainerSlideMarketing :global(.slick-dots) {
  position: absolute;
  right: 0;
  bottom: 50%;

  padding: 50px 10px 10px;
  margin: 0;

  width: fit-content;
  height: fit-content;
  background: rgba(255, 255, 255, 0.7);
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.cointainerSlideMarketing :global(.slick-dots li) {
  display: block;
  margin: 10px 0;
}

.boxDots {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.dots {
  width: 15px;
  height: 15px;
  display: block;
  @include themify('background-color', 'primary-primary');
  border-radius: 50%;
  border: none;

  &:hover,
  &:focus {
    @include themify('background-color', 'primary-hover');
  }
}

.cursorPointer {
  cursor: pointer !important;

  &:focus {
    opacity: 0.9;
  }
}

.controlsArea {
  position: absolute;
  right: 10px;
  bottom: 45%;
  z-index: 8;

  .playButton {
    background: transparent;
    border: none;
  }

  .dividingLine {
    height: 2px;
    @include themify('background-color', 'secondary-secondary');

    position: absolute;
    left: -5px;
    right: -2px;
    top: 35px;
    opacity: 0.5;
  }
}


@media screen and (min-width: 2300px) {
  .bannerImg{
    background-position: center 20% !important;
  }
}
