@import '../../styles/_colors.scss';
@import '../../styles/media_queries';
@import '../../styles/themes/themes.scss';

.container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 170px;
  @include themify('background', 'primary');
  margin: 0px 0 80px;

  @media (forced-colors: active) {
    border: 1px solid;
  }

  .image {
    margin-bottom: 140px;
    img {
      height: 192px;
      width: fit-content;
    }
  }
  .imgAfluent {
    margin-bottom: 35px;
    margin-left: 50px;
  }
  .content {
    display: flex;
    flex: 1 1;
    justify-content: center;
    align-items: center;
    .text {
      @include themify('color', 'text_and_background');
      font-style: normal;
      font-size: 24px;
      line-height: 130.5%;
      margin-right: 6rem;
    }
  }

  .upgradeBtn {
    width: 190px;
    height: 53px;
    margin-right: 5%;

    .btnText {
      font-size: 16px;
    }
  }
}

@media (max-width: $width-large) {
  .container {
    flex-direction: column;
    justify-content: flex-end;
    height: 330px;
    margin: 30px 0 60px;

    .image {
      margin-bottom: 20px;
      img {
        width: 298px !important;
        height: fit-content;
      }
    }
    .content {
      width: 90%;
      .text {
        font-size: 20px;
        margin-right: 0;
      }
    }

    .upgradeBtn {
      margin-top: 50px;
      margin-bottom: 30px;
      margin-right: 0;
    }
  }
}

@media (max-width: $width-small) {
  .container {
    margin: 0 0 60px;
    .image {
      img {
        width: 250px !important;
        height: fit-content;
      }
    }
    .upgradeBtn {
      margin-top: 30px;
      width: 90%;
    }
  }
}
