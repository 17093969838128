@import '../../../styles/style.scss';

.container {
  @include themify-half-color-gradient();
  
  .loadingContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 400px;
  }
}
