@import '../../../../../styles/themes/themes';
@import '../../../../../styles/fonts';

.checkbox {
  padding-left: 15px;
  padding-right: 15px;
  @include themify('color', 'const_gray');
  font-weight: 400;

  .financialInstitution {
    font-family: $font-regular;
    font-weight: 600;
  }

  .itemCheckBox {
    margin-bottom: 27px;
  }
}
