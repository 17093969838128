@import '../../../styles/colors';
@import '../../../styles/fonts';
@import '../../../styles/media_queries';

.container {
  display: flex;
  flex-direction: column;
}

@media (max-width: $width-small) {
  .container {
    flex: 1;
  }
}
