.iemsRectangle{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  
    img{
      width: 213.25px;
      height: 230px;
      overflow: hidden;
      position: relative;
    }
  }
  .blueRectangle{
    width: 734px;
    height: 230px;
    padding: 35px 0px 0px 20px;
    background-color: #021E4C;
  
    h2{
      color: white;
      font-size: 23.04px;
      font-weight: 600;
      line-height: 27.65px;
      text-align: left;
    }
  
    p{
      color: white;
      margin-top: 10px;
    }
  
    button{
      background-color: transparent;
      border-color: white;
      margin-top: 10px;
      padding: 5px;
    }
  
    a{
      color: white !important;
    }
  }
  @media (max-width: 1600px) {
    .blueRectangle{
      width: 534px;
    }
  }

  @media (max-width: 1300px) {
    .blueRectangle{
      width: 734px;
    }
  }
  
  @media (max-width: 768px) {
    .iemsRectangle {
      flex-direction: column;
    }
  
    .blueRectangle {
      width: 100%;
      height: auto;
      padding: 20px;
    }
  
    .iemsRectangle img {
      display: none;
    }
  
    .blueRectangle h2 {
      font-size: 18px;
      line-height: 22px;
    }
  
    .blueRectangle p {
      font-size: 14px;
    }
  }