@import '../../../../../styles/style.scss';

.containerCardItem {
  &:hover {
    cursor: pointer;
    .cardName {
      display: inline;
      border-bottom: 1px solid;
      @include themify('border-bottom-color', 'secondary-secondary');
    }
  }

  img {
    width: 150px;
    height: 95px;
    @media (max-width: 992px) {
      width: 230px;
      height: 145px;
    }
  }

  .cardInfo {
    display: block;

    span {
      display: block;
      width: 100%;
    }
    .cardType {
      font-size: 14px;
      @include themify('color', 'subtitle');
      margin-bottom: 10px;
      @media (max-width: 992px) {
        margin: 10px 0 0;
      }
    }
    .cardName {
      @include themify('color', 'primary');
      margin-left: 2px;
      width: fit-content;
      @media (max-width: 992px) {
        display: none;
      }
    }
    .cardSelected {
      display: inline;
      border-bottom: 1px solid;
      font-family: $font-regular;
      @include themify('border-bottom-color', 'secondary-secondary');
      font-weight: bold;
    }
  }
}
