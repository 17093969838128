@import '../../../../styles/themes/themes.scss';

.stepOne {
  h2 {
    font-size: 26px;
    @include themify('color', 'primary');
  }

  .boxPDFs {
    display: flex;
    flex-direction: column;
    margin-top: 15px;

    .pdf {
      display: flex;
      align-items: center;
      margin: 15px 15px;

      .pdfIcon {
        margin-right: 30px;
      }
    }
  }

  .boxCheckBoxs {
    margin: 40px 0 40px;
    padding: 25px 0 25px 15px;
    @include themify('border-color', 'tertiary');
    border-top: 1px solid;
    border-bottom: 1px solid;
    span {
      span {
        font-style: oblique;
      }
    }
    a {
      @include themify('color', 'link');
      text-decoration: underline;
    }
  }

  .footerBtn {
    p {
      font-size: 16px;
    }

    .buttonArea {
      display: flex;
      justify-content: flex-end;
      width: 100%;

      button {
        width: 180px;
      }
    }
  }
}
