@import '../../../styles/_colors.scss';
@import '../../../styles/_fonts.scss';
@import '../../../styles/_media_queries.scss';
@import '../../../styles/themes/themes.scss';

.navigation {
  display: none;

  @media (max-width: $width-large) {
    @include themify('background', 'text_and_background');
    background-color: #fff;
    bottom: -1px;
    display: flex;
    height: 5rem;
    left: 0;
    position: fixed;
    right: 0;
    transform: translateZ(0);
    width: 100vw;
    will-change: transform;
    z-index: 900;
    transition: all 0.1s;

    .item {
      flex: 1 1;
      @include themify('color', 'primary');
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      font-size: 12px;
      justify-content: center;
      text-align: center;

      .itemContent {
        display: flex;
        flex-direction: column;

        span {
          font-size: 10px;
          color: #6a727b;
        }


        .active {
          & + span {
            font-family: $font-regular;
            font-weight: bold;
            @include themify('color', 'primary');
          }

          svg {
            @include themify('color', 'secondary');
          }
        }


        img {
          height: 30px;
          width: 30px;
        }

        svg {
          height: 30px;
          width: 30px;
        }
      }
    }

    .buttonArea {
      width: 90%;
      height: 53px;
      margin: 50px auto 0;
    }
  }
}
