@import '../../styles/fonts';
@import '../../styles/colors';
@import '../../styles/media_queries';
@import '../../styles/themes/themes.scss';

.Outercontainer {
  display: flex;
  flex-direction: column;
  padding-bottom: 4rem;
}

.container {
  @include themify('background', 'text_and_background');
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  height: 100%;

  @media (forced-colors: active) {
    border: 1px solid;
  }

  .disclaimer {
    display: inline-block;
    margin-left: 21px;
    margin-bottom: 26px;

    @media screen and (min-width: $width-large) and (max-width: $width-extra-large) {
      display: none;
    }

    @media screen and (max-width: $width-small) {
      display: none;
    }
  }

  .disclaimerResponsive {
    display: none;
    position: absolute; 
    right: 21px;
    bottom: 26px;

    @media screen and (min-width: $width-large) and (max-width: $width-extra-large) {
      display: inline-block;
    }

    @media screen and (max-width: $width-small) {
      display: inline-block;
      position: absolute;
      bottom: 5px;
      left: 26px;
      z-index: 1;
      max-width: 50%;
    }
  }

  .disclaimerWithMargin {
    margin-top: 8px;
  }
}

.totalReimbursedDashboard {
  padding: 26px 21px 0;
  position: relative;
  max-height: 140px;
  display: flex;
  gap: 15px;
  height: 100%;

  @media screen and (min-width: $width-large) and (max-width: 1500px) {
    flex-direction: column;
    max-height: 300px;
    padding-bottom: 26px;
  }

  @media screen and (max-width: $width-small) {
    flex-direction: column;
    max-height: 300px;
    padding-bottom: 26px;
  }
}

.left {
  align-items: center;
  display: flex;
  justify-content: left;
  min-height: 69px;
  padding-top: 1%;
  width: 100%;

  .containerDescription {
    display: flex;
    flex-direction: column;
    padding-left: 1rem;
    justify-content: center;
    align-items: flex-start;
  }

  .icon {
    @include themify('color', 'secondary');
    align-self: center;
  }

  &.border {
    border-left: 1px solid;
    @include themify('border-color', 'const_gray');
  }

  h6 {
    @include themify('color', 'subtitle');
    max-width: 350px;
    font-size: 20px;
    font-family: $font-light;
    margin-bottom: 0;
    font-weight: bold;
  }

  .tooltip {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ContainerTooltip {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.right {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  min-height: 69px;
  padding-top: 1%;
  width: 100%;

  .containerValue {
    display: flex;
    gap: 3px;
    align-items: center;
    justify-content: space-between;

    .text {
      .totalAmount {
        @include themify('color', 'primary');
        font-size: 40px;

        .currencyCode {
          font-size: 30px;
        }
        .decimal {
          font-size: 30px;
        }
      }
    }

    .redirectLink {
      width: 100%;
      margin-right: -16px;
      display: flex;
      justify-content: flex-end;

      @media screen and (min-width: $width-large) and (max-width: $width-extra-large) {
        justify-content: flex-start;
      }

      @media screen and (max-width: $width-small) {
        position: absolute;
        bottom: 5px;
        right: 5%;
      }
    }
  }

  @media screen and (min-width: $width-large) and (max-width: $width-extra-large) {
    justify-content: flex-start;
  }

  @media screen and (max-width: $width-small) {
    justify-content: flex-start;
  }
}

.arrow {
  @include themify('color', 'text_active_element');
  margin-left: 2px;
  order: 2;
  cursor: pointer;

  @media screen and (min-width: 1300px) {
    order: 5;
  }

  @media screen and (max-width: $width-small) {
    margin-left: 0px;
    position: absolute;
    right: 5%;
  }
}

.showMoreActive {
  display: flex;
  justify-content: flex-end;
}

.rotateIcon {
  svg {
    transform: rotate(180deg);
  }
}

.flexContainerRow {
  flex-direction: row;
}

.flexContainerColumn {
  flex-direction: column;
}

@media screen and (max-width: $width-extra-large) and (min-width: $width-large) {
  .right {
    .containerValue {
      .text {
        .totalAmount {
          font-size: 35px;

          .currencyCode {
            font-size: 20px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 360px) {
  .right {
    .containerValue {
      .text {
        .totalAmount {
          font-size: 25px;

          .currencyCode {
            font-size: 20px;
          }
        }
      }
    }
  }
}

.containerLink {
  display: flex;
  flex-direction: row;
  padding-top: 2rem;
  align-items: center;
  justify-content: flex-end;

  @media screen and (max-width: $width-small) {
    justify-content: center;
  }
}
