@import '../../../../styles/fonts';
@import '../../../../styles/colors';
@import '../../../../styles/media_queries';
@import '../../../../styles/themes/themes.scss';

.recents {
  @include themify('background', 'text_and_background');
  border-radius: 3px;
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1);
  padding-bottom: 2%;

  .textCenter {
    text-align: center;
  }

  .emptyMessage {
    margin: 0 2% 0 3%;
  }

  .line {
    margin-top: 3%;
    @include themify('color', 'link');
    font-size: 16px;
    padding-bottom: 2%;
    padding-left: 4% !important;
    padding-right: 4% !important;

    span {
      width: 100%;
      display: block;
      margin: 0;
    }

    .status {
      font-size: 12px;
      display: flex;
      justify-content: center;

      a {
        @include themify('color', 'primary');
        display: block;
        font-size: 16px;
        text-align: right;
        font-weight: 600;
        font-family: $font-regular;
        max-height: 48px;

        &:hover {
          text-decoration: underline !important;
        }
      }
    }
  }

  @media (max-width: 990px) {
    margin-bottom: 4%;
  }

  @media (max-width: $width-medium) {
    .line {
      line-height: 30px;
      margin-bottom: 5%;
      padding-bottom: 0;

      &.lastItem {
        margin-bottom: 0;
      }

      .review,
      .complete {
        margin-top: 4%;
        padding: 2% 1%;
        width: 30%;
      }

      .status {
        font-size: 12px;
        justify-content: flex-start;

        a {
          text-align: left;
        }
      }
      .lineBottom {
        color: black;
        height: 1px;
        margin-top: 12%;
        width: 10%;

        &.lineNone {
          display: none;
        }
      }
    }
  }

  @media (max-width: $width-small) {
    height: 340px;
    .line {
      padding: 0;
      margin: 0;
      .status {
        padding-top: 2%;

        a {
          height: 5px;
        }
      }
    }
  }
}
