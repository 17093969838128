@import '../../../styles/fonts';
@import '../../../styles/colors';
@import '../../../styles/themes/themes.scss';

.bannerContainer {
  position: relative;
  .banner {
    display: flex;
    background-size: 100%;
    background-repeat: no-repeat;

    .blackCover {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      background: linear-gradient(45deg, #000, #555);
      opacity: 0.5;
    }
  }
}

