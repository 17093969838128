@import '../../../../styles/media_queries';
@import '../../../../styles/fonts';
@import '../../../../styles/colors';
@import '../../../../styles/themes/themes.scss';

.pagination {
    display: flex;
    align-items: center;
    padding-bottom: 2rem;
    justify-content: flex-end;
  
    a {
      @include themify('color', 'primary');
      margin-right: 1rem;
      padding: 1.2rem;
      font-weight: bold;
      cursor: pointer;

      font-size: 1.5rem;
      border-radius: 5px;
  
      @media screen and (max-width: 992px) {
        margin-right: 1rem;
      }
      @media screen and (min-width: 1100px) {
        font-size: 1.6rem;
      }
      &:hover {
        text-decoration: none;
        @include themify('background-color', 'text_and_background');
        @include themify('color', 'primary');
      }
    }

    .activepage {
      a {
        @include themify('background-color', 'primary');
        @include themify('color', 'text_and_background');
        @include themify('border-color', 'primary');
      }   
    }
  }