.container {
  padding: 0 9rem;
  margin-bottom: 10rem;
}

.loadingContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 15rem 0;
}
