@import '../../styles/colors';
.ShadowBox {
  margin-bottom: 0;
  padding: 40px;
  -webkit-box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1);
  background: $white;
  border-radius: 3px;

  @media (forced-colors: active) {
    border: 1px solid;
  }

  @media (max-width: 992px) {
    padding: 20px;
  }
  @media(min-width: 1200px){
    max-width: 1760px;
    margin: 0 auto
  }
}
