@import '../../../../../../../styles/media_queries';

.form {
  hr {
    margin: 20px 0;
  }

  .footer {
    display: flex;
    align-items: center;
    width: 100%;

    button {
      max-width: 220px;
      padding: 0 8px;
      @media (max-width: $width-medium) {
        max-width: none;
      }
    }
  }
}

@media (max-width: 1199.98px) {
  .section {
      padding: 2rem;
  }
}
