@import '../../../styles/media_queries';
@import '../../../styles/colors';
@import '../../../styles/fonts';
@import '../../../styles/themes/themes.scss';

.container {
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  justify-content: space-between;
  padding: 5px !important;

  .image {
    position: relative;
    cursor: pointer;
    background-size: contain !important;
    background-position: center center !important;
    background-repeat: no-repeat !important;

    .contentSale {
      display: flex;
      width: 50%;
      position: absolute;
      bottom: 0px;
      left: 0px;
      flex-direction: row;

      .boxLogo {
        @include themify('background', 'text_and_background');
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          height: 100%;
        }
      }
      span {
        width: 50%;
        height: 100%;
        @include themify('color', 'primary');
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 22px;
        font-weight: 400;
        @include themify('background', 'secondary');
        margin-left: -1px;
        font-family: $font-regular;
        text-decoration: none;
      }
    }
  }

  .contentInfo {
    display: flex;
    flex-direction: column;
    width: 100%;

    .company {
      font-size: 16px;
      font-weight: 400;
      @include themify('color', 'subtitle');
      margin-bottom: 14px;
      margin-top: 10px;
      cursor: pointer;
    }

    .containerInfo {
      cursor: pointer;
    }

    .info {
      font-size: 26px;
      font-weight: 400;
      @include themify('color', 'subtitle');
      line-height: 30px;
    }

    .contentDue {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 20px;

      span {
        font-size: 16px;
        font-weight: 400;
        @include themify('color', 'subtitle');
        align-self: center;
        margin-left: 10px;
      }
    }

    .contenCompany {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .icon {
        font-size: 44px;
        @include themify('color', 'secondary');
        cursor: pointer;
        padding: 1rem;
      }
    }
  }
}

@media (min-width: 990px) {
  .container {
    .image {
      width: 100%;
      height: 320px;
    }

    .contentSale {
      height: 100px;
    }

    .contentInfo {
      height: 180px;
      padding: 2rem 0;
    }
  }
}

@media (max-width: 990px) {
  .container {
    .image {
      width: 100%;
      height: 220px;
    }

    .contentSale {
      height: 80px;
    }

    .contentInfo {
      height: 220px;
      padding: 1rem 0;
    }
  }
}
