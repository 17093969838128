@import '../../../styles/style.scss';

.container {
  width: 100%;
  margin-bottom: 40px;
  padding-top: 10px;
  position: relative;

  .stepsBackground {
    position: absolute;
    top: 0;
    background: white;
    width: 100%;
    height: 40px;
    z-index: 1;
    border-radius: 50px;
    @media (forced-colors: active) {
      border: 1px solid;
    }
  }

  .steps {
    display: flex;
    align-items: center;
    z-index: 2;
    padding: 0 5px;
  }
}

@media (max-width: 992px) {
  .container {
    .line {
      position: relative;
      flex: 1 1;
    }
  }
}
