.container {
  margin: 150px 0 470px;

  .path {
    background-repeat: no-repeat;
    background-position: 150px 0;
    background-size: contain;

    height: 2200px;

    position: relative;

    .pathItem {
      position: absolute;
      right: 0;
      left: 0;

      &:nth-child(1) {
        top: 0px;
        padding-left: 100px;
      }
      &:nth-child(2) {
        top: 556px;
        padding-left: 0;
      }
      &:nth-child(3) {
        top: 1043px;
        padding-left: 385px;
      }
      &:nth-child(4) {
        top: 1610px;
        padding-left: 60px;
      }
      &:nth-child(5) {
        top: 2168px;
        padding-left: 100px;
      }
    }
  }
}

@media (max-width: 1150px) {
  .container {
    margin: 100px 0 50px;
    .path {
      background-image: none !important;
      height: auto;

      .pathItem{
        position: static;
        margin: 20px 0;
        padding-left: 0 !important;
      }
    }
  }
}
