@import '../../../../../styles/style.scss';

.recentClaimsItem {
  display: flex;
  @include themify('background', 'text_and_background');
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  border-radius: 3px;
  margin-bottom: 1.5%;
  padding: 30px 21px;
  flex-direction: column;
  width: 100%;

  @media screen and (min-width: 992px) {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
  }

  .left {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    @media screen and (min-width: 992px) {
      width: 40%;
      flex-direction: row;
      justify-content: flex-start;
    }

    .icon {
      order: 2;
      @media screen and (min-width: 992px) {
        order: 1;
      }

      img {
        width: 75px;
        height: auto;
      }
    }

    .text {
      @include themify('color', 'text-paragraph_subtle');
      display: flex;
      font-size: 16px;
      flex-direction: column;

      order: 1;
      @media screen and (min-width: 992px) {
        order: 2;
        padding-left: 4%;
        padding-right: 4%;
      }
    }
  }

  .center {
    display: flex;
    flex-direction: column;
    width: 100%;
    @media screen and (min-width: 992px) {
      width: 20%;
    }

    span {
      line-height: 30px;

      strong {
        font-family: $font-semibold;
        font-weight: 600;
      }
    }
  }

  .right {
    display: flex;
    padding-top: 1%;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    
    @media screen and (min-width: 992px) {
      width: 40%;
      align-items: center;
      justify-content: flex-end;
      flex-wrap: nowrap;
    }

    .option {
      display: flex;
      width: 120px;
      align-items: center;
      justify-content: center;

      .button {
        width: 100%;
      }
    }

    .arrow {
      margin-left: 30px;
      cursor: pointer;
    }
  }
}

.hideButtons {
  button {
    display: none;
  }
}

.showMoreActive {
  display: flex;
  justify-content: flex-end;
}
