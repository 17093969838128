@import '../../styles/colors';
@import '../../styles/themes/themes.scss';

.container {
  position: relative;
  height: 10px;
  width: 100%;
}

.line {
  position: absolute;
  top: 4px;
  width: 100%;

  height: 4px;
}

.circle {
  position: absolute;
  top: 0;

  width: 12px;
  height: 12px;
  border-radius: 6px;
}

.leftCircle {
  left: 0;
}

.rightCircle {
  right: 0;
}

.green {
  @include themify('background-color', 'positive-graphics');
}

.secondary {
  @include themify('background-color', 'secondary-secondary');
}

.alert {
  @include themify('background-color', 'disabled-graphics');
}