@import '../../../../../../../styles/colors';
@import '../../../../../../../styles/media_queries';

.separator {
  display: flex;
  flex-direction: row;
  align-items: center;

  h3 {
    flex: 1;
  }

  button {
    margin-left: 1.6rem;
    width: fit-content;
  }
}

.invalidFlightAlert {
  font-size: 1em;
  color: $error-red-alert;
}

.tooltipEn {
  &:hover {
    &:before {
      background-image: url('../../../../../../../assets/images/travelKit/boardingPassEn.png');
    }
  }
}

.tooltipPt {
  &:hover {
    &:before {
      background-image: url('../../../../../../../assets/images/travelKit/boardingPassPt.png');
    }
  }
}

.tooltipEs {
  &:hover {
    &:before {
      background-image: url('../../../../../../../assets/images/travelKit/boardingPassEs.png');
    }
  }
}

.tooltip {
  &:hover {
    &:before {

      background-size: contain;
      background-repeat: no-repeat;
      width: 350px;
      aspect-ratio: 1;
      max-width: 80vw !important;

      @media screen and (max-width: $width-large) {
        left: 0 !important;
      }

      @media screen and (max-width: $width-medium) {
        left: initial !important;
      }
    }
  }
}