@import '../../styles/colors';
@import '../../styles/fonts';
@import '../../styles/themes/themes.scss';

.titleSection {
  padding-right: 20px;
  .title {
    width: 100%;
    @include themify('color', 'primary');
    font-size: 2.5rem;
    font-weight: 300;
    margin-bottom: 2rem;
    align-items: center;
  }

  .subtitle {
    width: 100%;
    @include themify('color', 'text-paragraph_subtle');
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 1.6rem;
  }
}

@media (max-width: 992px) {
  .titleSection {
    .title {
      font-size: 20px;
      margin-bottom: 2rem;
    }
  }
}

@media (max-width: 620px) {
  .titleSection {
    padding-right: 0;
  }
}
