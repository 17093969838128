.boxModalResult {
  display: flex;
  margin-top: 4.8rem;
  flex-direction: column;
  width: 100%;
}

.boxButton {
  width: 100%;
  margin-bottom: 1rem;
}

@media screen and (min-width: 768px) and (max-width: 992px) {
  .boxModalResult {
    justify-content: center;
  }
}
@media screen and (min-width: 768px) {
  .boxModalResult {
    display: flex;
    flex-direction: row;
    margin-top: 4.8rem;
  }
  .boxButton {
    width: 180px;
    margin-right: 2rem;
  }
}
