@import '../../../../styles/style.scss';

.containerHighlight {
  width: 100%;
  max-width: 400px;
  display: flex;
  padding: 20px;
  border-radius: 10px;
  @include themify('background', 'const_light_gray');
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
  align-items: center;

  .iconHighlight{
    margin-right: 25px;
  }
  .textHighlight{
    font-weight: bold;
    color: #000;
    font-family: $font-regular;
  }
}
