@import '../../../../../styles/style.scss';

.formNurseLine {
  .footerBtns {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    @media (max-width: 760px) {
      flex-direction: column;
    }
  }
}

.ButtonSchedulelist {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.availableTimes {
  max-height: 300px;
  overflow: auto;
  max-width: 100%;
}
@media screen and (min-width: 992px) {
  .availableTimes {
    max-width: 400px;
  }
}

.buttonStep {
  width: 25%;

  @media (max-width: $width-small) {
    width: 100%;
  }
}
