@import '../../../../../../../../styles/themes/themes.scss';
@import '../../../../../../../../styles/fonts';

.personalArea {
  .fontGray12 {
    @include themify('color', 'tertiary');
    font-size: 14px;
  }
  .none {
    display: none;
  }

  .option {
    label {
      margin-bottom: 0px;
    }
  }
}
.elementRadio {
  @include themify('color', 'subtitle');
  font-size: 14px;
  .labelTitle {
    display: flex;
    margin: 20px 0px 14px;
    font-size: 16px;
    font-family: $font-regular;
    @include themify('color', 'link');
  }
}
