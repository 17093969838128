@import '../../../../styles/fonts';
@import '../../../../styles/colors';
@import '../../../../styles/themes/themes.scss';

.condition {
  @include themify('color', 'subtitle');
  font-size: 16.3625px;
  margin-left: 30px;
}

.conditionDisabled {
  @include themify('color', 'const_gray');
}

.diseasesList {
  padding-left: 30px;
  font-weight: 300;
  margin-bottom: 50px;
  list-style: none;
  @include themify('color', 'subtitle');
  li {
    min-height: unset;
  }
}

.container {
  margin-top: 40px;
  @include themify('color', 'primary');
  .termsArea {
    margin-bottom: 12px;
    h2 {
      font-size: 26px;
      @include themify('color', 'primary');
    }

    .pdf {
      display: flex;
      margin-top: 30px;
      margin-left: 15px;
      img {
        margin-right: 30px;
      }

      svg {
        margin-right: 30px;
      }

      .pdfInfo {
        display: flex;
        flex-direction: column;
      }
    }

    .disclaimer {
      @include themify('color', 'subtitle');
      margin: 20px 0 0 0;
      text-align: justify;

      svg {
        margin-right: 10px;
      }

      a {
        text-decoration: underline;
      }
    }
  }
  .acceptArea {
    padding: 25px 0 25px 0;
    border-top: 1px solid;
    @include themify('border-color', 'tertiary');
    border-bottom: 1px solid;
  }
  .footer {
    .buttonArea {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      button {
        height: 53px;
        max-width: 180px;
      }
    }
  }
}
