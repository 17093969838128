@import '../../../../../styles/style.scss';

.border {
  border-bottom: 1px solid;
}

.container {
  width: 100%;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  margin: 10px 0;
  padding: 0 2%;
  @media(forced-colors: active){
    border: 1px solid;
  }

  .accordionHeader {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    height: 76px;
    @include themify('border-color', 'const_gray');
    padding: 0 10px;

    .name {
      @include themify('color', 'subtitle');
      font-family: $font-regular;
      font-weight: 600;
      font-size: 1.6rem;
    }

    .moreInformation {
      display: flex;
      align-items: center;
      cursor: pointer;
      @include themify('color', 'secondary');

      .more_info {
        font-family: $font-regular;
        font-weight: 600;
        font-size: 1.6rem;
        @include themify('color', 'primary');
      }
      .icon {
        margin-left: 10px;
      }
    }
  }

  .content {
    margin-top: 30px;
    padding: 0 10px;
  }
}

@media (max-width: $width-small) {
  .container {
    .accordionHeader {
      .name {
        display: none;
      }
    }
  }
}
