@import '../../styles/style.scss';

.boxProfile {
  position: relative;

  .buttonProfile {
    width: 42px;
    height: 42px;
    border: none;
    border-radius: 50%;
    @include themify('background', 'primary');
    @include themify('color', 'text_and_background');
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-bottom: 0px;

    @media (forced-colors: active) {
      border: 1px solid;
    }
  }

  .subMenuEditProfile {
    transition: all linear 0.2s;
    @include themify('background', 'text_and_background');
    position: absolute;
    top: 60px;
    z-index: 10;
    right: 0px;
    list-style: none;
    border-right: 1px solid;
    border-left: 1px solid;
    border-bottom: 1px solid;
    @include themify('border-color', 'const_gray');
    
    li {
      border-bottom: 1px solid;
      @include themify('border-color', 'const_gray');
      
      &:last-child {
        border-bottom: none;
      }
      
      a {
        min-width: 180px;
        min-height: 56px;
        justify-content: flex-start;
      }
    }
  }

  .background {
    width: 100%;
    height: 100%;
    background: transparent;
    position: fixed;
    padding: 0;
    margin: 0;
    top: 0;
    left: 0;
  }
}
