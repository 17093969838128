@import '../../../../styles/style.scss';

.contentContainer {
  margin-top: 40px;

  @include themify('color', 'primary-primary');
  h2 {
    font-size: 26px;
    @include themify('color', 'primary-primary');
    text-align: justify;
  }
  .personalInfocontainer {
    padding: 20px 10px;
    margin: 30px 0px 20px 0px;
    border: 1px solid rgba(20, 52, 203, 0.4);
    .fieldContainer {
      display: flex;
      flex-direction: row;

      font-size: 16px;
      p {
        margin: 0px;
      }
    }
  }
  .disclaimerContainer {
    display: flex;
    justify-content: space-between;

    h4 {
      font-size: 14px;
      font-style: italic;
      margin-bottom: 60px;
    }
  }

  .footer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 0.8rem;

    button {
      width: 180px;
    }

    @media (max-width: $width-small) {
      flex-direction: column;
      button {
        width: 100%;
      }
    }
  }
}

.imageLink {
  text-align: center;
  .link {
    width: 130px;
    margin: 0 10px;
    @media (max-width: 500px) {
      width: 40%;
      min-width: 100px;
    }
  }
}

.groupInline {
  margin-top: 30px;
  display: flex;
  .appImagesContainer {
    display: flex;
    flex-direction: column;
    a {
      margin: 10px 0px;
    }
  }
}

.QRcode {
  width: 20%;
  margin: 0px 20px;
}
