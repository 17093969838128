@import '../../../../../styles/style.scss';

.certificatesArea {
  display: flex;
  padding: 3rem 0;
  justify-content: center;
  flex-direction: column;
  h4 {
    font-size: 1.5rem;
    margin-bottom: 1.25rem;
  }
  .certificateText {
    border-bottom: 1px solid #e0e0e0;
    color: #333;
    font-weight: 300;
    margin-bottom: 1%;
    padding-bottom: 1%;
    text-rendering: optimizeLegibility;
    margin-top: 20px;
  }
  .continue {
    width: 20%;
    @media screen and (max-width: $width-small) {
      margin-left: 0px;
      width: 100%;
      display: block;
      margin-top: 1rem;
    }
  }
  .back {
    border: 2px solid #1434CB;
    background-color: #fff;
    color: #1434CB;
    font-weight: 500;
    text-align: center;
    padding: 0.75rem 3rem;
    text-decoration: none;
    @media screen and (max-width: 768px) {
      margin-left: 0px;
      width: 100%;
      display: block;
    }
  }

  .certificateAreaItens {
    display: flex;
    width: 100%;
    flex-direction: column;
  }
}
