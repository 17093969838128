@import '../../../../../../styles/style.scss';
@import '../../../../../../styles/media_queries';

.certificatesArea {
  display: flex;
  padding: 30px 0;
  justify-content: center;
  flex-direction: column;

  h4 {
    font-size: 1.5rem;
    margin-bottom: 1.25rem;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    
    margin-bottom: 3rem;
    padding-bottom: 1%;

    @media screen and (max-width: $width-medium) {
      flex-direction: column;
      align-items: flex-start;
      gap: 1rem;
    }
  }
  
  .certificateText {
    color: #333;
    font-weight: 300;
    
    text-rendering: optimizeLegibility;
  }

  .generateButton {
    @media screen and (max-width: $width-medium) {
      width: 100%;
    }
  }
}
