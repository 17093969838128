@import '../../../../styles/colors';
@import '../../../../styles/fonts';
@import '../../../../styles/media_queries';
@import '../../../../styles/themes/themes.scss';

.container {
  display: flex;
  flex: 1;
  justify-content: space-around;
  min-height: 25rem;
  flex-direction: column;
  padding: 3rem 1rem 3rem 1rem;

  h2 {
    @include themify('color', 'primary-primary');
    font-style: normal;
    font-size: 2.8rem;
  }

  .text {
    @include themify('color', 'text-paragraph_subtle');
    font-style: normal;
    font-size: 1.8rem;
  }
}

@media (max-width: $width-small) {
  .button {
    width: 100%;
  }
}
