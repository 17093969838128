@import '../../../../../styles/style.scss';

.card {
  position: relative;

  display: flex;
  justify-content: center;
  @media (max-width: 500px) {
    margin: 12px 0;
  }

  .bodyImg {
    display: flex;
    align-items: flex-end;
    width: 100%;
    height: 100%;
    padding: 2rem;


    &:hover, &:focus {
      transition: transform 0.2s;
      transform: scale(1.1);
      padding: 3rem;
      font-family: $font-regular;
      @include themify('color', 'secondary-inverse');
    }

    img {
      width: 100%;
      height: max-content;
    }
    
    h3 {
      font-size: 26px;
      @include themify('color', 'text_and_background');
      z-index: 10;
    }

    .blackCover {
      position: absolute;
      top: 0;
      height: 100%;
      left: 0;
      width: 100%;
      background: linear-gradient(45deg, #000, #555);
      opacity: 0.3;
    }
  }

  .bodyDetails {
    width: 100%;
    height: 100%;
    position: relative;
    img {
      width: 100%;
      height: max-content;
      opacity: 0;
    }

    .content {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      @include themify('color', 'text_and_background');
      padding: 16px;
      .name {
        font-family: $font-regular;
        font-size: 16px;
        font-weight: 600;
      }
      .short_description {
        font-size: 16px;
        line-height: 24px;

        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .footer {
        display: flex;
        flex-wrap: wrap;

        position: absolute;
        left: 16px;
        bottom: 16px;
        gap: 16px;

        button {
          min-width: unset;
        }
      }
    }
  }
}

.priorityHeight {
  height: 46rem;
}

.commonHeight {
  height: 32rem;
}

.loadingContainer {
  width: 100%;
  height: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
}


.blackCoverItem {
  position: absolute;
  top: 0;
  height: 100%;
  left: 0;
  width: 100%;
  background: linear-gradient(to top, #000 18%, transparent 35%, transparent);
  opacity: 0.9;
}

.wrapper {
  width: 100%;
  height: 100%;
  cursor: pointer;
  background-size: cover;
  @include themify('color', 'text_and_background');
  overflow: hidden;
}
