@import '../../../../styles/themes/themes.scss';
@import '../../../../styles/fonts';

.cards {
  .list {
    margin-left: 0;
    margin-right: 0;

    .subtitleTypeCard {
      @include themify('color', 'subtitle');
      width: 100%;
      margin: 20px 0 20px 0;
      font-weight: 600;
      font-size: 16px;
      font-family: $font-regular;
    }
  }

  .loadingContainer {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .emptyProducts {
    text-align: center;
    width: 80%;
  }
}

@media (max-width: 992px) {
  .cards {
    .emptyProducts {
      width: 100%;
    }
  }
}

@media (max-width: 365px) {
  .cards {
    .list {
      margin-left: -15px;
      margin-right: -15px;
    }
  }
}
