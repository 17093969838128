@import '../../styles/colors';
@import '../../styles/themes/themes.scss';

.base {
  @include themify('color', 'secondary-secondary');
  font-size: 1.5rem;
  margin-top: 1.6rem;
  margin-bottom: 1.6rem;

  display: flex;
  flex-grow: 0;
  
}

.groupDivisor { 
  align-items: center;

  @include themify-repeating-linear-gradient('secondary-secondary', '::after');
  &::after {
    content: '';
    height: 10px;
    flex-grow: 1;
    opacity: 0.3;
    margin-left: 6px;
    margin-top: 4px;
  }
}

.simpleLine {
  @include themify('background-color', 'secondary-secondary', '::after');
  &::after {
    content: '';
    height: 1px;
    flex-grow: 1;
    margin-left: 6px;
    margin-top: 9px;
  }
}
