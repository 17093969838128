@import '../../../../../../../../styles/colors';
@import '../../../../../../../../styles/themes/themes.scss';
@import '../../../../../../../../styles/fonts';

.incidentAreal {
  margin-top: 0.7rem;
  margin-bottom: 20px;
  p {
    @include themify('color', 'primary');
    font-size: 1.2rem;
    font-weight: 400;
    margin-top: 0.1rem;
    margin-bottom: 0;
  }
  .elementRadio {
    @include themify('color', 'subtitle');
    font-size: 14px;
    .labelTitle {
      display: flex;
      margin-bottom: 15px;
      font-size: 16px;
      font-family: $font-regular;
      @include themify('color', 'link');
    }
  }
  .none {
    display: none;
  }
}
.marginBottom {
  margin-bottom: 12px;
  .buttonArea {
    display: flex;
    justify-content: flex-end;
    button {
      margin-right: -8px;
    }
  }

  .footer {
    display: flex;
    button {
      margin-left: -8px;
    }
  }
}
