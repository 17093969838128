@import '../../../styles/fonts';
@import '../../../styles/colors';
@import '../../../styles/themes/themes.scss';
@import '../../../styles/media_queries';

.bannerContainer {
  position: relative;

  .banner {
    display: flex;
    width: 100%;
    height: 520px;
    background-size: cover;
    background-position: 5% center;
    .content {
      padding: 120px 0 20px 90px;
      width: 70%;
      @include themify('color', 'text_and_background');
      z-index: 10;
      .benefit {
        font-size: 45px;
        margin-bottom: 12px;
      }
      .benefitDescription {
        max-width: 60%;
      }
      .redeemInfo {
        font-weight: bold;
        margin-top: 30px;
        margin-bottom: -30px;
      }
      .benefitInfo {
        margin-top: 60px;
        font-family: $font-regular;
      }

      .buttonsLogged {
        display: flex;
        margin-top: 60px;
        button {
          margin-top: 8px;
          margin-right: 10px;
          width: 180px;
        }
      }

      .loadingContainer {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      @media (min-width: 1920px) {
        padding: 120px 0 20px 14%;
      }
    }
    .blackCover {
      position: absolute;
      top: 0;
      height: 520px;
      left: 0;
      width: 100%;
      background: linear-gradient(45deg, #000, #555);
      opacity: 0.5;
    }
  }

  .childArea {
    position: absolute;
    right: 100px;
    bottom: 100px;
  }
}

@media (max-width: 1300px) {
  .bannerContainer {
    .banner {
      .content {
        .benefitDescription {
          max-width: none;
        }
      }
    }
  }
}

@media (max-width: 1300px) {
  .bannerContainer {
    .banner {
      background-position: center;
      height: 600px;
      .content {
        padding: 100px 2% 20px;
        width: 100%;
        .benefitInfo {
          margin-top: 40px;
        }
      }
      .divButton {
        display: none;
      }
      .blackCover {
        height: 600px;
      }
    }
  }
}

@media screen and (max-width: $width-medium) {
  .buttonsLogged {
    flex-direction: column;
  }
}

@media (max-width: 580px) {
  .bannerContainer {
    .banner {
      .content {
        padding-top: 60px;
        .benefit {
          font-size: 33px;
        }
      }
    }
  }
}

.bannerContainer.bannerDownload {
  .banner {
    height: 700px;
    .downloadBannerContent {
      position: absolute;
      bottom: 60px;
      max-width: 50%;

      @media (max-width: 1300px) {
        max-width: none;
      }

      @media (max-width: 992px) {
        text-align: center;
        left: 0;
        right: 0;
      }

      .downloadTypeTitle {
        font-size: 53px;
        font-family: $font-bold;
        display: inline;
        text-shadow: -2px 2px 10px rgba(0, 0, 0, 0.2);

        @media (max-width: 1500px) {
          font-size: 48px;
        }

        @media (max-width: 992px) {
          text-align: center;
          font-size: 34px;
        }
      }

      img {
        margin-left: 20px;
        margin-bottom: 22px;
        width: 65px;
        height: 65px;

        @media (max-width: 992px) {
          margin-left: 15px;
          margin-bottom: 10px;
          width: 50px;
          height: 50px;
        }
      }
    }

    .blackCover {
      height: 700px;
    }
  }
}
