@import '../../../../../../../../styles/themes/themes.scss';
@import '../../../../../../../../styles/fonts';

.questionsArea {
  .elementRadio {
    @include themify('color', 'subtitle');
    font-size: 14px;
    .labelTitle {
      display: flex;
      margin-bottom: 15px;
      font-size: 16px;
      font-family: $font-regular;
      @include themify('color', 'link');
    }
  }
  .none {
    display: none;
  }
  .option {
  }
}
