@import '../../../styles/themes/themes.scss';
@import '../../../styles/media_queries';

.certificatesAndClaims {
  background-color: transparent;

  @media (min-width: 990px) {
    .certificates {
      padding-right: 1.5%;
      padding-left: 15px;
    }
    .certificates + .certificates {
      padding-left: 1.5%;
      padding-right: 15px;
    }
  }
  @media(min-width: $width-extra-large) {
     max-width: 1920px;
     margin-left: auto;
     margin-right: auto;
   }
}

.titleContainer{
  width: 100%;
  @include themify('color', 'primary');
  font-size: 3.3rem;
  margin: 30px 0px;
  @media screen and (max-width: $width-large){
    margin: 40px 0px;
    margin-top: 80px;
  }
}

.titleCard {
  font-size: 3.3rem;
  @include themify('color', 'primary');
  margin: 30px 0px;
  @media screen and (min-width: $width-large) {
    font-size: 33px;
  }
}
