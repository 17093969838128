@import '../../../../../../../../styles/colors';
@import '../../../../../../../../styles/themes/themes.scss';
@import '../../../../../../../../styles/fonts';

.expenseArea {
  margin-bottom: 0.7rem;
  h4 {
    font-size: 16px;
    font-family: $font-regular;
    margin: 20px 0 14px;
    @include themify('color', 'primary');
  }
  p {
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 24px;
    @include themify('color', 'subtitle');
  }
  .expenseItem {
    .footer {
      button {
        margin-left: -8px;
      }
    }
  }

  .add {
    display: flex;
    justify-content: flex-end;
    button {
      margin-right: -8px;
    }
  }

  .totalArea {
    margin-top: 16px;
  }
}
