@import '../../styles/style.scss';

.white {
  @include themify('color', 'text_and_background');
  font-size: 49px;
  text-align: center;
  margin-bottom: 14px;
  line-height: unset;
}

.blue {
  @include themify('color', 'primary');
  text-align: center;
  margin-bottom: 14px;
  line-height: unset;
}

.absoluteCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.textLoading {
  font-size: 3rem;
}
h2 {
  span {
    font-size: 2.5rem;
  }
}
