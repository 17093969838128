@import '../../../../../../../styles/themes/themes.scss';
@import '../../../../../../../styles/fonts';

.container {
  margin-bottom: 40px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .hideInput {
      opacity: 0;
      position: absolute;
      top: 0px;
      left: 0px;
      display: none;
    }

    h3 {
      margin-bottom: 0;
      font-size: 16px;
      @include themify('color', 'text-paragraph_subtle');
      font-family: $font-regular;
    }
  }
  .emptyArea {
    text-align: center;

    .emptyText {
      font-size: 12px;
      @include themify('color', 'text-paragraph_subtle');
    }
  }
}
