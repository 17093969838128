@import '../../styles/themes/themes.scss';
@import '../../styles/fonts';
@import '../../styles/media_queries';
@import '../../styles/colors';

.notificationContainer {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 25px;

  position: relative;

  @media (max-width: $width-medium) {
    position: static;
  }

  .boxIcon {
    position: relative;

    svg {
      width: 22px;
      height: 24px;
    }

    .number {
      display: flex;
      justify-content: center;
      align-items: center;

      @include themify('background', 'primary-primary');
      @include themify('color', 'text-inverse_paragraph');
      width: 18px;
      height: 18px;
      border-radius: 50%;

      font-family: $font-regular;
      font-size: 12px;

      position: absolute;
      bottom: 0px;
      right: -10px;
    }
  }
  .containerList {
    @include themify('background', 'surface-surface');
    @include themify('border-color', 'const_gray');
    transition: all linear 0.2s;
    top: 60px;
    z-index: 10;
    right: 0;
    width: 400px;
    position: absolute;
    border: 1px solid;
    border-top: none;

    @media screen and (max-width: $width-medium) {
      width: 100% !important;
    }

    .menuNotifications {
      list-style: none;
      min-height: 200px;
      max-height: calc(100vh - 250px);
      overflow-y: auto;
      margin-bottom: 0;
      width: 100% !important;

      @include themify('color', 'primary-primary', ' a');
      a {
        font-weight: bold;
      }

      @media (max-width: $width-medium) {
        width: 300px;
        top: 79px;
        right: 10px;
      }

      li {
        padding: 10px;
        border-bottom: 1px solid;
        width: 100%;
        @include themify('border-color', 'const_gray');

        &.new {
          border-left: 4px solid;
          @include themify('border-left-color', 'warning-graphics')
        }

        &:last-child {
          border-bottom: none;
        }

        p {
          margin-bottom: 0;
          @include themify('color', 'primary-primary');
        }

        span {
          max-width: 100%;
          font-size: 12px;
          padding: 2px;
          color: #6b6b6b;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
    }

    .viewAll {
      display: flex;
      justify-content: center;
      @include themify('border-color', 'const_gray');
      border-top: 1px solid;
      width: 100%;

      a {
        width: 100%;
      }
    }
  }
}
