@import '../../../../../../../../styles/colors';
@import '../../../../../../../../styles/fonts';
@import '../../../../../../../../styles/themes/themes.scss';

.container {
  display: flex;
  flex-direction: column;
  @include themify('color', 'text-paragraph_subtle');
  font-size: 16px;

  p {
    line-height: 21px;
    margin-bottom: 22px;
  }
}
