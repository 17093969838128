@import '../../../styles/colors';
@import '../../../styles/fonts';
@import '../../../styles/themes/themes.scss';

.navigationContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;

  .navigateItem {
    a {
      @include themify('color', 'primary-primary');
      font-family: $font-regular;
      font-weight: 600;
    }
  }
}

.borderBottom {
  padding-bottom: 30px;
  border-bottom: 1px solid;
  @include themify('border-color', 'secondary-secondary');
}
