@import '../../../../../styles/style.scss';

.appointmentInfo {
  margin-top: 30px;
  .info {
    border-top: 1px solid;
    border-bottom: 1px solid;
    @include themify('border-color', 'tertiary');
    margin: 3% 0 3% 0;
    padding-top: 25px;
    p {
      @include themify('color', 'subtitle');
      font-size: 1.6rem;
      font-weight: 300;
      line-height: 1.5rem;
      margin-bottom: 2%;
      text-align: center;
      text-rendering: optimizeLegibility;
      width: 100%;
      strong {
        font-weight: bold;
      }
      &.date {
        @include themify('color', 'primary');
        font-size: 20px;
        margin-bottom: 2%;
        text-align: center;
        font-weight: bold;
      }
    }
    .contactInfo {
      border: 0px;
      @include themify('color', 'subtitle');
      text-align: center;
      width: 40%;
      h5 {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 0;
      }
      p {
        @include themify('color', 'subtitle');
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
}

@media (max-width: 990px) {
  .appointmentInfo {
    .info {
      .p {
        font-size: 1.1rem;
      }
      .contactInfo {
        width: 85%;
        .h5 {
        }

        .p {
          @include themify('color', 'text_active_element');
        }
      }
    }
  }
}
