@import '../../styles/style.scss';
.boxListCards {
  width: 100%;
  margin-left: 3%;
  @media screen and (max-width: 992px) {
    width: auto;
  }
  align-self: center;
}
.openListCards {
  position: relative;
  span {
    @media screen and (max-width: 1100px) {
      font-size: 1.5rem;
    }
  }
  img {
    @media screen and (max-width: 400px) {
      width: 4.2rem;
      height: 2.8rem;
      margin: 0px 0.5rem;
    }
    margin: 0px 1.2rem;
    width: 5.3rem;
    height: 3.2rem;
  }
}

.listCards {
  transition: all linear 0.2s;
  width: 45rem;
  border-radius: 5px;
  @include themify('background', 'text_and_background');
  position: absolute;
  left: -80px;
  right: 0px;
  top: 55px;
  z-index: 10;
  box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.1);

  @media (forced-colors: active) {
    border: 1px solid;
  }

  @media screen and (min-width: 320px) and (max-width: 450px) {
    width: 100vw;
    left: -90px;
    top: 55px;
    border-radius: 0px;
  }
  @media screen and (min-width: 450px) and (max-width: 600px) {
    width: 400px;
    left: -80px;
    right: 0px;
  }

  ul {
    list-style: none;
    max-height: 400px;
    overflow-x: auto;
    margin-right: 6px;
    padding-top: 2rem;
  }
}

.icon {
  margin-left: 1rem;
  font-size: 1.5rem;
  @include themify('color', 'secondary');
}

.buttonCards {
  background: transparent;
  border: none;
  @include themify('color', 'primary');
  font-size: 1.8rem;
  text-align: left;
  display: flex;
  align-items: center;

  @media (forced-colors: active) {
    border: 1px solid;
  }

  span {
    white-space: nowrap;
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  @media screen and (min-width: 300px) and (max-width: 400px) {
    span {
      max-width: 30px;
    }
  }
  @media screen and (min-width: 400px) and (max-width: 550px) {
    span {
      max-width: 100px;
    }
  }

  @media screen and (min-width: 550px) and (max-width: 650px) {
    span {
      max-width: 250px;
    }
  }

  @media screen and (min-width: 650px) and (max-width: 992px) {
    span {
      max-width: 350px;
    }
  }

  @media screen and (min-width: 992px) and (max-width: 1280px) {
    span {
      max-width: 80px;
    }
  }

  @media screen and (min-width: 1280px) {
    span {
      max-width: 60%;
    }
  }

  @media screen and (min-width: 1400px) {
    span {
      max-width: 100%;
    }
  }
}

.hideMenu {
  display: none;
}

.showMenu {
  display: block;
}
