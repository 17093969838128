@import '../../styles/_colors.scss';
@import '../../styles/_fonts.scss';
@import '../../styles/themes/themes.scss';

.menuMobileList {
  width: 100vw;
  @include themify('background', 'text_and_background');

  ul {
    list-style: none;

    li {
      padding: 15px 20px;
      border-bottom: 1px solid;
      @include themify('border-color', 'text_and_background');
      font-size: 2rem;
      @include themify('color', 'link');
      justify-content: flex-start;
      align-items: center;
      display: flex;

      a {
        @include themify('color', 'link');
        width: 100%;
      }

      a.active {
        font-weight: 500;
        font-family: $font-regular;
        text-decoration: none;
      }

      .icon {
        height: 40px;
        margin-right: 20px;
      }
    }
  }
}

.submenu {
  padding-top: 10px;
}

