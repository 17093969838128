@import '../../styles/fonts';

.container {
  height: 600px;
  background-position: center;
  background-size: cover;

  .logoArea {
    padding-top: 60px;
    padding-right: 90px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    .logo {
      width: 100px;
      height: 100px;
    }
  }

  .content {
    padding: 90px 90px 20px;
    @media (min-width: 1200px){
      max-width: 1760px;
      margin: 0 auto
    }

    .title {
      color: white;
      font-family: $font-bold;
      font-size: 50px;
      margin-bottom: 30px;
      max-width: 60%;

      text-shadow: -2px 2px 10px rgba(0, 0, 0, 0.2);
    }

    @media (max-width: 520px) {
      button {
        width: 100%;
      }
    }
  }
}

@media (max-width: 1200px) {
  .container {
    .content {
      .title {
        font-size: 40px;
      }
    }
  }
}

@media (max-width: 992px) {
  .container {
    .content {
      padding: 60px 20px 20px;
      .title {
        font-size: 36px;
      }
    }
  }
}

@media (max-width: 720px) {
  .container {
    .logoArea {
      justify-content: center;
      padding-right: 0;
    }

    .content {
      .title {
        text-align: center;
        font-size: 30px;
        max-width: none;
      }
    }

    .wrapButton {
      margin: 0 auto;
    }
  }
}
