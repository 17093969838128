@import '../../../../styles/colors';
@import '../../../../styles/fonts';

.container {
  background: linear-gradient(
    to bottom,
    white 0%,
    white 32%,
    $const_axa_blue 32%,
    $const_axa_blue 100%
  );
  margin-top: 50px;
  display: flex;

  @media(forced-colors: active){
    border: 1px solid;
  }

  .textArea {
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 150px 2% 0;

    h2 {
      color: white;
      font-family: $font-semibold;
      font-size: 40px;
      margin-bottom: 30px;
      text-align: center;
    }

    .wrapButton {
      width: 280px;
      button {
        padding: 0 10px;
      }

      @media (max-width: 520px) {
        max-width: none;
        width: 100%;
      }
    }
  }

  .imageArea {
    flex: 1;
    img {
      width: 450px;
      height: auto;
      transform: scaleX(-1);
    }
  }
}

@media (max-width: 1200px) {
  .container {
    .textArea {
      h2 {
        font-size: 32px;
      }
    }
  }
}

@media (max-width: 992px) {
  .container {
    flex-direction: column-reverse;
    align-items: center;
    background: linear-gradient(
    to bottom,
    white 0%,
    white 25%,
    $const_axa_blue 25%,
    $const_axa_blue 100%
  );

    .textArea {
      width: 100%;
      padding: 60px 10px 0;

      .wrapButton {
        margin-bottom: 20px;
      }
    }

    .imageArea {
      img {
        width: 380px;
      }
    }
  }
}

@media (max-width: 540px) {
  .container {
    .imageArea {
      img {
        width: 300px;
      }
    }
  }
}
