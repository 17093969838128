@import '../../../../styles/colors';
@import '../../../../styles/fonts';
@import '../../../../styles/media_queries';
@import '../../../../styles/themes/themes.scss';

.container {
  .titleArea {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    h2 {
      margin: 0;
      font-size: 25px;
      @include themify('color', 'primary');
    }
  }

  .reimbursementItem.selected {
    @include themify('background', 'const_light_gray');
  }

  .content {
    .reimbursementItem {
      border-bottom: 1px solid;
      @include themify('border-color', 'subtitle');
      padding: 24px;

      &:last-child {
        border-bottom: none;
        margin-bottom: 0;
      }

      .footer {
        margin-top: 8px;
        display: flex;
        width: 100%;
        gap: 8px;
        margin-left: -1rem;

        @media (max-width: $width-medium) {
          flex-direction: column;
        }
      }
    }
    .emptyMessage {
      @include themify('color', 'subtitle');
      font-size: 16px;
    }
  }
}

@media (max-width: 768px) {
  .container {
    .titleArea {
      h2 {
        font-size: 20px;
      }
    }
  }
}
