@import '../../styles/style.scss';

.boxModalWithImage {
  p {
    font-size: 1.6rem;
    line-height: 2.4rem;
    @include themify('color', 'subtitle');
    margin-top: 4rem;
    margin-bottom: 2rem;
  }
  .boxButton {
    width: 100%;
    height: 53px;
    @media screen and (min-width: 992px) {
      width: 180px;
    }
  }

  .boxGrey,
  .boxWhite {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .boxImageCard {
    width: 310px;
    img {
      max-width: 100%;
    }
  }

  .width50 {
    width: 50%;
  }
  .width100 {
    width: 100%;
  }

  .boxWhite {
    margin-bottom: 30px;
  }
  .children {
    width: 95%;
    display: flex;
    justify-content: center;
  }
  @media screen and (min-width: 500px) and (max-width: 992px) {
    padding: 0px 20px;

    .boxGrey {
      img {
        max-width: 400px;
      }
    }
  }

  @media screen and (max-width: 500px) {
    .boxGrey {
      img {
        max-width: 300px;
      }
    }
    .boxWhite {
      padding: 0px 20px;
    }
  }

  @media screen and (min-width: 992px) {
    .boxGrey,
    .boxWhite {
      flex-direction: column;
      align-items: start;
    }
    .boxGrey {
      margin-left: 4rem;
      img {
        max-width: 400px;
      }
    }
    .boxWhite {
      margin-bottom: 0px;
    }
    .children {
      width: 100%;
      display: flex;
      justify-content: flex-start;
    }
  }
}

.title {
  width: 100%;
    display: flex;
  @media screen and (max-width: 992px) {
    justify-content: center;
  }
  h2 {
    font-size: 3.3rem;
    @media screen and (max-width: 400px) {
      font-size: 2.8rem;
    }
    @include themify('color', 'primary');
    line-height: 4.4rem;
    margin-bottom: 0.7rem;
    max-width: 490px;
  }
}

.subtitle {
  h4 {
    display: inline;
  }
}

.icon {
  width: 40px;
  margin-bottom: 30px;
  img {
    width: 100%;
    max-width: 40px;
  }
}

.colorRed {
  color: red !important;
}

@media (max-width: 1199.98px) {
  .section {
      padding: 2rem;
  }
}
