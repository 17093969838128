@import '../../../../styles/style.scss';

.isBenefit {
  margin-bottom: 40px;
}

.benefitsCategories {
  ul {
    list-style: none;
    display: flex;
    align-items: center;
    font-weight: 300;
    white-space: nowrap;
    margin: 0;
    position: relative;
    left: 30px;

    @media(max-width: 920px){
      position: inherit;
    }

    li {
      @include themify('color', 'subtitle');
      cursor: pointer;
      float: left;
      font-size: 16px;
      margin-right: 3rem;
      text-decoration: none;
      white-space: nowrap;
      border-bottom: 1px solid transparent;

      @media (max-width: 992px) {
        margin-left: 0px;
        margin-right: 5%;
      }

      &.active {
        font-weight: 600;
        font-family: $font-regular;
        @include themify('border-bottom-color', 'secondary-secondary');
      }
      &:hover {
        cursor: pointer;
        @include themify('border-bottom-color', 'secondary-secondary');
      }
    }
  }
}

@media (max-width: 992px) {
  .benefitsCategories {
    width: 100%;
    overflow-x: auto;
    margin-bottom: 30px;
    margin-top: 20px;
    ul {
      li {
        font-size: 16px;
      }
    }
  }
}
