@import '../../../styles/colors';
@import '../../../styles/fonts';
@import '../../../styles/media_queries';
@import '../../../styles/themes/themes.scss';

.container {
  .contentAllClaims {
    .status {
      .header {
        margin-bottom: 2%;
        p {
          @include themify('color', 'subtitle');
          font-size: 18px;
        }
      }

      .contentStatus {
        ul {
          margin-bottom: 0px;
        }
      }

      .more {
        text-align: center;
        a {
          @include themify('color', 'secondary');
          font-size: 16px;
          text-decoration: none;
        }
      }
    }
  }

  .contentOffers {
    margin: 0 1%;
  }

  .contentUpgradeMe {
    padding: 0 6%;
    margin-bottom: 100px;
  }

  @media (max-width: 990px) {
    .contentClaims {
      padding: 0 2rem;
    }
    .contentAllClaims {
      padding: 0 2rem;
    }
  }
}

.showMore {
  width: 100%;
  margin: 5rem 0;
  margin-top: calc(3.2rem - 1.5%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.headerContainer {
  display: flex;
  .titleSection {
    flex-direction: column;
    display: flex;
    flex: 1 1;
    width: 100%;
  }

  @media (max-width: 620px) {
    flex-direction: column;
  }
}
