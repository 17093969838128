@import '../../../../../../../styles/colors';
@import '../../../../../../../styles/fonts';
@import '../../../../../../../styles/themes/themes.scss';

.incidentArea {
  .none {
    display: none;
  }

  .elementRadio {
    @include themify('color', 'subtitle');
    font-size: 14px;
    .labelTitle {
      display: flex;
      margin-bottom: 15px;
      font-size: 16px;
      font-family: $font-regular;
      @include themify('color', 'link');
    }
  }
  .incidentPeriodArea {
    margin-top: 0.7rem;
    h3 {
      color: #333;
      font-size: 1.175rem;
      font-weight: 300;
      margin-top: 0;
      margin-bottom: 0;
    }
    .marginBottom {
      margin-bottom: 25px;
    }
  }
}

.radioButtonError {
  .error {
    border-bottom-color: $error-red-alert;
    &:focus {
      border-bottom-color: $error-red-alert;
    }
  }
  .errorLabel {
    font-size: 0.75em;
    color: $error-red-alert;
    font-family: $font-regular;
  }
}
