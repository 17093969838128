@import '../../styles/themes/themes.scss';
@import '../../styles/fonts';
@import '../../styles/media_queries';

.notificationContainer {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 25px;
  @media (max-width: 992px) {
    margin-left: 25px;
  }

  position: relative;

  @media (max-width: $width-medium) {
    position: static;
  }

  .boxIcon {
    position: relative;

    img {
      width: 22px;
      height: 24px;
    }

    .number {
      display: flex;
      justify-content: center;
      align-items: center;

      @include themify('background', 'secondary');
      @include themify('color', 'primary');
      width: 18px;
      height: 18px;
      border-radius: 50%;

      font-family: $font-regular;
      font-size: 12px;

      position: absolute;
      bottom: 0px;
      right: -10px;
    }
  }

  .menuNotifications {
    transition: all linear 0.2s;
    @include themify('background', 'text_and_background');
    position: absolute;
    top: 60px;
    z-index: 10;
    right: 0;
    list-style: none;
    border: 1px solid;
    border-top: none;
    @include themify('border-color', 'const_gray');

    @media (max-width: $width-medium) {
      top: 79px;
      right: 10px;
    }

    li {
      white-space: nowrap;
      padding: 10px;
      border-bottom: 1px solid;
      @include themify('border-color', 'const_gray');

      @media (max-width: $width-medium) {
        white-space: normal;
        width: 300px;
      }

      p {
        margin-bottom: 0;
        @include themify('color', 'primary');
      }
      p.marginBottom {
        margin-bottom: 10px;
      }
      a {
        @include themify('color', 'secondary');
        text-decoration: none;
        display: block;
        width: 100%;
        height: 100%;
        &:hover {
          text-decoration: underline;
        }

        img {
          margin-left: 10px;
        }
        svg {
          margin-left: 10px;
        }
      }
      &:last-child {
        border-bottom: none;
      }
    }
  }
}
