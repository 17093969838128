@import '../../../../../styles/style.scss';

.container {
  @include themify('color', 'primary-primary');

  .titleContainer {
    h2 {
      font-size: 26px;
      @include themify('color', 'primary-primary');
    }
  }
}

.loadingContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 15rem 0;
}

.footer {
  width: 100%;
  padding-top: 1.6rem;
  margin-top: 1.6rem;
}
