@import '../../styles/style.scss';

.container {
  width: 450px;
  min-height: 510px;
  padding: 40px;

  @include themify('background', 'primary');
  @include themify('color', 'text_and_background');
  font-family: $font-regular;

  position: sticky;
  top: 20%;
  float: right;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (forced-colors: active) {
    border: 1px solid;
  }

  .group {
    display: flex;
    flex-direction: column;
    margin: 10px 0;

    .title {
      font-size: 30px;
      text-align: center;
      font-family: $font-bold;
    }

    h3 {
      font-size: 32px;
    }

    .description {
      font-size: 18px;
      text-align: justify;
    }

    .withoutMargin {
      margin: 0;
    }

    .link + .link {
      margin-top: 10px;
      a {
        margin-left: 0;
      }
    }

    a {
      @include themify('color', 'tertiary');
      font-weight: 600;
    }

    .buttonLink {
      width: fit-content;
      border: none;
      font-size: 18px;
      font-family: $font-regular;
      font-weight: bold;
      @include themify('color', 'tertiary');
      text-decoration: underline;

      &:hover {
        background: transparent;
        @include themify('color', 'tertiary');
      }
      &:focus {
        background: transparent;
        outline: none;
      }
    }

    .iconMargin {
      svg {
        margin-right: 10px;
      }
    }

    .linksArea {
      display: flex;
      flex-direction: column;
      margin-bottom: 2px;

      button {
        margin-top: 20px;
      }
    }
  }
  .centerGroup {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
  }
}

@media (max-width: 1600px) {
  .container {
    width: 100%;
    min-width: 200px;
    min-height: 570px;
    height: auto;
    padding: 30px;

    .group {
      h3 {
        font-size: 26px;
      }
      p {
        font-size: 14px;
      }
    }
  }
}

@media (max-width: 1300px) {
  .container {
    margin-top: 100px;
    width: 100%;
    height: 570px;
    padding: 30px;

    position: static;
    top: 0;
    float: none;

    .group {
      h3 {
        font-size: 32px;
      }
      p {
        font-size: 16px;
      }
    }
  }
}

@media (max-width: 500px) {
  .container {
    height: fit-content;

    .group {
      margin: 10px 0;
      h3 {
        font-size: 25px;
      }
    }
  }
}

.marginTop {
  margin-top: 40px;
}

.footerText {
  font-family: $font-light;
  font-size: 14px;
  margin: 10px 0;

  @media (max-width: 1600px) {
    font-size: 12px;
  }

  @media (max-width: 1300px) {
    font-size: 14px;
  }
}

.QRcode {
  width: 250px;
  height: 250px;
  @media (max-width: 1600px) {
    max-width: none;
    width: 80%;
  }
  @media (max-width: 1300px) {
    width: auto;
  }
  @media (max-width: 500px) {
    width: 80%;
  }
}

.center {
  text-align: center !important;
  align-items: center;
}
.imageLink{
  text-align: center;
  .link {
    width: 130px;
    margin: 0 10px;
    @media (max-width: 500px) {
      width: 40%;
      min-width: 100px;
    }
  }
}

.groupInline {
  flex-direction: row !important;
  justify-content: space-around;
}

.marginTop {
  margin-top: 20px;
  @include themify('color', 'tertiary');
}

.loginButton {
  width: 100%;
  margin-top: 16px;
}

.linkButton {
  width: 100%;
  font-size: 18px;
  padding: 8px;
}