.container {
  max-height: 300px;
  overflow: auto;
  max-width: 100%;
}

.itemContainer {
  display: inline-block;

  width: 25%;
  padding: 1px;
  @media screen and (max-width: 576px) {
    width: 100%;
  }

  button {
    min-width: fit-content;
    padding: 12px 2px;
    width: 100%;
    font-size: 1.5rem;
  }
}
