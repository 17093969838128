@import '../../../../styles/style.scss';

.wellcome {
  @include themify('color', 'primary-primary');
  line-height: 1.4;
  margin-top: 2.75rem;
  font-size: 1.375rem;
  font-weight: 700;
  margin-top: 0;
}
.termsArea {
  margin-bottom: 30px;
  h2 {
    font-size: 26px;
    @include themify('color', 'primary-primary');
  }
  .pdf {
    display: flex;
    margin-top: 30px;
    margin-left: 15px;
    
    .pdfIcon {
      margin-right: 30px;
    }

    .pdfInfo {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      a {
        @include themify('color', 'text-paragraph_subtle');
        font-weight: 600;
      }
    }
  }
}

.diseasesList {
  padding-left: 30px;
  font-weight: 300;
  margin-bottom: 50px;
  li {
    min-height: unset;
  }
}

.textFinal {
  width: 100%;
  text-align: center;
  @include themify('background', 'tertiary');
  padding: 5px;
}

.certificatesArea {
  h4 {
    color: #333;
    font-size: 1.5rem;
    font-weight: 300;
    margin-bottom: 1.25rem;
  }
  .selectCertificateText {
    border-bottom: 1px solid;
    @include themify('color-border-bottom', 'tertiary');
    color: #333;
    font-size: 1.175rem;
    font-weight: 300;
    margin-bottom: 1%;
    padding-bottom: 1%;
    text-rendering: optimizeLegibility;
  }
}

.wrappButton {
  display: flex;
  justify-content: flex-end;

  button {
    width: 180px;
  }
}
