@import '../../styles/style.scss';

.headingTitle {
  @include themify('color', 'text_and_background');
  margin-bottom: 30px;

  .box {
    display: flex;
    align-items: center;
    margin-bottom: 40px;

    .link {
      cursor: pointer;
      .arrowIcon {
        margin-top: 3px;
        margin-right: 20px;
        display: inline-block;
      }
    }
    h4 {
      @include themify('color', 'text_and_background');
      font-size: 25px;
      margin: 0;
    }
  }

  h1 {
    font-size: 45px;
    margin-bottom: 12px;
  }
}

@media (max-width: 992px) {
  .headingTitle {
    .box {
      .link {
        position: static;
      }
      h4 {
        font-size: 20px;
      }
    }
    h1 {
      font-size: 33px;
    }
  }
}
