@import '../../../styles/themes/themes.scss';

.totalReimbursedDetails{
  @include themify('background', 'text_and_background');
  padding: 0px 21px 26px !important;
  position: relative;
  display: flex;
  flex-direction: column;

  .containerItensDetails{
    padding: 0px 5%;

    .itemDetails{
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
