@import '../../../../../styles/style.scss';

.footer {
  display: flex;
  align-items: center;
  width: 100%;
  padding-top: 24px;

  @media (max-width: 1200px) {
    padding: 0 20px;
  }

  button {
    max-width: 220px;
    padding: 0 8px;
    @media (max-width: $width-medium) {
      max-width: none;
    }
  }
}
