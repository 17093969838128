@import '../../styles/style.scss';

.container{
  @include themify('color','primary-primary');
  
  .contentTitle{
    display: flex;
    height: 54px;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid;
    @include themify('border-color', 'secondary-secondary');
    padding: 4rem 0;
    padding-top: 10rem;
    h2 {
      @include themify('color','primary-primary');
      font-weight: normal;
      font-size: 25px;
    }
  }
  
  @media (min-width: 1200px){
    max-width: 1760px;
    margin-left: auto;
    margin-right: auto;
  }
}
