@import '../../../../styles/style.scss';

.certificateFormFill {
  .submit {
    border: 2px solid #FCC015;
    background-color: #FCC015;
    color: $white;
    font-weight: 500;
    margin-left: 0.7%;
    padding: 0.75rem 3rem;
    &.disabled {
      background-color: #1434CB;
      border: 2px solid #1434CB;
      opacity: 0.5;
    }
    @media screen and (max-width: 768px) {
      margin-left: 0px;
      width: 100%;
      display: block;
      margin-top: 1rem;
    }
  }
  .back {
    border: 2px solid #1434CB;
    background-color: $white;
    color: #1434CB;
    font-weight: 500;
    text-align: center;
    padding: 0.75rem 3rem;
    text-decoration: none;
    @media screen and (max-width: 768px) {
      margin-left: 0px;
      width: 100%;
      display: block;
    }
  }
  input,
  select {
    &:disabled {
      background: transparent;
      color: #999 !important;
      cursor: not-allowed;
    }
  }
}

.csatSurvey {
  width: 100%;
  height: 250px;
  border-style: none;
  overflow: hidden;

  @media screen and (max-width: $width-small) {
    height: 270px;
  }

  @media screen and (max-width: 374px) {
    overflow-y: scroll;
    padding-bottom: 0;
  }
}