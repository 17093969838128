@import '../../styles/colors';
@import '../../styles/themes/themes.scss';

.container {
  width: 100%;
  display: flex;

  .info {
    width: 45%;

    .boxInfo {
      max-width: 412px;
      height: 100%;

      display: flex;
      flex-direction: column;
      justify-content: center;

      h1 {
        @include themify('color', 'primary');
        font-size: 45px;
        margin-bottom: 20px;
      }

      p {
        font-size: 16px;
        line-height: 24px;
        @include themify('color', 'subtitle');
        margin-bottom: 50px;
      }

      .footer {
        margin-bottom: 50px;
      }
    }
  }

  @media (max-width: 1090px) {
    flex-direction: column;
    align-items: center;

    .info {
      width: 100%;

      .boxInfo {
        max-width: none;
      }
    }

    img {
      width: 400px;
    }
  }

  @media (max-width: 520px) {
    .info {
      .boxInfo {
        h1 {
          font-size: 35px;
          margin-bottom: 10px;
        }

        p {
          margin-bottom: 30px;
        }
      }
    }

    img {
      width: 80%;
    }
  }
}
