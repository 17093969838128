@import '../../../styles/media_queries';
@import '../../../styles/fonts';
@import '../../../styles/colors';
@import '../../../styles/themes/themes.scss';

.container {
  position: relative;
  margin-bottom: 200px;

  .banner {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 400px;
    @include themify('background', 'primary');
  }
  .title {
    position: absolute;
    top: 100px;
    width: 100%;
    font-size: 50px;
    font-weight: 300;
    padding: 0 9rem;
    @include themify('color', 'text_and_background');
  }
  .subtitle {
    position: absolute;
    top: 180px;
    width: 100%;
    font-size: 18px;
    font-family: $font-regular;
    padding: 0 9rem;
    @include themify('color', 'text_and_background');
  }
  .contentSupport {
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: absolute;
    top: 250px;
    width: 100%;
    height: 270px;
    padding: 0 6rem;
  }
}

@media (max-width: 992px) {
  .container {
    margin-bottom: 200px;

    .banner {
      height: 300px;
    }

    .title {
      top: 70px;
      padding: 0 2rem;
      font-size: 40px;
    }

    .subtitle {
      top: 130px;
      font-size: 16px;
      padding: 0 2rem;
      line-height: 22px;
    }
  }

  .contentSupport {
    flex-direction: column !important;
    margin-bottom: 20px;
    padding: 0 !important;
    top: 230px !important;
    padding: 0 2rem !important;
  }
}

@media (max-width: 430px) {
  .container {
    .title {
      font-size: 33px;
    }
  }
}


@media (max-width: 375px) {
  .contentSupport{
    height: auto !important;
  }
}
