@import '../../../../styles/style.scss';

.itemContainer {
  flex: 1 1;
  margin: 0 5px;
  z-index: 2;
  .item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .label {
      display: flex;
      align-items: center;
      @include themify('color', 'primary-primary');

      span {
        position: relative;
        color: white;
        font-size: 45px;
        margin-right: 20px;
      }

      .hasName {
        @include themify('background-color', 'secondary-inverse', ':after');
        &.theme-axa:after {
          background-color: #D24723;
        }
        
        &:after {
          content: '';
          position: absolute;
          width: 1px;
          height: 50%;
          top: 25%;
          right: -10px;
        }
      }

      .stepName {
        font-size: 14px;
        margin-bottom: 0;
        font-weight: bold;
        color: white;
      }
    }

    img {
      margin: 0 0 10px 1%;
      height: 20px;
    }
  }
}

.selected {
  font-family: $font-semibold !important;

  @include themify('color', 'secondary-secondary');
  @media (forced-colors: active) {
    text-decoration: underline;
  }
}
span.selected {
  font-family: $font-regular !important;
}

.regular {
  font-family: $font-regular !important;
}

@media (max-width: 992px) {
  .itemContainer {
    flex: 0;
    .item {
      .label {
        span {
          font-size: 33px;
          margin-right: 0;
        }
        .hasName:after,
        .hasName.theme-master:after,
        .hasName.theme-afluent:after {
          background: transparent;
        }
        .stepName {
          display: none;
        }
      }

      img {
        margin-left: 0;
      }
    }
  }
}

@media (max-width: 575px) {
  .itemContainer {
    .item {
      .label {
        display: none;
      }
    }
  }
}
