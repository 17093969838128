@import '../../../../../../../../styles/colors';
@import '../../../../../../../../styles/themes/themes.scss';
@import '../../../../../../../../styles/fonts';

.personalArea {
  .fontGray12 {
    @include themify('color', 'const_gray');
    font-size: 14px;
  }
  .none {
    display: none;
  }
  .option {
  }
}

.elementRadio {
  @include themify('color', 'subtitle');
  font-size: 14px;
  .labelTitle {
    display: flex;
    margin-bottom: 15px;
    font-size: 16px;
    font-family: $font-regular;
    @include themify('color', 'link');
  }
}
