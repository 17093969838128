@import '../../../styles/fonts';
@import '../../../styles/colors';
@import '../../../styles/media_queries';
@import '../../../styles/themes/themes.scss';

.exploreBenefits {
  @include themify('background', 'tertiary');
  margin: 5% 0 5% 0;

  .center {
    margin: 0 auto;
    width: 100%;

    .left {
      padding-top: 7%;
      padding-left: 10%;
      @media screen and (min-width: 992px) {
        padding-left: 9rem;
      }

      span {
        @include themify('color', 'subtitle');
        display: block;
        font-size: 16px;
        margin-bottom: 4%;
        font-weight: 600;
      }
      h2 {
        font-size: 43px;
        line-height: 52px;
        @include themify('color', 'primary');
      }
    }
    .right {
      .liveDesktop {
        margin: 0;
        padding: 0;
        display: flex;
        .linkContainer {
          overflow: hidden;
          a {
            &:hover,
            &:focus {
              span {
                @include themify('color', 'secondary-inverse');
                font-family: $font-regular;
              }

              .image {
                transition: transform 0.2s;
                transform: scale(1.1);
              }
            }

            .image {
              width: 100%;
              height: 100%;
              max-height: 400px;
            }
          }
        }
      }
      .liveMobile {
        display: none;
      }
      span {
        bottom: 8%;
        @include themify('color', 'text_and_background');
        font-size: 33px;
        left: 8%;
        line-height: 36px;
        position: absolute;
        z-index: 1;
      }

      .nopadding {
        padding: 0 !important;
        margin: 0 !important;
      }
    }
    @media (min-width: 1200px) {
      max-width: 1760px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  @media screen and (max-width: $width-large) {
    .center {
      .left {
        span {
          font-size: 14px;
        }
        h2 {
          font-size: 30px;
          line-height: 35px;
        }
      }
      .right {
        span {
          font-size: 23px;
          line-height: 26px;
        }
      }
    }
  }

  @media screen and (max-width: $width-medium) {
    .center {
      .left {
        span {
          display: none;
        }
        h2 {
          font-size: 26px;
          line-height: 33px;
        }
      }
      .right {
        span {
          font-size: 20px;
          line-height: 26px;
        }
      }
    }
  }

  @media screen and (max-width: $width-small) {
    .center {
      .left {
        padding-left: 5%;

        span {
          display: none;
        }
        h2 {
          font-size: 26px;
          line-height: 33px;
          padding-right: 10%;
          margin-bottom: 15%;
        }
      }
      .right {
        .liveDesktop {
          display: none;
        }
        .liveMobile {
          display: block;
          overflow-y: hidden;
          white-space: nowrap;

          .row {
            display: block;

            .scrollBox {
              display: inline-block;
              float: none;
            }
          }
        }
        span {
          font-size: 22px;
          line-height: 26px;
        }
      }
    }

    :global(.col-9) {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
}

.blackCover {
  position: absolute;
  top: 0;
  height: 100%;
  left: 0;
  width: 100%;
  background: linear-gradient(to top, #000 10%, transparent 30%, transparent);
  opacity: 0.9;
}
