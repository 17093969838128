@import '../../../../../../../styles/colors';
@import '../../../../../../../styles/fonts';
@import '../../../../../../../styles/themes/themes.scss';

.phoneArea {
  margin-bottom: 0.7rem;
}

.labelTitle {
  @include themify('color', 'subtitle');
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 15px;
}

.fontGray12 {
  @include themify('color', 'tertiary');
  font-size: 12px;
}

@media (max-width: 1199.98px) {
  .section {
      padding: 2rem;
  }
}
