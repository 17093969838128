@import '../../../../../../styles/colors';
@import '../../../../../../styles/fonts';

.controlArea {
  display: flex;

  button {
    margin-right: -8px;
  }
}
