@import '../../../../../styles/themes/themes.scss';
@import '../../../../../styles/media_queries';

.boxButtons {
  display: flex;
  flex-direction: column;

  .buttonItem {
    margin-top: 0.8rem;
    width: 100%;
  }
}
