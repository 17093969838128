@import '../../../../../../../../styles/colors';
@import '../../../../../../../../styles/themes/themes.scss';

.container {
  padding-bottom: 8px;

  @media (max-width: 1200px) {
    padding: 0 20px;
  }
}

.alertMessage {
  margin-top: 5px;
}

.containerOctForm {
  padding: 20px;
  border-radius: 10px;
  border: 1px dashed;
  @include themify('border-color', 'secondary');
}

.controlArea {
  display: flex;
  justify-content: flex-end;
  gap: 0.8rem;

  .btnOct:last-child {
    margin-right: -1rem;
  }
}

.marginContainer {
  margin-bottom: 32px;
}

.wrapperNote {
  margin-bottom: 14px;
}
