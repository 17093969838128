@import '../../styles/colors';

.input {
  width: 100%;
  margin: 45px auto 0 auto;
  @media screen and (min-width: 400px) {
    margin: 45px 0 0px;
  }
  position: relative;

  label {
    font-size: 1.3rem;
    top: -20px;
  }
  input {
    border: none !important;
    height: 68px !important;
    font-size: 20px !important;
    border-radius: 3px !important;
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1);
    padding: 5px 10px;
    text-align: center;
    margin-top: 20px !important;

    &:focus {
      background: transparent !important;
      box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1) !important;
    }
    &::placeholder {
      @media (max-width: 575px) {
        position: relative !important;
        bottom: initial !important;
      }
    }
  }
}

.submitButton {
  height: 53px;
  width: 100%;
}

.checkbox {
  margin: 25px 0px;
  font-size: 1.2rem;

  @media screen and (min-width: 400px) {
    font-size: 1.6rem;
  }
  font-size: 1.2rem;
  input {
    margin-right: 10px;
  }
}

.formContent {
  width: 100%;
}

.container {
  display: flex;
  flex-direction: row;
}

.alertMessage {
  margin-top: 20px;
}

.disclaimer {
  font-size: 13px;
  display: block;
  margin-bottom: 25px;
}

@media (max-width: 1199.98px) {
  .section {
      padding: 2rem;
      justify-content: center;
      display: flex;
  }

  .container {
    flex-direction: column;
  }
}
