@import '../../../styles/themes/themes.scss';
@import '../../../styles/colors';
@import '../../../styles/fonts';

.container {
  padding-top: 120px;

  .content {
    padding: 0 9rem;

    .wrapListCards {
      margin-bottom: 60px;
    }

    .text {
      font-size: 16px;
      margin-bottom: 30px;
      @include themify('color', 'const_axa_blue');
      text-align: justify;
    }

    .mainText {
      font-weight: bold;
    }

    .axaExplanation {
      display: flex;

      .image {
        width: auto;
        height: 350px;
      }

      .textArea {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 85px;
        .subtitle {
          color: $const_axa_blue;
          font-family: $font-semibold;
          font-size: 30px;
          margin-bottom: 10px;
        }
        .description {
          line-height: 32px;
          color: #000;
          font-size: 20px;
          font-family: $font-regular;
        }
      }
    }
  }

  @media (min-width: 1200px){
    max-width: 1760px;
    margin: 0 auto
  }

  @media (max-width: 1450px) {
    .content {
      .axaExplanation {
        .image {
          height: 280px;
        }

        .textArea {
          padding: 0 0 0 60px;
          .subtitle {
            font-size: 26px;
          }
          .description {
            font-size: 16px;
          }
        }
      }
    }
  }

  @media (max-width: 992px) {
    padding-top: 80px;
    .content {
      padding: 0 2rem;
      .axaExplanation {
        flex-direction: column-reverse;
        .image {
          width: 60%;
          min-width: 280px;
          height: auto;
          margin: auto;
        }

        .textArea {
          padding: 0 0 40px 0;
          padding-left: 0;
          text-align: center;
        }
      }
    }
  }
}

.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
