@import '../../../styles/fonts';
@import '../../../styles/colors';
@import '../../../styles/media_queries';
@import '../../../styles/themes/themes.scss';

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 70px 0 40px;

  .title {
    font-size: 33px;
    font-weight: 300;
    @include themify('color', 'primary');

    @media screen and (max-width: 992px) {
      font-size: 28px;
    }

    @media screen and (max-width: 768px) {
      margin-top: 20px;
    }
  }

  .headerActions {
    display: flex;
    align-items: center;
    font-weight: 300;
    margin: 20px 0px;

    span {
      font-weight: 300;
      border-bottom: 1px solid transparent;
      cursor: pointer;
      margin-left: 3rem;
      &:hover {
        @include themify('border-bottom-color', 'secondary-secondary');
      }
    }

    .filterSelected {
      @include themify('color', 'subtitle');
      font-family: $font-regular;
      @include themify('border-bottom-color', 'secondary-secondary');
    }
  }
}

@media (max-width: 990px) {
  .headerContainer {
    flex-direction: column;
    align-items: flex-start;

    .headerActions {
      span {
        font-size: 16px;
        margin-left: 0;
        margin-right: 3rem;
      }
    }
  }
}
