@import '../../../../../styles/colors';
@import '../../../../../styles/themes/themes';

.loadingContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  flex: 1 1 auto;
  margin: 0 auto;
  margin-bottom: 10px;
  max-width: 1280px;
  height: 90vh;
  position: relative;



  .noDoctorNotice{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 3;
   background-color: #ffffff;
  }

  .title {
    width: 100%;
    @include themify('color', 'text_and_background');
    font-size: 50px;
    font-weight: 300;
    padding: 0 10rem;
    margin-bottom: 20px;
  }

  .videoCall {
     position: absolute;
    right: 0px;
    left: 0px;
    top: 0px;
    bottom: 0px;
    z-index: 1;
  }

  .boxButtons {
    display: none;
    opacity: 0;
    transition: opacity 3s ease-in-out;
  }


  .patientCameraContainer {
    position: absolute;
    right: 5px;
    top: 5px;
    z-index: 2;
    height: 300px;
    width: 300px;
    background: rgba( 0,0,0, 0.5);
    border-radius: 10%;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
    display: flex;
    justify-content: center;
    align-items: center;

    .videoCallPatient{
      border-radius: 10%;
      width: 100%;
      height: 100%;
      overflow: hidden;
    }

  }
}

.container:hover {
  .boxButtons {
    opacity: 1;
    position: absolute;
    padding: 0px 2rem;
    height: 60px;
    background: rgba( 255, 255, 255, 0.20 );
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    border-radius: 10px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
    z-index: 1;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    display: flex;
    justify-content: center;
    align-items: center;

    .button {
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100px;
      cursor: pointer;

      &:hover {
        background: rgba( 255, 255, 255, 0.20 );
      }
    }
  }

  .notShow {
    display: none;
  }

  @media screen and (max-width: 992px) {
    .patientCameraContainer {
      height: 150px;
      width: 150px;
    }

  }
}
