@import '../../../../styles/themes/themes.scss';
@import '../../../../styles/fonts';

.rowAbsolute {
  position: absolute;
  bottom: -28px;

  @media (max-width: 540px) {
    bottom: -30px;
  }

  .subtitle {
    margin-top: 15px;
    padding: 0 15px;
    font-size: 16px;
    font-family: $font-regular;
    @include themify('color', 'subtitle');

    @media (max-width: 1200px) {
      font-size: 14px;
    }
    @media (min-width: 992px) and (max-width: 1200px) {
      font-size: 12px;
    }
    @media (max-width: 540px) {
      font-size: 12px;
    }
  }
}

.planTitle{
  font-size: 20px;
  @include themify('color', 'subtitle');
  margin-bottom: 20px;
}
