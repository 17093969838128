@import '../../../../styles/colors';
@import '../../../../styles/fonts';

.container {
  margin-top: 120px;
  .textArea {
    text-align: center;
    h2 {
      color: $const_axa_blue;
      font-family: $font-semibold;
      font-size: 30px;
      margin-bottom: 10px;
    }
    p {
      line-height: 32px;
      color: #000;
      font-size: 20px;
      font-family: $font-regular;
    }
  }
  .iconArea {
    ul {
      list-style-type: none;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      li {
        flex-basis: 30%;
        padding: 2%;
        display: flex;
        justify-content: center;

        .content {
          display: flex;
          flex-direction: column;
          justify-content: center;
          background: #f5f7fc;

          padding: 32px 20px;
          height: 222px;
          width: 340px;

          @media(forced-colors: active){
            border: 1px solid;
          }

          img {
            width: auto;
            height: 60px;
          }
          h3 {
            text-align: center;
            font-size: 22px;
            color: $const_axa_blue;
            font-family: $font-semibold;
            margin-top: 10px;
          }
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .container {
    .textArea {
      h2 {
        font-size: 26px;
      }
      P {
        font-size: 18px;
      }
    }
    .iconArea {
      ul {
        li {
          .content {
            h3 {
              font-size: 18px;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .container {
    .iconArea {
      ul {
        li {
          .content {
            width: 280px;
            img {
              height: 50px;
            }
          }
        }
      }
    }
  }
}
