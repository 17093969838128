.container {
  @media screen and (min-width: 992px) {
    margin-bottom: 12rem;
  }

  .boxProducts {
    margin-top: 23rem;
    margin-bottom: 5rem;
    @media screen and (min-width: 992px) {
      margin: 24rem 0 10rem;
      padding-left: 9rem;
    }
  }

  .offerSession {
    margin-bottom: 15rem;
    @media screen and (min-width: 992px) {
      margin-bottom: 18rem;
    }
  }

  .benefitSession {
    margin-bottom: 5rem;
    @media screen and (min-width: 992px) {
      margin-bottom: 12rem;
    }
  }

  .wrapBanner{
    margin: 5% 0;
  }
}
