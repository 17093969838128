@import '../../../styles/themes/themes.scss';

.center {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 50px;

  .emptyMessage {
    @include themify('color', 'subtitle');
  }
}

.container {
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  .list {
    width: 100%;
    padding: 0 auto;
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 50px;
    flex-wrap: wrap;
    margin: 0 auto;
  }
}

@media (max-width: 750px) {
  .container {
    padding: 0;
  }
}
