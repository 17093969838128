@import '../../styles/colors';
@import '../../styles/fonts';
@import '../../styles/themes/themes.scss';

.header {
  flex: 1;
  display: flex;
  gap: 20px;
  padding: 2rem 0;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid;
  @include themify('border-color', 'const_gray');

  &:hover {
    cursor: pointer;
  }

  h6 {
    flex: 1;
    font-size: 16px;
    font-weight: bold;
    font-family: $font-regular;
    margin: 10px 0 5px;
    @include themify('color', 'primary');
  }

  .iconHeader {
    margin-top: 8px;
    @include themify('color', 'text_active_element');
  }
}

.questionContent{
  @include themify('color', 'primary-primary', ' a');
  a {
    font-weight: bold;
  }
}
