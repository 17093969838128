@import '../../styles/colors';
@import '../../styles/themes/themes.scss';

.container {
  &.theme-master {
    background: linear-gradient(90deg, $white 50%, $primary-master 50%);
  }
  &.theme-afluent {
    background: linear-gradient(90deg, $white 50%, $primary-afluent 50%);
  }
  &.theme-portoBank {
    background: linear-gradient(90deg, $white 50%, $primary-portoBank 50%);
  }
  header {
    display: flex;
    height: 8rem;
    align-items: center;
    z-index: 100;
    transition: all linear 0.4s;
    padding: 0 1rem;
    @media screen and (min-width: 400px) and (max-width: 1280px) {
      padding: 0 3rem;
    }

    @media screen and (min-width: 1280px) {
      padding: 0 8rem;
    }
  }

  .body {
    width: 100%;
    height: calc(100vh - 8rem);
    display: flex;
    align-items: center;

    padding: 0 1rem;
    @media screen and (min-width: 400px) and (max-width: 1280px) {
      padding: 0 3rem;
    }

    @media screen and (min-width: 1280px) {
      padding: 0 8rem;
    }

    @media (max-width: 520px) {
      height: 100vh;
    }
  }

  @media (max-width: 1090px) {
    &.theme-master {
      background: linear-gradient(180deg, $white 70%, $primary-master 30%);
    }
    &.theme-afluent {
      background: linear-gradient(180deg, $white 70%, $primary-afluent 30%);
    }
    &.theme-portoBank {
      background: linear-gradient(180deg, $white 70%, $primary-portoBank 30%);
    }
  }
}
