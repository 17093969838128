@import '../../../../../../styles/style.scss';

.legend {
  display: flex;
  flex-direction: row;
  div {
    display: flex;
    margin-right: 20px;
  }
  font-size: 0.9rem;

  margin-bottom: 20px;
}

.legendIcon {
  width: 20px;
  height: 20px;
  display: block;
  margin-right: 10px;
}

.legendAvailable {
  border: 2px solid;
  @include themify('border-color', 'primary-primary');
}

.legendUnavailable {
  border: 2px solid;
  @include themify('border-color', 'disabled-graphics');
  opacity: 0.5;
}

.legendSelected {
  @include themify('background-color', 'primary-primary');
}
