@import '../../../styles/media_queries';
@import '../../../styles/fonts';
@import '../../../styles/colors';
@import '../../../styles/themes/themes.scss';

.container {
  display: flex;
  flex-direction: column;
}

.center {
  justify-content: center;
}

.pagesLabel {
  display: flex;
  justify-content: flex-end;
  padding-right: 2.4rem !important;
  padding-bottom: 1rem;
}

.smallPaddingBottom {
  padding-bottom: 2rem;
}

@media (max-width: 990px) {
}


.table {
  margin: 3% auto;
  width: 100%;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  .header {
    @include themify('background-color', 'const_light_gray');
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 1rem;
    margin-right: 0;
    margin-left: 0;
    .cell {
      font-weight: 600;
      text-align: center;
    }

    .cellMobile {
      font-weight: 600;
      text-align: start;
    }
    .cellMobilePaddingLeft{
      padding-left: 19px;
    }
    th:first-child {
      div:first-child {
        div:first-child {
          margin-left: 8px;
        }
      }
    }

  }
  .body {
    .item {
      display: flex;
      justify-content: space-around;
      align-items: center;
      border-bottom: 1px solid #ccc;
      margin-right: 10px;
      margin-left: 10px;
      padding: 1rem;
      .cell {
        text-align: center;
      }
      .status {
        display: flex;
        justify-content: center;
      }
    }

    .itemMobile {
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin-right: 10px;
      margin-left: 10px;
      border-bottom: 1px solid #ccc;
      padding: 1rem;
      .cell {
        text-align: center;
      }
      .cellMobile {
        text-align: start;
      }
      .cellMobileEnd {
        text-align: end;
        padding-right: 2rem;
      }
      .status {
        display: flex;
        justify-content: center;
      }
    }

    .NoBottomBorder {
      border-bottom: none;
    }

    .itemExpand {
      display: flex;
      justify-content: space-around;
      align-items: flex-start;
      margin-right: 10px;
      margin-left: 10px;
      padding: 1rem;
      width: 100%;
      .label {
        // @include themify('color', 'disabled');
        color: #808080;
        padding-bottom: 1rem;
      }
      .contentRow {
        @include themify('color', 'primary');
        font-weight: 600;
        border-bottom: none;
      }

      @media screen and (max-width: 600px) {
        margin: 0;
        padding: 0;
        padding-bottom: 1rem;
        padding-top: 0;
      }
    }
    .decimal {
      font-size: 13px;
    }
  }
}

.rotateIcon {
  svg {
    transform: rotate(180deg);
  }
}
