@import '../../../../styles/colors';
@import '../../../../styles/themes/themes.scss';

.stepTwo {
  h2 {
    font-size: 26px;
    font-weight: 400;
    @include themify('color', 'primary-primary');
    @include themify('border-color', 'surface-inverse');
    border-bottom: 1px solid;
    padding-bottom: 38px;
    margin-bottom: 34px;
  }

  .boxCheckBoxs {
    span {
      span {
        font-style: oblique;
      }
    }
    .itemDescription {
      margin-left: 35px;

      span {
        font-size: 16px;
        color: $disabled;
        &.active {
          @include themify('color', 'primary-primary');
        }
      }
    }
  }

  .btnFooter {
    display: flex;
    justify-content: space-between;
    width: 100%;
    @include themify('border-color', 'surface-inverse');
    border-top: 1px solid;
    padding-top: 25px;
    margin-top: 50px;

    .divButton {
      width: 180px;
      
      button {
        width: 100%;
      }
    }
  }
}

@media (max-width: 575px) {
  .stepTwo {
    .btnFooter {
      .divButton {
        button {
          width: 95%;
        }
      }
      .divButton + .divButton {
        display: flex;
        justify-content: flex-end;
      }
      .divButton {
        width: 100%;
      }
    }
  }
}
