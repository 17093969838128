@import '../../../styles/themes/themes.scss';
@import '../../../styles/fonts';

.bannerContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0 9rem 10rem;
  height: 42rem;
  @include themify('color', 'text_and_background');
  @include themify('background', 'primary');

  h1 {
    font-weight: 300;
    font-size: 50px;
    margin-bottom: 20px;
  }

  p {
    font-weight: 300;
    font-size: 18px;
    font-family: $font-regular;
  }

  @media (max-width: 992px) {
    padding: 0 2rem 10rem;
  }
}
