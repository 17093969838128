.incidentAreal {
  margin-top: 0.7rem;
  margin-bottom: 0.5rem;
  h3 {
    color: #333;
    font-size: 1.175rem;
    font-weight: 300;
    margin-top: 0.1rem;
    margin-bottom: 0;
  }
  .labelTitle {
    color: #555;
    font-size: 1.2rem;
    font-weight: 300;
  }
}
