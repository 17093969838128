@import '../../../../styles/fonts';
@import '../../../../styles/colors';
@import '../../../../styles/media_queries';
@import '../../../../styles/themes/themes.scss';

.claims {
  margin-bottom: 4rem;

  @media (min-width: 990px) {
    padding-left: 1.5% !important;
  }

  .generate {
    margin-bottom: 4rem;
  }

  .counter {
    display: flex;
    @include themify('background', 'text_and_background');
    border-radius: 3px;
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1);
    margin-bottom: 4rem;
    min-height: 288px;
    width: 100%;

    .item {
      position: relative;
      padding: 3rem 0;
      width: 47%;
      min-height: 168px;
      @media (forced-colors: active) {
        border: 1px solid;
      }

      span {
        padding-left: 9%;
      }

      .icon {
        width: 35px;
        height: 35px;
        display: block;
        margin-right: 5%;
      }

      .info {
        .number {
          @include themify('color', 'primary');
          font-size: 60px;
          padding-left: 8%;
        }

        h6 {
          @include themify('color', 'subtitle');
          font-size: 25px;
          padding-left: 9%;
        }

        a {
          position: absolute;
          bottom: 30px;
          margin-left: 9%;
        }
      }
    }

    .divider {
      margin-top: 3%;
      border-left: 1px solid;
      @include themify('border-color', 'tertiary');
      width: 0;
      height: 45px;
    }
  }

  @media (max-width: $width-medium) {
    .counter {
      flex-direction: column;
      align-items: center;
      padding: 4% 0;

      &.marginLeft {
        margin-left: 0;
      }
      .item {
        display: flex;
        flex-direction: row-reverse;
        width: 100%;

        .icon {
          width: 26px;
          height: 26px;
        }
        span {
          padding: 2% 5% 0 0;
        }
        .info {
          flex: 1;
          display: flex;
          .number {
            padding-top: 2%;
            flex: 1 1;
            font-size: 40px;
            margin-bottom: 0;
            line-height: 1;
          }

          div {
            padding-top: 2%;
            flex: 3 3;

            h6 {
              padding-left: 2%;
              padding-right: 4%;
            }
            a {
              margin-left: 2%;
            }
          }
        }
      }
      .divider {
        margin-top: 3%;
        border-left: 1px solid;
        @include themify('border-color', 'tertiary');
        height: 0;
        width: 45px;
        margin-left: -30%;
      }
    }
  }
  @media (max-width: 1117px) {
    .counter {
      .item {
        .info {
          h6 {
            font-size: 20px;
          }
        }
      }
    }
  }
}
