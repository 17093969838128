@import "../../../../../../styles/colors";
@import "../../../../../../styles/fonts";
@import "../../../../../../styles/themes/themes.scss";

.tokenex {
  width: 100%;
}

.alertMessage {
  background-color: $error-red-alert;
  width: 100%;
  padding: 10.5px;
  text-align: center;
  margin-bottom: 10px;
  span {
    letter-spacing: 1.5px;
    color: $white;
    font-size: 15.4px;
    font-family: $font-regular;
    max-width: 494px;
  }
}

.visaWarning{
  font-size: 13px;
}

.boxForm{
  .tokenexIframe{
    height: 60px;
    margin-top: 1px;
  }
}

.btnFooter {
  display: flex;
  width: 100%;
  @include themify('border-color', 'tertiary');
  border-top: 1px solid;
  padding-top: 20px;
  margin-top: 10px;

  button{
    max-width: 180px;
    @media(max-width: 580px){
      max-width: none;
    }
  }
}

