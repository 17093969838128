@import '../../styles/colors';
@import '../../styles/media_queries';
@import '../../styles/fonts';
@import '../../styles/themes/themes.scss';

.container {
  .boxContainer {
    position: relative;
    bottom: 100px;
    .infoContainer {
      max-width: 65%;
      padding: 150px 100px 30px;
      @include themify('color', 'primary');
      h1 {
        @include themify('color', 'primary');
        font-size: 33px;
        margin-bottom: 25px;
      }

      p {
        max-width: none !important;
      }

      .messengerElegibility {
        margin-top: 30px;
        color: red;
      }

      .info {
        margin-top: 50px;
        button {
          max-width: 250px;
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .container {
    .boxContainer {
      bottom: 0px;
      .infoContainer {
        max-width: none;
        padding: 110px 20px 30px;
      }
    }
  }
}

@media (max-width: 580px) {
  .container {
    .boxContainer {
      bottom: 0px;
      .infoContainer {
        padding: 10px 20px 30px;
      }
    }
  }
}
