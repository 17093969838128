@import '../../styles/fonts';
@import '../../styles/colors';

.chipContainer {
  height: 32px;
  padding: 0 16px;

  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;

  border-radius: 32px;
  cursor: default;

  &.POSITIVE {
    background: $tea_green;
    span {
      color: $green_100;
    }
  }
  &.NEGATIVE {
    background: $pink;
    span {
      color: $red_100;
    }
  }
  &.WARNING {
    background: $sorbet_yellow;
    span {
      color: $visa_yellow_100;
    }
  }
  &.INFO {
    background: $light_blue;
    span {
      color: $blue_100;
    }
  }
  &.DISABLED {
    background: $gray_20;
    span {
      color: $gray_100;
    }
  }

  span {
    font-family: $font-semibold;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    white-space: nowrap;
  }
}
