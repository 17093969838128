@import '../../../../../../../../styles/colors';
@import '../../../../../../../../styles/themes/themes.scss';
@import '../../../../../../../../styles/fonts';

.itemArea {
  margin-bottom: 0.7rem;
  .labelTitle {
    display: flex;
    margin: 20px 0px 14px;
    font-size: 16px;
    font-family: $font-regular;
    @include themify('color', 'link');
  }
  .labelInfo {
    font-size: 16px;
    @include themify('color', 'subtitle');
    margin-bottom: 24px;
  }
}

.buttonArea {
  display: flex;
  justify-content: flex-end;
  button {
    margin-right: -8px;
  }
}
