@import '../../../styles/media_queries';

.container {
  width: fit-content;
  max-width: 700px;
  padding-top: 30px;
}

@media (max-width: $width-small) {
  .container {
    width: 100%;
  }
}
