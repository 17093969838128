@import '../../../styles/colors';
@import '../../../styles/fonts';
@import '../../../styles/media_queries';

.allBenefits {
  padding-bottom: 5rem;
  @media screen and (min-width: $width-large) {
    margin: 5rem 0;
    padding: 0 9rem;
  }
  @media(max-width: 1200px) {
    margin: 15px;
  }
  @media(min-width: 1200px){
    max-width: 1760px;
    margin-left: auto;
    margin-right: auto;
  }

  .loadingContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 5rem;
  }
}
