@import '../../../../styles/themes/themes.scss';
@import '../../../../styles/fonts';

.footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid;
  border-color: get-theme-color('axa', 'surface-inverse');
  padding-top: 25px;
  margin-top: 46px;

  .divButton {
    width: 180px;
    
    button {
      width: 100%;
    }
  }
}

@media (max-width: 575px) {
  .footer {
    .divButton{
      button {
        width: 95%;
      }
    }
    .divButton + .divButton{
      display: flex;
      justify-content: flex-end;
    }
    .divButton {
      width: 100%;
    }
  }
}

.title {
  font-size: 2.6rem;
  font-family: $font-regular;
  margin-bottom: 40px;
  color: get-theme-color('axa', 'primary-primary');
}

.subtitle{
  font-size: 1.4rem;
  margin-bottom: 20px;
}
