@import '../../../styles/themes/themes.scss';
@import '../../../styles/media_queries';

.containerInfo{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  padding-bottom: 0.5rem;

  .referenceText {
    @include themify('color', 'subtitle');
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0px;
  }
  .valueText {
    @include themify('color', 'primary');
    font-weight: 600;
    font-size: 16px;
  }

  @media screen and (max-width: $width-small) {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

.container{
  display: flex;
  align-items: center;
  padding-bottom: 2rem;
  @media screen and (max-width: $width-medium) {
    padding-bottom: 0;
  }
}

.containerItems{
  display: flex;
  flex-grow: 1;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  padding-bottom: 3rem;
  flex-direction: column;
}

.containerViewMore{
  display: flex;
  flex-grow: 1;
  align-self: center;
  justify-content: center;
  text-decoration: underline;
  padding-top: 2rem;
  font-weight: bold;

  @include themify('text-decoration-color', 'secondary');

  a {
    @include themify('color', 'secondary');
    @include themify('text-decoration-color', 'secondary');
  }
}

.containerBorder{
  display: flex;
  border-left: 1px solid;
  @include themify('border-color', 'secondary');
  flex-direction: column;
  flex-grow: 1;
}

.arrow {
  @include themify('color', 'text_active_element');
  // margin-left: 30px;
  padding-left: 1rem;
  order: 2;
  cursor: pointer;
  @media screen and (min-width: 1300px) {
    order: 5;
  }
  @media screen and (min-width: $width-small) {
    margin-left: 0px;
  }
}

.showMoreActive {
  // width: 100%;
  // display: flex;
  // justify-content: flex-end;
}

.rotateIcon {
  svg {
    transform: rotate(180deg);
  }
}

@media (max-width: 820px) {
  .containerBorder {
    padding-left: 1rem;
  }
}

.tooltip{
  display: flex;
  justify-content: center;
  align-items: center;
}


.ContainerTooltip{
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: $width-small) {
    width: 100%;
    justify-content: space-between;
  }
  @media screen and (min-width: $width-small) and (max-width: $width-large) {
    width: 50%;
    justify-content: space-between;
  }
}
