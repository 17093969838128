@import '../../styles/colors';
@import '../../styles/fonts';
@import '../../styles/themes/themes.scss';

// For original file style refer to: https://github.com/tbleckert/react-select-search/blob/main/style.css

.container {
  margin-top: 22px;
  margin-bottom: 22px;
  position: relative;
  font-family: $font-light;
  width: 100%;
}

.input {
  @include themify('color', 'text-paragraph_subtle');
  border: none;
  border-bottom: 1px solid;
  @include themify('border-color', 'text-paragraph_subtle');
  width: 100%;
  background: transparent;
  padding: 6px 0;
  font-size: 1.6rem;
  appearance: none;
  height: 35px;
  outline: none;

  @media (max-width: 575px) {
    height: auto;
    font-size: 14px;
    white-space: pre-line;
  }

  &:focus {
    border-bottom: 1px solid;
    @include themify('border-color', 'secondary-secondary');
    border-bottom-width: 2px;
  }

  &:disabled {
    cursor: not-allowed;
  }
}

.chevronIcon {
  position: absolute;
  top: 10px;
  right: 0;
  pointer-events: none;
}

div.tooltip {
  position: absolute;
  right: 26px;
  top: 4px;
  padding: 0;

  span:hover:before {
    right: -26px !important;
  }
}

.input.error {
  border-bottom-color: $error-red-alert;
}

.label {
  position: absolute;
  top: -16px;
  font-size: 12px;
  font-weight: bold;
  font-family: $font-regular;
  @include themify('color', 'primary');

  &.axa {
    @include themify('color', 'const_axa_blue');
  }
}

.errorLabel {
  font-size: 0.75em;
  color: $error-red-alert;
}

.isMultiple .input {
  margin-bottom: -2px;
}

.input[readonly] {
  cursor: pointer;
}

.isDisabled .input {
  cursor: not-allowed;
}

.select {
  @include themify('background-color', 'text_and_background');
  border: 1px solid;
  @include themify('border-bottom-color', 'primary');
  @include themify('border-right-color', 'primary');
  @include themify('border-left-color', 'primary');
  border-top-color: transparent;
  overflow: auto;
  max-height: 360px;
}

.inputContainer:not(.isMultiple) .select {
  position: absolute;
  z-index: 2;
  top: 35px;
  right: 0;
  left: 0;
  display: none;
}

.inputContainer:not(.isMultiple).hasFocus .select {
  display: block;
}

.option,
.notFound {
  display: block;
  width: 100%;
  padding: 4px 2px;
  @include themify('background-color', 'text_and_background');
  border: none;
  outline: none;
  text-align: left;
  cursor: pointer;
  margin-bottom: 0;
  font-family: 'Twemoji Country Flags', $font-light;
}

.notFound {
  @include themify('color', 'alert');
}

.option:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  background: transparent !important;
}

.isHighlighted,
.option:not(.isSelected):hover {
  @include themify('background-color', 'primary');
  @include themify('color', 'text_and_background');
}

.isSelected {
  font-weight: bold;
  @include themify('background-color', 'primary');
  @include themify('color', 'text_and_background');
}

.row:not(:first-child) .groupHeader {
  margin-top: 10px;
}

.row:not(:last-child) .groupHeader {
  margin-bottom: 10px;
}

.value {
  @include themify('color', 'primary');
}