@import '../../../styles/style.scss';
@import '../../../styles/media_queries';

.benefitItem {
  .item {
    @include themify('background', 'text_and_background');
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    padding: 5%;
    margin: 0 0 7% 0;
    height: 204px;
    display: flex;
    flex-direction: column;
    @media (forced-colors: active) {
      border: 1px solid;
    }

    &.absolute {
      width: 100%;
      height: auto !important;
    }

    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;

      .category {
        @include themify('color', 'subtitle');
        font-family: $font-semibold;
        font-size: 16px;
        line-height: 22px;
      }
    }

    .center {
      h6 {
        @include themify('color', 'link');
        font-size: 25px;
        @media screen and (max-width: 1550px) {
          font-size: 22px;
        }
        @media screen and (max-width: 1400px) {
          font-size: 20px;
        }
      }
    }

    .content {
      h4 {
        font-size: 16px;
        font-weight: bold;
        padding: 0 2rem;
        line-height: 24px;
        margin-bottom: 1.5rem;
      }

      h5 {
        font-size: 16px;
        font-weight: bold;
        padding: 0 2rem;
        margin-top: 3rem;
      }
      &.none {
        display: none !important;
      }

      ul {
        list-style-type: none !important;
        margin-top: 5%;

        li {
          border-left: 1px solid;
          @include themify('border-left-color', 'secondary');
          margin-bottom: 4%;
          padding-left: 6%;
        }
      }

      .buttonWrapper {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 5%;

        button {
          background-color: transparent;
          border: 0;
          @include themify('color', 'secondary');
          outline: none;
        }
      }

      .buttonsArea {
        display: flex;
        flex-direction: column;
      }

      .actions {
        border-top: 1px solid;
        @include themify('border-top-color', 'tertiary');
        padding-top: 8px;

        .actionButton {
          width: 100%;
        }

        button {
          width: 100%;
        }
      }

      .redeemInfo {
        font-weight: bold;
      }
    }

    .bottom {
      display: flex;
      justify-content: space-between;
      bottom: 16px;
      position: absolute;
      width: calc(100% - 10%);

      &.none {
        display: none;
      }

      .generate {
        display: block;
        width: 100%;

        .linkButton {
          margin-left: -8px;
        }
      }

      .actions {
        button {
          background-color: transparent;
          border: 0;
          @include themify('color', 'secondary');
          outline: none;
          width: 30px;
        }
      }
    }

    .link {
      display: inline-block;
      font-family: $font-regular;
      a{
       color: #021E4C
      }
    }
  }
  .iconChevron {
    @include themify('color', 'text_active_element');
  }
}

.iconChevron {
  @include themify('color', 'secondary');
  font-weight: normal;
  margin-left: auto;
  svg {
    padding-top: 8px;
  }
}

.iconArrowRight {
  margin-left: 3%;
}
