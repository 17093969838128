@import '../../../styles/media_queries';
@import '../../../styles/fonts';
@import '../../../styles/colors';
@import '../../../styles/themes/themes.scss';

.container {
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin-bottom: 10rem;

  .content {
    .menusArea {
      span {
        display: flex;
        padding: 2rem 0 4rem 0;
        width: 60% !important;
        font-size: 16px;
        line-height: 24px;
        @include themify('color', 'subtitle');
      }
    }
  }
}

@media (max-width: 992px) {
  .container {
    .content {
      .menusArea {
        padding-right: 2rem;

        span {
          width: 100% !important;
        }
      }
    }
  }
}
