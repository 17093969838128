@import '../../styles/style.scss';

.containerModalTitle {
  display: flex;
  margin-right: 10px;
  margin-top: 3.8rem;
  justify-content: center;
  .close.axa {
    span {
      @include themify('color', 'const_axa_blue');
    }
  }
  .close {
    font-weight: 500 !important;
    position: absolute;
    top: 30px;
    right: 30px;
    opacity: 1;

    &:hover:not(:disabled):not(.disabled),
    &:focus:not(:disabled):not(.disabled) {
      opacity: 1;
    }

    @media (max-width: 920px) {
      right: 10px;
    }

    span {
      @include themify('color', 'secondary');
      font-size: 40px;
      font-family: $font-light;
      margin-right: 10px;
      &:hover,
      &:focus {
        opacity: 0.5;
      }
    }
  }
}

.mandatoryMessage {
  display: flex;
  justify-content: center;
  margin-right: 10px;
  .wrappSpan {
    font-size: 12px;
    max-width: 1240px;
    width: 100%;
  }

  @media (max-width: 1199.98px) {
    .wrappSpan {
      padding: 2rem;
    }
  }
}

.boxModalTitle {
  display: flex;
  align-items: center;
  max-width: 1240px;
  width: 100%;

  @media (max-width: 1199.98px) {
    padding: 0px 20px;
  }

  img {
    margin-right: 18px;
    @media screen and (min-width: 992px) {
      padding-bottom: 15px;
    }
  }

  svg {
    margin-right: 18px;
    @media screen and (min-width: 992px) {
      margin-bottom: 15px;
    }
  }

  .modalTitle {
    font-size: 2.6rem;
    font-family: $font-light;
    @include themify('color', 'primary');
    @media screen and (min-width: 992px) {
      font-size: 3.3rem;
      padding-bottom: 15px;
    }
  }
}

.borderBottom {
  border-bottom: 1px solid;
  @include themify('border-bottom-color', 'tertiary');
}

.show {
  display: block !important;
}

.zindexNone {
  z-index: 0;
}

.noMargin {
  @media (max-width: 992px) {
    margin: 0 !important;
  }
}

.opacity {
  opacity: 0.6;
}

.noBorder {
  border: 0 !important;
  border-radius: 0 !important;
}

@media (max-width: 992px) {
  .modalMobile {
    max-width: none !important;
    width: 100% !important;
    align-items: normal !important;
    min-height: 100% !important;
  }
}

@media (min-width: 560px) and (max-width: 992px) {
  .modalMobile {
    min-height: 100% !important;
  }
}

.boxContent {
  width: 100%;
  max-width: 1240px;

  margin: 50px auto;
  padding: 0 0px 50px;
}

.borderHighLight {
  @media (forced-colors: active) {
    border: 1px solid !important;
  }
}

.modalDuo {
  background: linear-gradient();
  &.theme-master {
    background: linear-gradient(
      to right,
      $white 0%,
      $white 60%,
      $tertiary-master 60%,
      $tertiary-master 100%
    );
  }
  &.theme-afluent {
    background: linear-gradient(
      to right,
      $white 0%,
      $white 60%,
      $tertiary-afluent 60%,
      $tertiary-afluent 100%
    );
  }

  &.theme-portoBank {
    background: linear-gradient(
      to right,
      $white 0%,
      $white 60%,
      $tertiary-afluent 60%,
      $tertiary-afluent 100%
    );
  }

  max-width: 100%;
  @media screen and (min-width: 600px) and (max-width: 992px) {
    &.theme-master {
      background: linear-gradient(
        to bottom,
        $white 0%,
        $white 75%,
        $tertiary-master 75%,
        $tertiary-master 100%
      );
    }
    &.theme-afluent {
      background: linear-gradient(
        to bottom,
        $white 0%,
        $white 75%,
        $tertiary-afluent 75%,
        $tertiary-afluent 100%
      );
    }
    &.theme-portoBank {
      background: linear-gradient(
        to bottom,
        $white 0%,
        $white 75%,
        $tertiary-afluent 75%,
        $tertiary-afluent 100%
      );
    }
  }

  @media screen and (max-width: 600px) {
    &.theme-master {
      background: linear-gradient(
        to bottom,
        $white 0%,
        $white 75%,
        $tertiary-master 75%,
        $tertiary-master 100%
      );
    }
    &.theme-afluent {
      background: linear-gradient(
        to bottom,
        $white 0%,
        $white 75%,
        $tertiary-afluent 75%,
        $tertiary-afluent 100%
      );
    }
    &.theme-portoBank {
      background: linear-gradient(
        to bottom,
        $white 0%,
        $white 75%,
        $tertiary-afluent 75%,
        $tertiary-afluent 100%
      );
    }
  }
}

.modal {
  z-index: 10101 !important;

  @media (forced-colors: active) {
    background: #000;
  }
}

.modalErrorContent {
  padding: 0px !important;
  margin: 0px auto !important;
}

.modalErrorContent {
  padding: 0px !important;
  margin: 0px auto !important;
}
