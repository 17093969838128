@import '../../styles/media_queries';
@import '../../styles/fonts';
@import '../../styles/colors';
@import '../../styles/themes/themes.scss';

.tabBar {
  display: flex;
  flex: 1;
  overflow-x: auto;
  flex-direction: row;
  margin: 1rem 0 5rem 0;
  padding-bottom: 5px;

  .tab,
  .tabSelected {
    white-space: nowrap;
    font-size: 16px;
    margin-right: 5rem;
    font-style: normal;
    &:hover {
      cursor: pointer;
      border-bottom: 1px solid;
      @include themify('border-color', 'secondary-secondary');
    }
  }

  .tab {
    @include themify('background', 'text_and_background');
    @include themify('color', 'subtitle');
    font-family: $font-light;
    font-weight: 300;
  }

  .tabSelected {
    @include themify('background', 'text_and_background');
    border-bottom: 1px solid;
    @include themify('border-color', 'secondary-secondary');
    font-family: $font-regular;
    font-weight: 600;
    @include themify('color', 'subtitle');
  }
}

@media (max-width: 990px) {
  .contentFilter {
    display: flex;
    flex: 1;
    overflow-y: scroll;
    padding: 1rem 0 2rem 0;
  }
}
