@import '../../../../../../../styles/themes/themes.scss';
@import '../../../../../../../styles/fonts';

.fileContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  padding: 10px 15px;
  margin: 10px 0;

  .infoContainer {
    display: flex;
    align-items: center;
    gap: 1rem;

    .name {
      @include themify('color', 'text-paragraph_subtle');
      font-family: $font-regular;
      font-weight: 600;
      font-size: 1.6rem;
      margin-right: 10px;

    &:nth-child(1) {
      padding-right: 10px;
      @include themify('border-color', 'secondary-secondary');
      border-right: 1px solid;
  }
}
  }


.closeIcon {
  @include themify('color', 'primary-primary');
  width: 20px;
  font-size: 20px;
  margin-right: 10px;

}

}
