@import '../../../../../styles/style.scss';

.notificationsItem {
  display: flex;

  @include themify('background', 'text_and_background');
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  border-radius: 3px;
  margin-bottom: 15px;
  padding: 30px 21px;
  flex-direction: column;

  @media(forced-colors: active){
    border: 1px solid;
  }

  @media screen and (min-width: $width-extra-large) {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 20px;
  }

  &.checked {
    border: 1px solid;
    @include themify('border-color', 'secondary-secondary');
  }

  &.activeNotficationBorder {
    border-left: 4px solid;
    @include themify('border-color', 'warning-graphics');
  }

  .activeNotificationBlue {
    @include themify('color', 'primary-primary');
  }

  .left {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 15px;

    @media screen and (min-width: $width-extra-large) {
      width: 40%;
      flex-direction: row;
      justify-content: flex-start;
      // align-items: center;
      margin-bottom: 0;
    }

    @media screen and (min-width: 1500px) {
      width: 40%;
    }
    @media screen and (min-width: 1700px) {
      width: 60%;
    }

    .icon {
      @include themify('color', 'text_active_element');
      order: 2;
      @media screen and (min-width: $width-extra-large) {
        order: 1;
      }

      img {
        width: 50px;
      }
    }
    .text {
      @include themify('color', 'text-paragraph_subtle');
      display: flex;
      font-size: 16px;
      flex-direction: column;
      padding-right: 10px;

      order: 1;
      @media screen and (min-width: $width-extra-large) {
        order: 2;
        padding-left: 4%;
        padding-right: 4%;
      }

      span {
        font-family: $font-semibold;
        font-weight: 600;
        line-height: 30px;
        word-break: break-word;
      }
      .moreInfo {
        font-family: $font-light;
        font-size: 1.6rem;
        font-weight: normal;
      }
    }
  }

  .center {
    display: flex;
    flex-direction: column;
    text-align: right;
    justify-content: space-between;

    &.verticalDivider {
      padding-right: 5rem;
    }

    &.noBorder {
      border-right: 0 !important;
    }

    span {
      line-height: 30px;

      strong {
        font-family: $font-semibold;
        font-weight: 600;
      }

      &.arrow {
        @include themify('color', 'text_active_element');
        cursor: pointer;
        @media screen and (min-width: $width-extra-large) {
          display: none;
        }
        @media screen and (min-width: $width-small) {
        }
      }
    }

    @media screen and (min-width: $width-extra-large) {
      width: 30%;
    }
    @media screen and (min-width: 1500px) {
      width: 30%;
    }
    @media screen and (min-width: 1700px) {
      width: 20%;
    }

    @media screen and (max-width: $width-extra-large) {
      flex-direction: row;
      justify-content: flex-start;
      text-align: left;
      width: 99%;

      &.verticalDivider {
        border-right: 0;
        padding-right: 0;
      }
    }
  }

  .right {
    display: flex;
    padding-top: 15px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @media screen and (min-width: $width-extra-large) {
      width: 30%;
      align-items: center;
      justify-content: flex-end;
      height: 40px;
      flex-wrap: nowrap;
      padding-top: 0;
    }
    @media screen and (min-width: 1500px) {
      width: 30%;
    }
    @media screen and (min-width: 1700px) {
      width: 20%;
    }

    @media screen and (max-width: $width-extra-large) {
      display: none;
    }

    button, a {
      width: 100%;
    }

    .arrow {
      @include themify('color', 'text_active_element');
      margin-left: 30px;
      order: 2;
      cursor: pointer;
      @media screen and (max-width: $width-extra-large) {
        display: none;
      }
    }
  }

  .bottom {
    align-self: flex-end;
    color: $dark-blue;
    flex-grow: 1;
    width: 100%;
  }
}

.detailContent {
  border-top: 1px solid;
  @include themify('border-color', 'tertiary');
  margin-top: 2rem;
  width: 100%;

  .detailContentText {
    padding: 1.5rem 2rem 0 1.5rem;
    display: flex;

    a {
      font-weight: 600;
      color: $dark-blue;
    }
  }
}

.detailContentButtons {
  margin: 3.2rem 1.6rem 0;
  display: flex;
  align-items: center;
  gap: 1.6rem;

  @media screen and (max-width: $width-extra-large) {
    flex-direction: column-reverse;
    align-items: stretch;
    
    button, a {
      width: 100%;
    }
  }
}

.button {
  width: 100%;
  order: 3;

  @media screen and (min-width: $width-extra-large) {
    width: 180px;
    order: 2;
  }

  @media screen and (max-width: $width-extra-large) {
    display: none;
  }
}

.labelStatus {
  width: 180px;
  display: flex;
  justify-content: flex-start;
  @media screen and (min-width: $width-extra-large) {
    justify-content: center;
  }
}

.moreInfo {
  font-family: $font-light;
  font-size: 1.6rem;
}

.hideButtons {
  .button {
    display: none;
  }
}

.detailContent :global(.btnSelect) {
  button {
    @include themify('background', 'secondary-secondary');

    @include themify('color', 'primary-primary');
    border: 2px solid;
    @include themify('border-color', 'secondary-secondary');
    font-weight: 600;
    &:hover {
      border: 2px solid;
      @include themify('border-color', 'primary-primary');
    }
  }
}

.showMoreActive {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.rotateIcon {
  transform: rotate(180deg);
}

.flightStatus {
  display: flex;
  column-gap: 16px;
  row-gap: 8px;
  flex-wrap: wrap;

  span {
    line-height: initial !important;
    white-space: nowrap;
  }
}

.buttonLeftDivider {
  @include themify('border-right-color', 'secondary-secondary');
  border-right: 1px solid;
  padding-right: 1.6rem;

  @media (max-width: 1300px) {
    width: 100%;
    border-right: none;
    padding-right: 0;
  }
}

.noticationBodyLoading {
  width: 100%;
  margin-left: 2.4rem;
}