@import '../../styles/colors';
@import '../../styles/themes/themes.scss';

.container {
  @include themify-half-color-gradient();
}

.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
}
