@import '../../../../../../../../../styles/style.scss';

.titleRadio {
  display: flex;
  margin-bottom: 15px;
  font-size: 16px;
  font-family: $font-regular;
  @include themify('color', 'link');
}
.optionsArea {
  .option {
    display: flex !important;
    flex: auto !important;
    max-width: none !important;
    padding: 0 15px;
    width: 100%;
    @include themify('color', 'tertiary');
    font-size: 14px;
  }
}
.rowSpaceBetween {
  justify-content: space-between;
}

.marginBottom {
  margin-bottom: 20px;
}

.options {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  .option {
    display: flex !important;
    flex: auto !important;
    max-width: none !important;
    padding: 0 15px;
    width: 100%;
    @include themify('color', 'tertiary');
    font-size: 14px;
  }
}

@media (max-width: 1199.98px) {
  .section {
      padding: 2rem;
  }
}
