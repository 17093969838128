@import '../../styles/themes/themes.scss';

.containerLogin {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  @include themify('background', 'tertiary');
}
