@import '../../../../styles/style.scss';

.iconColorTheme {
  @include themify('color', 'text_active_element');
  height: 20px;
  width: 20px;
}

.iconArrow {
  position: absolute;
  top: 160px;
  @media screen and (max-width: 992px) {
    top: 210px;
  }
}

.iconArrowRight {
  right: 0px;
  cursor: pointer;
}
.iconArrowLeft {
  left: 0px;
  cursor: pointer;
}

.slider {
  padding-top: 25px;
}
