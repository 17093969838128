@import '../../styles/style.scss';

.container {
  width: 600px;
  z-index: 999999;
  border-radius: 8px 8px 0px 0px;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.25);
  transition: transform 0.7s ease-in-out;
  transform: translate(0, 100%);
  max-height: 100%;

  padding: 48px 0 16px;
  border-style: none;

  @include themify('background-color', 'surface-surface');

  position: fixed;
  bottom: 0;
  left: 42px;

  &.showing {
    transform: translate(0, 0%);
  }

  @media screen and (max-width: $width-medium) {
    width: 100%;
    left: 0;
    right: 0;
  }
}

.closeIcon {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 40px;
  height: 40px;
  padding: 12px;

  border-style: none;
  background-color: transparent;
}