@import '../../../../../styles/style.scss';

.benefitItem {
  display: flex;
  position: relative;

  @include themify('background', 'const_light_gray');
  width: 230px;
  height: 140px;
  padding: 15px 15px 15px 50px;
  margin: 25px 30px;

  &:after {
    content: '';

    position: absolute;
    bottom: -32px;
    left: 20px;

    width: 0;
    height: 0;
    border-top: 20px solid $const_light_gray;
    border-right: 20px solid $const_light_gray;
    border-bottom: 20px solid transparent;
    border-left: 20px solid transparent;
  }

  .imageContent {
    position: absolute;
    top: -30px;
    left: -30px;

    @include themify('background', 'badge_download_app');
    border-radius: 50%;
    border: 5px solid $white;
    padding: 15px;

    img {
      width: 30px;
      height: 30px;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;

    h4 {
      font-size: 14px;
      font-family: $font-semibold;
      @include themify('color', 'primary');
    }
    p {
      font-size: 12px;
      font-family: $font-regular;
    }
  }
}

@media(max-width: 1760px){
  .benefitItem{
    margin: 25px 18px;
  }
}
