@import '../../../../../styles/themes/themes.scss';
@import '../../../../../styles/fonts';

.container {
  .benefitArea {
    margin-bottom: 16px;
    .benefit {
      p {
        border-bottom: 1px dashed;
        padding-bottom: 5px;
        margin-bottom: 15px;
        @include themify('border-color', 'const_gray');
      }
    }
  }
}

.marginRow {
  margin-bottom: 35px;
}

.textForAll {
  font-family: $font-regular;
  font-size: 12px;
  line-height: 15px;
  @include themify('color', 'primary');
}
.amountArea {
  margin-top: 55px;
  .amountInteger {
    font-size: 2.6rem;
    font-family: $font-regular;
    @include themify('color', 'const_axa_blue');
  }
  .amountDecimal {
    font-size: 2rem;
    font-family: $font-regular;
    @include themify('color', 'const_axa_blue');
  }
}
