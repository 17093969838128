@import '../../../../../../../../styles/themes/themes.scss';
@import '../../../../../../../../styles/colors';

.personalArea {
  .none {
    display: none;
  }
}

.labelTitle {
  font-size: 16px;
  @include themify('color', 'subtitle');
  margin-bottom: 24px;
}
