@import '../../styles/_colors.scss';
@import '../../styles/_media_queries.scss';
@import '../../styles/_fonts.scss';
@import '../../styles/themes/themes.scss';

@mixin themify-inside-prismic() {
  @each $theme, $colors in $themes {
    .insidePrismic.theme-#{$theme} {
      h2 {
        margin-top: 40px;
        font-family: $font-bold;
        color: get-theme-color($theme, 'secondary-secondary');
      }
      div div {
        h1 ~ ul {
          li {
            div {
              border: 2px solid get-theme-color($theme, 'secondary-secondary');
              padding: 20px;
            }
    
            p{
              margin-top: 0;
            }
          }
        }
      }
    }
  }
}

.containerBenefitsDetail {
  @media screen and (min-width: 992px) {
    min-height: 1100px;
  }

  .boxContainer {
    position: relative;
    bottom: 100px;

    .infoContainer {
      max-width: 70%;
      padding: 150px 100px 30px;
      @include themify('color', 'primary-primary');

      @include themify-inside-prismic();

      .loadingContainer {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .notAdditional,
      .termsArea,
      .toRedeem {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 5px;

        img, svg {
          width: 15px;
          height: 15px;
          margin-right: 10px;
        }
        p {
          font-size: 14px;
          text-align: center;
          margin-bottom: 0;

        }
      }

      .notAdditional {
        margin-top: 10px;
      }
      .toRedeem {
        margin-bottom: 120px;
      }

      .cardImageArea {
        text-align: center;

        img {
          max-width: 100%;
        }

        p {
          margin: 50px 0;
          font-size: 33px;
          font-family: $font-semibold;
        }
      }

      .logoArea {
        margin: 80px 0 40px;
        display: flex;
        justify-content: center;
        align-items: center;

        .benefitLogo {
          width: auto;
          max-height: 40px;

          margin-left: 25px;
          padding-left: 25px;
          border-left: 1px solid;
          border-color: $primary-master;
        }
      }

    }
    @media(min-width: 1200px) {
      max-width: 1760px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

@media (max-width: 1280px) {
  .containerBenefitsDetail {
    .boxContainer {
      .infoContainer {
        max-width: none;
        padding: 150px 4% 30px;

        .cardImageArea {
          p {
            font-size: 24px;
          }
        }
      }
    }
  }
}

@media (max-width: 580px) {
  .containerBenefitsDetail {
    .boxContainer {
      .infoContainer {
        max-width: none;
        padding: 120px 4% 30px;

        .notAdditional,
        .toRedeem {
          p {
            font-size: 12px;
          }
        }
      }
    }
  }
}

.navigation {
  padding: 0 9rem;

  @media (max-width: 992px) {
    padding: 0 2rem;
  }
}
