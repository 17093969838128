@import '../../../../styles/style.scss';

.bannerContainer {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  height: 700px;
  max-height: 80vh;
  position: relative;

  .content {
    position: absolute;
    bottom: 60px;
    left: 90px;

    h1 {
      font-family: $font-bold;
      color: $white;
      font-size: 60px;
    }

    svg {
      margin-left: 20px;
      margin-bottom: 10px;
    }
  }
}

@media (max-width: 992px) {
  .bannerContainer {
    .content {
      left: 0;
      right: 0;

      h1 {
        font-size: 36px;
        text-align: center;
        padding: 0 5px;
      }
      img {
        width: 22px;
      }
    }
  }
}

@media (max-width: 500px) {
  .bannerContainer {
    height: 70vh;
    max-height: none;
  }
}
