@import '../../styles/style.scss';

.highlightContainer {
  display: flex;
  width: 100%;
  border: 1px solid;
  @include themify('border-color', 'info-graphics');
  border-radius: 8px;

  .iconArea {
    padding: 16px 10px;
    @include themify('background-color', 'info-graphics');
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    display: flex;
    align-items: center;
  }

  .highlightNote {
    display: block;
    padding: 16px;
    font-family: $font-regular;
  }

  .highLightLink {
    margin: 16px;
    margin-top: 0;
    font-size: 16px;
    font-family: $font-regular;
    display: block;
    width: fit-content;
  }
}
