@import '../../../../styles/colors';
@import '../../../../styles/themes/themes.scss';
@import '../../../../styles/fonts';

.container {
  flex: 1 1 auto;
  padding-top: 10rem;
  padding-bottom: 170px;
  margin-bottom: 20px;
  
  @include themify-half-color-gradient();

  .title {
    width: 100%;
    @include themify('color', 'text_and_background');
    font-size: 50px;
    font-weight: 300;
    padding: 0 10rem;
    margin-bottom: 20px;
  }
  .subtitle {
    font-family: $font-regular;
    width: 100%;
    @include themify('color', 'text_and_background');
    font-size: 18px;
    padding: 0 10rem;
    margin-bottom: 45px;
    display: block;
  }
  .appointmentsArea {
    padding: 0 10rem;
    .previousAppointments {
      h1 {
        @include themify('color', 'primary-primary');
        font-size: 2rem;
        font-family: $font-light;
        margin-bottom: 10px;
      }
      p {
        width: 100%;
      }
    }
  }

  .Containerbutton {
    display: flex;
    justify-content: center;
    padding-top: 3.2rem;
  }
}

.loading {
  text-align: center;
  margin-bottom: 50px;
  margin-top: 100px;
}

@media (max-width: 990px) {
  .container {
    padding-top: 100px;

    .title {
      padding: 0 3rem;
      font-size: 40px;
    }
    .subtitle {
      padding: 0 3rem;
      font-size: 16px;
    }
    .appointmentsArea {
      padding: 0 3rem;
      .previousAppointments {
        h1 {
          @include themify('color', 'secondary-secondary');
          margin-right: 1rem;
        }

        .p {
          @include themify('color', 'text_active_element');
        }
      }
    }
  }
}
