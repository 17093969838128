@import '../../../../../../../styles/style.scss';

.containerDoctorInfo{
  display: flex;
  align-items: center;
  gap: 20px;


  .doctorName {
    text-align: center;
     font-weight: bold;
    @include themify('color', 'primary-primary');
  }
}
.doctorPhoto {
  max-width: 96px;
  max-height: 96px;
  align-self: center;
  aspect-ratio: 0.75;
}


.title {
  font-size: 16px;
  font-weight: bold;
  font-family: $font-regular;
  @include themify('color', 'primary-primary');
}

