@import '../../styles/fonts';
@import '../../styles/colors';
@import '../../styles/media_queries';
@import '../../styles/themes/themes.scss';

.boxContainer {
  padding-top: 100px;
  @include themify-half-color-gradient();
}

.container {
  position: relative;
  font-family: $font-light;
  min-height: 340px;

  .title {
    width: 100%;
    @include themify('color', 'text_and_background');
    font-size: 50px;
    font-weight: 300;
    padding: 0 9rem;
    margin-bottom: 20px;
  }

  .subtitle {
    font-family: $font-regular;
    width: 100%;
    @include themify('color', 'text_and_background');
    font-size: 18px;
    padding: 0 9rem;
    margin-bottom: 125px;
    display: block;
  }
  .noHaveClaim {
    font-size: 18px;
  }

  .content {
    width: 100%;
    padding: 0 9rem;
    @media(min-width: 1200px) {
      max-width: 1920px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .cotentViewAll {
    display: flex;
    height: 30px;
    justify-content: flex-end;
    align-items: center;
    flex-direction: row;
    padding: 0 9rem;
    margin-top: 100px;

    a {
      @include themify('color', 'primary');
      margin-right: 1rem;
      font-family: $font-regular;
      font-weight: 600;
    }

    .icon {
      height: 16px;
    }
  }
}

@media (max-width: 990px) {
  .container {
    .banner {
      height: 260px;
    }
    .title {
      padding: 0 3rem;
      font-size: 40px;
    }
    .subtitle {
      padding: 0 3rem;
      font-size: 16px;
    }

    .content {
      padding: 0 3rem;
    }

    .cotentViewAll {
      justify-content: center;
      margin-top: 600px;
      padding: 0 3rem;

      a {
        @include themify('color', 'secondary');
        margin-right: 1rem;
      }

      .icon {
        @include themify('color', 'text_active_element');
      }
    }
  }
}
