@import '../../../styles/media_queries';
@import '../../../styles/fonts';
@import '../../../styles/colors';
@import '../../../styles/themes/themes.scss';

.headerProfile {
  display: flex;
  flex: 1;
  height: 200px;
  @include themify('background', 'primary');
  padding: 5rem 9rem;
  align-items: center;

  @media(forced-colors: active){
    border-bottom: 1px solid;
  }

  .buttonProfile {
    width: 98px;
    height: 98px;
    border-radius: 50%;
    @include themify('background', 'primary');
    @include themify('color', 'text_and_background');
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0px;
    border: 1px solid;
    @include themify('border-color', 'text_and_background');
    font-size: 50px;
    margin-right: 20px;
  }

  .welcome {
    display: flex;

    .welcomeTitle {
      @include themify('color', 'text_and_background');
      font-size: 32px;
      font-weight: 300;
      margin-right: 10px;
    }
    .welcomeSubtitle {
      @include themify('color', 'text_and_background');
      font-size: 32px;
      font-weight: 300;
    }
  }
}

@media (max-width: 992px) {
  .headerProfile {
    height: 140px;
    padding: 0 2rem;
    margin-bottom: 15px;

    .buttonProfile {
      width: 50px;
      height: 50px;
      font-size: 24px;
    }

    .welcome {
      flex-direction: column;

      .welcomeTitle {
        font-size: 16px;
      }
      .welcomeSubtitle {
        font-size: 25px;
      }
    }
  }
}
