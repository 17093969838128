@import '../../styles/fonts';
@import '../../styles/colors';
@import '../../styles/media_queries';
@import '../../styles/themes/themes.scss';

.boxContainer {
  padding-top: 100px;
  @include themify-half-color-gradient();
}

.container {
  position: relative;
  font-family: $font-light;
  min-height: 340px;

  .title {
    width: 100%;
    @include themify('color', 'text_and_background');
    font-size: 50px;
    font-weight: 300;
    padding: 0 9rem;
    margin-bottom: 20px;

    @media screen and (max-width: 478px) {
      font-size: 35px;      
    }
  }

  .subtitle {
    font-family: $font-regular;
    width: 100%;
    @include themify('color', 'text_and_background');
    font-size: 18px;
    padding: 0 9rem;
    margin-bottom: 45px;
    display: block;
  }
}
