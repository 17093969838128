@import '../../../styles/style.scss';

.FileCardItem {
  width: 100%;

  .content {
    display: flex;
    align-items: center;
    gap: 16px;
    width: 100%;

    padding: 16px 24px;

    border-radius: 8px;
    border: 1px solid;
    @include themify('border-color', 'const_gray');
    background: $white;

    .fileName {
      flex: 1;

      font-family: $font-regular;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 18px;
      line-height: 150%;
      max-height: calc(18px * 3); // 2 lines
      color: #000;

      @media screen and (max-width: $width-medium) {
        font-size: 16px;
        max-height: calc(16px * 3); // 2 lines
      }

      @media screen and (max-width: $width-small) {
        font-size: 14px;
        max-height: calc(14px * 3); // 2 lines
      }
    }

    .actionsArea {
      display: flex;
      gap: 16px;

      button {
        background: transparent;
        border: none;
      }
    }

    @media screen and (max-width: $width-small) {
      padding: 8px 12px;
      gap: 8px;
    }
  }

  .error {
    font-family: $font-regular;
    color: $text_title_error;
    font-size: 14px;
    line-height: 150%;
  }
}
