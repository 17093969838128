@import '../../styles/style.scss';

/* The container */
.container {
  width: 100%;
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 15px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  @include themify('color', 'subtitle');
}

.colorBlue {
  @media (forced-colors: active) {
    font-weight: bolder;
    font-family: $font-bold;
  }
  
  @include themify('color', 'primary-primary')
}

.labelInnerHTML {
  width: fit-content;
}

.flexInline {
  display: flex;
  flex-direction: row;
}

/* Hide the browser's default radio button */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  border: 1px solid;
  @include themify('border-color', 'subtitle');
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: transparent;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;

  @media (forced-colors: active) {
    border: 10px solid;
  }
}

.container:focus {
  outline: 0;
  span.checkmark {
    border: 3px solid #000;
  }
}

/* Show the indicator (dot/circle) when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container .checkmark:after {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.checkmark {
  @include themify('background-color', 'secondary-secondary', ':after')
}
