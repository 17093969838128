@import '../../../../../../styles/media_queries';

.tooltipEn {
  &:hover {
    &:before {
      background-image: url('../../../../../../assets/images/travelKit/reservationCodeEn.png');
    }
  }
}

.tooltipPt {
  &:hover {
    &:before {
      background-image: url('../../../../../../assets/images/travelKit/reservationCodePt.png');
    }
  }
}

.tooltipEs {
  &:hover {
    &:before {
      background-image: url('../../../../../../assets/images/travelKit/reservationCodeEs.png');
    }
  }
}

.tooltip {
  &:hover {
    &:before {

      background-size: contain;
      background-repeat: no-repeat;
      width: 350px;
      aspect-ratio: 1;
      max-width: 80vw !important;

      @media screen and (max-width: $width-large) {
        left: 0 !important;
      }

      @media screen and (max-width: $width-medium) {
        left: initial !important;
      }
    }
  }
}
