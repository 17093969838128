@import '../../../../styles/style.scss';

.headerContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;

  .HeaderInfo {
    flex-direction: column;
    display: flex;
    flex: 1;
    width: 100%;

    .title {
      display: flex;
      flex: 1;
      width: 100%;
      @include themify('color', 'primary');
      font-size: 2.5rem;
      font-weight: 300;
      margin-bottom: 2rem;
      align-items: center;
    }

    .subtitle {
      display: flex;
      flex: 1;
      width: 100%;
      @include themify('color', 'subtitle');
      font-size: 16px;
      font-weight: 300;
    }
  }

  .headerActions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .right {
      display: flex;
      align-items: center;
      gap: 0.8rem;
    }
  }

  .filterSelected {
    font-weight: bold;
    border-bottom: 1px solid;
    @include themify('color', 'secondary');
    @include themify('border-color', 'text_active_element');
  }
}

@media (max-width: 990px) {
  .headerContainer {
    flex-direction: column;

    .title {
      font-size: 20px;
      margin-bottom: 2rem;
    }

    .headerActions {
      span {
        font-size: 16px;
      }
    }
  }
}
