.content {
  margin-top: 4rem;

  @media screen and (max-width: 500px) {
    margin-left: 2.5%;
    margin-right: 2.5%;
  }
}

.reason_select {
  margin-top: 28px;
  margin-left: 35px;
}

.modal_button {
  margin-top: 4rem;
  margin-right: 2rem;
  width: 200px;

  @media screen and (max-width: 500px) {
    width: 100%;
  }
}