@import '../../../styles/style.scss';

.yourBenefits {
  @include themify('color', 'primary');
  h2 {
    @include themify('color', 'primary');
    font-size: 33px;
    margin-bottom: 18px;
  }
}

.emptyBenefits {
  padding: 0 30px;
}

.loadingContainer {
  display: flex;
  justify-content: center;
}
