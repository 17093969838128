@import '../../../../../styles/colors';
@import '../../../../../styles/_fonts.scss';
@import '../../../../../styles/media_queries';

.card {
  margin: 0 10px 1.5rem;
  max-width: 190px;

  img {
    width: 100%;
    max-height: 120px;
  }
  .detail {
    .title {
      color: $dark-blue;
      font-size: 2rem;
      font-weight: 500;
      margin: 1rem 0 0.4rem 0;
    }
    .subtitle {
      font-size: 13px;
    }
  }
}

@media (max-width: 992px) {
  .card {
    max-width: 150px;

    .detail {
      .title {
        font-size: 16px;
      }
    }
  }
}

@media (max-width: $width-large) {
  .card {
    margin: 0 6px 1.5rem;

    img {
      max-width: 160px;
      max-height: 100px;
    }
  }
}

@media (max-width: 360px) {
  .card {
    margin: 0 2px 1.5rem;

    img {
      max-width: 150px;
      max-height: 90px;
    }
  }
}
