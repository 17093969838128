@import '../../../../../../../../styles/colors';
@import '../../../../../../../../styles/themes/themes.scss';

.personalArea {
  .fontGray12 {
    @include themify('color', 'const_gray');
    font-size: 14px;
  }
  .none {
    display: none;
  }
  .option {
    label {
      margin-bottom: 0px;
    }
  }
}

.buttonArea {
  display: flex;
  justify-content: flex-end;
  button {
    margin-right: -8px;
  }
}

.footer {
  padding-left: 15px;
  display: flex;
  align-items: center;
  padding: 0 !important;
}
