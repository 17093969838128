@import '../../../../../../../styles/colors';
@import '../../../../../../../styles/fonts';
@import '../../../../../../../styles/themes/themes.scss';

.notes {
    margin-bottom: 34px;
    width: 60%;
}
.labelNotes {
    font-family: $font-light;
    font-size: 12px;
    margin-bottom: 0;
}

.topMargin {
    margin-top: 5px;
}