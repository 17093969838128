@import '../../styles/style.scss';

.badgeContainer{
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  width: 100%;
  padding: 8px 16px;

  &.default{
    @include themify('background-color', 'primary-primary');
    @include themify('color', 'primary-inverse');
  }
  &.secondary{
    @include themify('background-color', 'disabled-surface');
    @include themify('color', 'disabled-text');
  }
  &.negative{
    @include themify('background-color', 'negative-surface');
    @include themify('color', 'negative-text');
  }

  .badgeText{
    font-family: $font-semibold;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
  }
}
