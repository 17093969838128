@import '../../styles/colors';
@import '../../styles/fonts';
@import '../../styles/themes/themes.scss';

.Select {
  margin-top: 22px;
  margin-bottom: 22px;
  position: relative;
  font-family: $font-light;
  width: 100%;

  select {
    @include themify('color', 'text-paragraph_subtle');
    border: none;
    border-bottom: 1px solid;
    @include themify('border-color', 'text-paragraph_subtle');
    width: 100%;
    background: transparent;
    font-size: 1.6rem;
    appearance: none;
    height: 35px;
    outline: none;
    
    &.value {
      @include themify('color', 'primary-primary', '', true)
    }

    &:active, &:focus {
      @include themify('border-color', 'primary-primary');
      border-bottom-width: 2px;
    }

    &:disabled {
      cursor: not-allowed;
    }
  }

  .groupText {
    position: absolute;
    right: 26px;
    top: 4px;
    padding: 0;

    span:hover:before {
      right: -26px !important;
    }
  }
}

.error {
  border-bottom-color: $error-red-alert !important;
}

.errorLabel {
  font-size: 0.75em;
  color: $error-red-alert;
}

.label {
  position: absolute;
  top: -16px;
  font-size: 12px;
  font-weight: bold;
  font-family: $font-regular;
  @include themify('color', 'primary-primary');
}

.labelAlignedToRight {
  position: absolute;
  top: -16px;
  right: 0;
  font-size: 12px;
  font-weight: bold;
  font-family: $font-regular;
  @include themify('color', 'primary-primary');
}

.defaultStyle {
  @include themify('background-color', 'surface-surface');
  padding-left: 12px;
  appearance: none;
}

.selectHideArrow {
  select::-ms-expand {
    display: none;
  }
}

.shouldBeSmaller {
  @media (max-width: 1415px) {
    height: auto;
    font-size: 14px !important;
    white-space: pre-line;
  }
}

.chevronIcon {
  position: absolute;
  top: 10px;
  right: 0;
  pointer-events: none;
}
