@import '../../../../styles/fonts';
@import '../../../../styles/colors';
@import '../../../../styles/media_queries';
@import '../../../../styles/themes/themes.scss';

.certificates {
  margin-bottom: 4rem;

  @media (min-width: 990px) {
    padding-right: 1.5% !important;
  }

  .generate {
    margin-bottom: 4rem;
  }

  .counter {
    position: relative;
    @include themify('background', 'text_and_background');
    border-radius: 3px;
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1);
    margin-bottom: 4rem;
    min-height: 288px;
    padding: 3rem 0;
    width: 47%;

    @media (forced-colors: active) {
      border: 1px solid;
    }

    &.marginLeft {
      margin-left: 6%;
    }

    span {
      padding-left: 9%;
    }

    p,
    .length {
      @include themify('color', 'primary');
      font-size: 60px;
      padding-left: 8%;
    }

    h6 {
      @include themify('color', 'subtitle');
      font-size: 25px;
      line-height: 33px;
      padding-left: 9%;
      padding-right: 9%;
    }

    .icon {
      width: 35px;
      height: 35px;
      display: block;
    }

    a {
      position: absolute;
      bottom: 30px;
      margin-left: 9%;
    }
  }

  @media (max-width: $width-medium) {
    .counter {
      @include themify('background', 'text_and_background');
      border-radius: 3px;
      box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1);
      display: flex;
      justify-content: space-between;
      margin-bottom: 5%;
      min-height: 168px;
      padding: 4% 0;
      width: 100%;

      p,
      .length {
        font-size: 40px;
        line-height: 1;
      }

      .right {
        order: 2;
        padding-top: 2%;
        width: 8%;

        .icon {
          width: 26px;
          height: 26px;
        }
      }

      .left {
        padding-top: 2%;
        margin-left: 6%;
        order: 0;
        width: 15%;
      }

      .center {
        order: 1;
        padding-top: 2%;
        width: 50%;

        h6 {
          padding: 0;
        }
        a {
          margin: 0;
        }
      }

      &.marginLeft {
        margin-left: 0;
      }

      span {
        padding-left: 2%;
      }

      h6 {
        padding-left: 4%;
        padding-right: 4%;
      }
    }
  }

  @media (max-width: 650px) {
    .counter {
      .right {
        margin-right: 5% !important;
      }
    }
  }

  @media (max-width: 1117px) {
    .counter {
      h6 {
        font-size: 20px;
      }
    }
  }

  @media (max-width: $width-large) {
    .counter {
      .right {
        margin-right: 1%;
      }
    }
  }
}
