@import '../../../../../../../styles/colors';
@import '../../../../../../../styles/fonts';
@import '../../../../../../../styles/themes/themes.scss';

.title {
  color: #000;
  font-size: 1.4rem;
  font-weight: 300;
  margin-bottom: 0;
}

.radioButtonError {
  .error {
    border-bottom-color: $error-red-alert;
    &:focus {
      border-bottom-color: $error-red-alert;
    }
  }
}

.elementRadio {
  @include themify('color', 'subtitle');
  font-size: 14px;
  .labelTitle {
    display: flex;
    margin-bottom: 15px;
    font-size: 16px;
    font-family: $font-regular;
    @include themify('color', 'link');
  }
}

.labelTitle {
  margin-bottom: 24px;
  font-weight: 300;
  font-size: 16px;
  @include themify('color', 'subtitle');
}

.noPadding {
  padding: 0px !important;
}

.none {
  display: none;
}
