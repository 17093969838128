@import '../../styles/colors';
@import '../../styles/media_queries';
@import '../../styles/fonts';
@import '../../styles/themes/themes.scss';

.containerBenefitsDetail {
  .boxContainer {
    position: relative;
    bottom: 100px;

    @media (min-width: 1200px) {
      max-width: 1760px;
      margin: auto;
    }

    .infoContainer {
      max-width: 65%;
      padding: 200px 90px 30px;
      @include themify('color', 'link');
      .short_description {
        margin-bottom: 60px;
        font-size: 25px;
        line-height: 35px;
      }
      .description {
        font-size: 18px;
        margin-bottom: 50px;
        line-height: 1.5;
        h3 {
          font-size: 26px;
          margin-bottom: 20px;
        }
        ul {
          list-style-type: none !important;
          li {
            border-left: 1px solid;
            padding-left: 20px;
          }
          li + li {
            margin-top: 20px;
          }
        }
        hr {
          margin: 30px 0;
        }
      }
      .terms {
        font-size: 14px;
        margin-top: 10px;
        font-style: italic;
        color: #6b6b6b;
        p {
          line-height: 1.5;
          margin-bottom: 2rem;
        }
      }
      .linkArea {
        width: 100%;
        text-align: center;
        margin-top: 60px;

        display: flex;
        flex-direction: column;
        gap: 8px;
      }

      .loadingContainer {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .infoContainerWithTabBar {
      padding-top: 140px;
    }
  }
  .boxPage {
    padding: 0 9rem;

    .marginTop{
      margin-top: 60px;
    }

    @media (min-width: 1200px) {
      max-width: 1760px;
      margin: auto;
    }
  }
}

@media (max-width: 1200px) {
  .containerBenefitsDetail {
    .boxContainer {
      .infoContainer {
        max-width: none;
        padding: 400px 90px 30px;
      }

      .infoContainerWithTabBar {
        padding-top: 340px;
      }
    }
  }
}

@media (max-width: 992px) {
  .containerBenefitsDetail {
    .boxContainer {
      .infoContainer {
        max-width: none;
        padding: 400px 20px 30px;
      }

      .infoContainerWithTabBar {
        padding-top: 340px;
      }
    }
  }
}

@media screen and (max-width: $width-large) {
  .containerBenefitsDetail {
    .boxPage {
      padding: 0 2rem;
    }
  }
}

@media screen and (max-width: $width-medium) {
  .containerBenefitsDetail {
    .boxContainer {
      position: static;
    }
  }
}

@media (max-width: 580px) {
  .containerBenefitsDetail {
    .boxContainer {
      .infoContainer {
        max-width: none;
        padding: 50px 20px 30px;
      }

      .infoContainerWithTabBar {
        padding-top: 10px;
      }
    }
  }
}

.newVersionDetail.containerBenefitsDetail {
  .boxContainer {
    display: flex;
    flex-direction: row-reverse;
    padding: 0 90px;

    .blueBoxContent {
      margin-top: -360px;
      z-index: 99;
    }
    .infoContainer {
      max-width: none;
      flex: 1;
      padding: 200px 90px 30px 0px;
    }

    .infoContainerWithTabBar {
      padding-top: 140px;
    }
  }

  @media (max-width: 1760px) {
    .boxContainer {
      .blueBoxContent {
        width: 30%;
      }
    }
  }

  @media (max-width: 1300px) {
    .boxContainer {
      flex-direction: column;
      margin-top: 0;
      bottom: 0;

      .blueBoxContent {
        width: 100%;
        margin-top: 0;
      }

      .infoContainer {
        padding-top: 100px;
        padding-right: 0;
      }

      .infoContainerWithTabBar {
        padding-top: 40px;
      }
    }
  }

  @media (max-width: 992px) {
    .boxContainer {
      padding: 0 20px;
    }
  }
}
.disclaimerContainer {
  padding-top: 20px;
  .disclaimer {
    font-size: 14px;
    font-style: italic;
    color: #6b6b6b;
    p {
      line-height: 1.5;
      margin-bottom: 2rem;
    }
  }
}

.center {
  text-align: center !important;
}

.fakeLink{
  @include themify('color', 'link');
}
