@import '../../../../../../../../styles/colors';
@import '../../../../../../../../styles/themes/themes.scss';
@import '../../../../../../../../styles/fonts';

.personalArea {
  .labelTitle {
    @include themify('color', 'tertiary');
    font-size: 12px;
    font-weight: 400;
  }
  .none {
    display: none;
  }
  
  .elementRadio {
    @include themify('color', 'subtitle');
    font-size: 14px;
    .labelTitle {
      display: flex;
      margin-bottom: 15px;
      font-size: 16px;
      font-family: $font-regular;
      @include themify('color', 'link');
    }
  }
}
.errorLabel {
  font-size: 0.75em;
  color: $error-red-alert;
}
