@import '../../styles/themes/themes.scss';
@import '../../styles/colors';
@import '../../styles/fonts';

.container {
  @include themify-half-color-gradient();

  .loadingContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
  }
}

.content {
  @media (max-width: 520px) {
    padding-top: 40px;
  }

  @media(forced-colors: active){
    border: 1px solid;
  }

  .box {
    @include themify('background', 'text_and_background');
    border-radius: 5px;
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1);
    padding: 40px;
    width: 100%;
    margin-bottom: 30px;
    z-index: 1;

    @media (max-width: 992px) {
      padding: 20px;
    }
  }
}

.title {
  font-size: 2.6rem;
  font-family: $font-regular;
  color: get-theme-color('axa', 'primary-primary');
  margin-bottom: 30px;
}

.productNotFound {
  font-size: 2.4rem;
  text-align: center;
  margin: 1rem 0px;
  color: get-theme-color('axa', 'primary-primary');
}
