@import '../../styles/fonts';
@import '../../styles/colors';
@import '../../styles/media_queries';
@import '../../styles/themes/themes.scss';

.container {
  position: relative;

  .title {
    width: 100%;
    @include themify('color', 'text_and_background');
    font-size: 50px;
    font-weight: 300;
    padding: 0 9rem;
    margin-bottom: 20px;
  }

  .subtitle {
    font-family: $font-regular;
    width: 100%;
    @include themify('color', 'text_and_background');
    font-size: 18px;
    padding: 0 9rem;
    margin-bottom: 45px;
    display: block;
  }

  .carouselContent {
    top: 380px;
    width: 100%;
    height: 270px;
    padding: 0 9rem;
  }

  .carouselContentSimpleBanner {
    width: 100%;
  }

  .subtitleSimpleBanner {
    width: 100%;
    @include themify('color', 'primary');
    font-size: 3.3rem;
  }

  .bannerSubContainner {
    display: flex;
    flex-direction: row;
    span {
      flex: 1;
      }
    @media (max-width: 992px) {
      flex-direction: column !important;
    }
    .titleContainer{
      display: flex;
      justify-content: flex-end;
      align-items: baseline;
      margin: 3.2rem 0;
      
      @media (max-width: $width-large) {  
        margin: 30px 0 !important;
        z-index: 1;
        position: absolute;
        bottom: -75px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  .loadingContainer {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  @media(min-width: 1200px) {
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 992px) {
  .container {
    .title {
      top: 80px;
      padding: 0 2rem;
      font-size: 40px;
    }

    .subtitle {
      top: 280px;
      padding: 0 2rem;
    }

    .carouselContent {
      padding: 0 2rem;
    }

    .subtitleSimpleBanner {
      font-size: 33px;
    }
  }
}

.sliderBenefits :global(.slick-list) {
  padding: 25px 0px;
}

@media (max-width: 500px) {
      .icon {
        display: none;
      }
}
