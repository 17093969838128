@import '../../../../styles/style.scss';

.itemContainer {
  .header {
    margin-bottom: 25px;
    h3 {
      @include themify('color', 'primary');
      font-size: 25px;
      font-family: $font-semibold;
    }
    p {
      font-size: 18px;
      font-family: $font-regular;
    }
  }

  .content {
    display: flex;

    .imageArea {
      flex: 1;
      margin-top: 50px;

      img {
        height: 180px;
      }
    }
    .benefitsArea {
      flex: 3;

      list-style-type: none;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
    }
  }
}

@media (max-width: 1150px) {
  .itemContainer {
    .header {
      padding-left: 0 !important;
      text-align: center;
    }

    .content {
      .imageArea {
        flex: 0;
        display: flex;
        align-items: center;
        margin-top: 0;
        margin-right: 20px;
      }
      .benefitsArea {
        flex: 1;
      }
    }
  }
}

@media(max-width: 992px){
  .itemContainer{
    .content{
      .imageArea{
        display: none;
      }
      .benefitsArea{
        justify-content: center;
      }
    }
  }
}
