@import '../../../../../../../../../styles/themes/themes.scss';
@import '../../../../../../../../../styles/fonts';
@import '../../../../../../../../../styles/colors';

.container {
  cursor: pointer;
  width: 100%;
  margin: 1rem 0;
  padding: 2rem;
  height: auto;
  border: 2px solid;
  @include themify('border-color', 'primary-primary');
  @include themify('background-color', 'surface-surface');
  
  &:hover:not(.disabled) {
    @include themify('background-color', 'surface-hover');
  }

  &:active:not(.disabled) {
    @include themify('background-color', 'surface-pressed');
  }

  .title {
    font-size: 18px;
    margin-bottom: 1.6rem;
    font-weight: 600;
    text-align: left;
    @include themify('color', 'primary-primary');
  }
  
  .text {
    margin-bottom: 0.2rem;
    text-align: left;
    @include themify('color', 'primary-primary');
    font-weight: 600;
  }

  &.disabled {
    cursor: default;
    background-color: transparent;
    border-color: transparent;

    padding: 0;

    .title {
      font-weight: 600;
    }

    .text {
      color: black;
      font-weight: 400;
    }
  }
}

.airportContainer {
  flex: 1;
}

.chevronIcon {
  align-self: center;
  margin: 0 3rem;
}

.title {
  font-size: 18px;
  margin-bottom: 1.6rem;
  font-weight: 600;
  text-align: left;
  @include themify('color', 'primary-primary');
}

.text {
  margin-bottom: 0.2rem;
  text-align: left;
  @include themify('color', 'primary-primary');
  font-weight: 600;
}