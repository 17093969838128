@import '../../styles/themes/themes.scss';
@import '../../styles/colors';
@import '../../styles/fonts';
@import '../../styles/media_queries';

.menuList {
  height: fit-content;
  position: sticky;
  top: 30px;
  overflow: auto;
  padding-top: 5rem;
  padding-right: 5rem;
  @media (max-width: $width-large) {
    position: static;
    padding-top: 0;
    padding-right: 0;
  }

  ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    width: 100%;

    li {
      display: flex;
      padding-bottom: 15px;
      flex-direction: column;
      @include themify('color', 'primary');
    }
    li.pointer {
      cursor: pointer;
    }

    li.pointer:hover {
      text-decoration: underline;
    }
  }
}
.faqDiv {
  flex: 1;
  h2 {
    margin-top: 30px;
  }
}
.faqContainer {
  display: flex;

  @media (max-width: $width-large) {
    flex-direction: column;
  }
}
.selected {
  font-weight: bold !important;
  cursor: pointer;
}
