@import '../../../../styles/style.scss';

.recentClaimsItem {
  display: flex;
  @include themify('background', 'surface-surface');
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  border-radius: 3px;
  margin-bottom: 15px;
  padding: 30px 21px;
  flex-direction: column;

  @media screen and (min-width: 1300px) {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 20px;
  }

  .left {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 15px;
    @media screen and (min-width: 1300px) {
      width: 35%;
      flex-direction: row;
      justify-content: flex-start;
      margin-bottom: 0;
    }

    .icon {
      order: 2;
      @media screen and (min-width: 1300px) {
        order: 1;
      }
    }
    .text {
      @include themify('color', 'text-paragraph_subtle');
      display: flex;
      font-size: 16px;
      flex-direction: column;
      padding-right: 10px;
      justify-content: center;

      order: 1;
      @media screen and (min-width: 1300px) {
        order: 2;
        padding-left: 4%;
        padding-right: 4%;
      }

      span {
        font-family: $font-semibold;
        font-weight: 600;
        line-height: 30px;
        word-break: break-word;
      }
    }
  }

  .center {
    display: flex;
    flex-direction: column;
    width: 100%;
    @media screen and (min-width: 1300px) {
      width: 30%;
    }

    span {
      line-height: 30px;

      strong {
        font-family: $font-semibold;
        font-weight: 600;
      }
    }
  }

  .right {
    display: flex;
    padding-top: 15px;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    @media screen and (min-width: 1300px) {
      width: 35%;
      align-items: center;
      justify-content: flex-end;
      height: 40px;
      flex-wrap: nowrap;
      padding-top: 0;
    }

    @media screen and (max-width: 360px) {
      flex-direction: column;
    }
  }
}

.button {
  width: 100%;

  @media screen and (min-width: 1300px) {
    width: 120px;
  }
}
