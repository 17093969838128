@import '../../../styles/colors';
@import '../../../styles/themes/themes.scss';

.background {
  background: $white;
  opacity: 0.8;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

.boxError {
  padding: 20px 0;
  position: relative;
  z-index: 10;
  .error {
    width: 100%;
    height: 100%;
    max-width: 1240px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    h2 {
      @include themify('color', 'primary');
    }

    .footer {
      margin-top: 20px;
      max-width: 200px;
      button {
        text-align: left;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .boxError {
    position: fixed;
    padding: 0 20px;

    .error {
      .footer {
        max-width: none;
        button {
          text-align: center;
        }
      }
    }
  }
}
