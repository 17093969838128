@import '../../../../../styles/style.scss';

.voucherBox {
  display: flex;
  padding: 40px 25px;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.2);
  flex-basis: 48%;
  margin-bottom: 2%;

  main {
    flex: 1;
    padding: 0 20px;

    .title {
      @include themify('color', 'primary-primary');
      font-size: 24px;
      font-family: $font-regular;
      margin-bottom: 40px;
    }

    p {
      @include themify('color', 'text-paragraph_subtle');
      margin-bottom: 0;
    }

    span{
      font-size: 14px;
    }
  }
}

@media(max-width: $width-extra-large){
  .voucherBox{
    flex-basis: 100%;
    margin-bottom: 4%;
  }
}
