@import '../../../../../../../../styles/colors';
@import '../../../../../../../../styles/themes/themes.scss';
@import '../../../../../../../../styles/fonts';

.addicionalPassagers {
  margin-bottom: 0.7rem;

  .itemPassenger {
    .footer {
      display: flex;
      button {
        margin-left: -8px;
        margin-bottom: 24px;
      }
    }
  }
  p {
    @include themify('color', 'const_gray');
  }
  .add {
    display: flex;
    justify-content: flex-end;
    button {
      margin-right: -8px;
    }
  }

  .elementRadio {
    @include themify('color', 'subtitle');
    font-size: 14px;
    .labelTitle {
      display: flex;
      margin-bottom: 15px;
      font-size: 16px;
      font-family: $font-regular;
      @include themify('color', 'link');
    }
  }

  .none {
    display: none;
  }
}
