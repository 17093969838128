@import '../../../../../../../styles/colors';
@import '../../../../../../../styles/fonts';
@import '../../../../../../../styles/themes/themes.scss';

.incidentArea {
  .none {
    display: none;
  }
  margin-bottom: 6px;

  .incidentPeriodArea {
    margin-top: 0.7rem;
    .marginBottom {
      margin-bottom: 25px;
    }
  }
}

.elementRadio {
  @include themify('color', 'tertiary');
  font-size: 14px;
  .labelTitle {
    @include themify('color', 'primary');
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 15px;
    font-family: $font-regular;
  }
}

.noPadding {
  padding: 0 !important;
}

.radioButtonError {
  .error {
    border-bottom-color: $error-red-alert;
    &:focus {
      border-bottom-color: $error-red-alert;
    }
  }
  .errorLabel {
    font-size: 0.75em;
    color: $error-red-alert;
    font-family: $font-regular;
  }
}

.checkBox {
  margin: 0px;
}

.labelTitleCheckbox {
  font-size: 16px;
  @include themify('color', 'subtitle');
}

.disclaimerCheckbox {
  font-size: 16px;
  @include themify('color', 'subtitle');
  margin-bottom: 24px;
}

.noTopMargin{
  margin-top: 0px;
}
