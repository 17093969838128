@import '../../../../styles/style.scss';

.boxProductCategory{
  width: 100%;
  margin-bottom: 15px;
  ul {
    display: flex;
    list-style: none;
    li{
      margin-right: 30px;
      @include themify('color', 'subtitle');
      &.active {
        font-weight: 600;
        font-family: $font-regular;
        border-bottom: 1px solid;
        @include themify('border-bottom-color', 'secondary-secondary');
      }
      &:hover {
        cursor: pointer;
        border-bottom: 1px solid;
        @include themify('border-bottom-color', 'secondary-secondary');
      }
    }
  }
}
