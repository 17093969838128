.showOnMobile {
  display: none;
}

.modal-dialog {
  width: 100% !important;
  max-width: 1440px !important;
}

.noPaddingLeftRight {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

@media screen and (max-width: 992px) {
  .hideOnMobile {
    display: none;
  }

  .showOnMobile {
    display: inline-block;
    margin-left: 16px;
  }
}

.fb_dialog_advanced {
  z-index: 900 !important;
}
.fb_dialog_content iframe {
  bottom: 42px !important;
  right: 100px !important;
  z-index: 500 !important;
}

.Toastify__toast-container {
  top: 80px !important;
  z-index: 999 !important;
  font-size: 14px;
  width: 360px !important;

  --toastify-color-warning: #fa9b00;
  --toastify-color-info: #2684ff;
  --toastify-icon-color-info: #fff;
}

.notClosable .Toastify__toast-container {
  z-index: 998 !important;
  position: fixed !important;
  width: 100% !important;
}

@media (max-width: 480px) {
  .notClosable .Toastify__toast-container {
    position: static !important;
  }
  .Toastify__toast-container {
    width: 100% !important;
  }
}

.Toastify__toast {
  font-family: 'visa_dialect_light', Helvetica, 'Helvetica Neue' !important;
  padding: 0 !important;
}

@media (forced-colors: active) {
  .Toastify__toast.Toastify__toast--info,
  .Toastify__toast.Toastify__toast--info.gold {
    background: #000 !important;
  }
}

.Toastify__toast--info {
  background: linear-gradient(
    90deg,
    #2684ff 0,
    #2684ff 32px,
    white 32px,
    white 100%
  ) !important;
}

.Toastify__toast--info.colored {
  background: #2684ff !important;
  color: #fff !important;
}

.Toastify__toast--info.gold {
  background: linear-gradient(
    90deg,
    #021E4C 0,
    #021E4C 32px,
    white 32px,
    white 100%
  ) !important;
}

.Toastify__toast--info.gold a {
  color: #021E4C;
}

.Toastify__toast--info .Toastify__toast-icon {
  width: 15px !important;
  margin-inline-end: 20px !important;
  margin-inline-start: 2px;
  background: #2684ff;
}

.Toastify__toast--info.gold .Toastify__toast-icon {
  width: 15px !important;
  margin-inline-end: 20px !important;
  margin-inline-start: 2px;
  background: #021E4C;
}

.Toastify__toast--warning .Toastify__toast-icon {
  background: #fa9b00;
}

.Toastify__toast-body {
  align-items: baseline !important;
}

.Toastify__close-button {
  margin: 5px 8px 0 8px;
}

.embeddedServiceSidebar * {
  font-family: 'visa_dialect_regular', sans-serif !important;
  font-size: 100% !important
}

.embeddedServiceSidebar.modalContainer {
  font-family: 'visa_dialect_regular', sans-serif !important;
}

.embeddedServiceHelpButton,
.embeddedServiceSidebar.modalContainer {
  z-index: 99999 !important;
}

.embeddedServiceHelpButton .helpButton .uiButton {
  background-color: #19236d !important;
  font-family: 'visa_dialect_regular', sans-serif !important;
}
.embeddedServiceHelpButton .helpButton .uiButton:focus {
  outline: 1px solid #19236d !important;
}
.previews img {
  max-width: 100%;
  border-radius: 10px;
}
.chat-content a {
  color: #fff;
}

mark {
  background-color: #ffef99 !important;
}

@media screen and (max-width: 992px) {
  .embeddedServiceHelpButton .helpButton {
    bottom: 50px !important;
  }
}
