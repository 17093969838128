@import '../../styles/style.scss';

.listCountries {
  list-style: none;
  li {
    margin-bottom: 10px;
    margin-right: 40px;
    font-size: 1.5rem;
    @include themify('color', 'primary');
    &:not(.countrySelected) {
      cursor: pointer;
    }
    &:hover,
    &.countrySelected {
      @include themify('color', 'secondary');
      font-weight: bold;
    }
    .buttonItem {
      background: transparent;
      border: none;
      text-align: left;
    }
  }
}

.borderLeft {
  border-left: 1px solid;
  @include themify('border-left-color', 'tertiary');
  padding-left: 3rem;
}

.subtitle {
  font-family: $font-regular;
  font-weight: bold;
  font-size: 2rem;
  margin-bottom: 20px;
  font-size: 1.5rem;
  @include themify('color', 'primary');
}
