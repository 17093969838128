@import '../../../../styles/themes/themes.scss';
@import '../../../../styles/fonts';

.itemCarousel {
  max-width: none !important;
  min-width: auto !important;
  width: auto !important;
  margin: 0;
}

:global(.slick-slide:first-child) {
  .imageArea,
  .short_description {
    margin-left: 0;
  }
}

:global(.slick-slide:last-child) {
  .imageArea,
  .short_description {
    margin-left: 20px;
    margin-right: 0;
  }
}

:global(.slick-slide:only-child) {
  .imageArea,
  .short_description {
    margin-left: 0;
    margin-right: 20px;
  }
}

.itemBenefit {
  width: 460px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 20px 60px;

  .imageArea {
    background-size: cover;
    background-position: center;
    display: flex;
    width: 460px;
    height: 368px;

    margin: 0 10px;
    max-width: 432px;
    position: relative;

    .imageInfo {
      position: absolute;
      top: 10px;
      right: 10px;
      display: flex;
      height: 30%;
      width: auto;
    }
  }
  .short_description {
    width: 100%;
    max-width: 432px;
    margin: 4% 10px 20px;

    @media(max-width:386px){
      height: 150px;
    }
    p {
      @include themify('color', 'subtitle');
      font-size: 16px;
      font-family: $font-regular;
      font-weight: 600;
      margin-bottom: 20px;
    }
    span {
      @include themify('color', 'subtitle');
      font-size: 22px;
      font-family: $font-regular;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
}

@media (max-width: 500px) {
  .itemBenefit {
    width: 300px;

    .imageArea {
      width: 300px;
      height: 240px;
    }
  }
}

@media (max-width: 350px) {
  .itemBenefit {
    width: 240px;

    .imageArea {
      width: 240px;
      height: 200px;
    }
  }
}
