.container {
  width: 100%;
  height: 100%;
  // overflow-y: auto;
}

.header {
  width: 100%;
}

.alertArea {
  width: 100%;
}