@import '../../../styles/colors';
@import '../../../styles/themes/themes.scss';

.container {
  &.theme-master {
    background: linear-gradient(
      to bottom,
      $primary-master 0%,
      $primary-master 500px,
      white 500px,
      white 100%
    );
  }
  &.theme-afluent {
    background: linear-gradient(
      to bottom,
      $primary-afluent 0%,
      $primary-afluent 500px,
      white 500px,
      white 100%
    );
  }
  &.theme-portoBank {
    background: linear-gradient(
      to bottom,
      $primary-portoBank 0%,
      $primary-portoBank 500px,
      white 500px,
      white 100%
    );
  }

  .loadingContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 400px;
  }
}
