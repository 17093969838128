@import '../../../../../styles/fonts';
@import '../../../../../styles/themes/themes.scss';

.labelTitle {
  font-family: $font-regular;
  font-size: 16px;
  color: get-theme-color('axa', 'primary-primary');
  margin-bottom: 20px;
}

.labelErrorGroup {
  span {
    font-size: 0.75em;
    color: #ff4040;
  }
}

.fields {
  width: 100%;
  padding: 0 15px;
  display: flex;

  @media(max-width: 576px){
    flex-direction: column;
  }

  .wrapInput{
    margin-right: 100px;
    width: 250px;
    @media(max-width: 576px){
      margin-right: 0;
      width: 100%;
      & + .wrapInput{
        margin-top: 30px;
      }
    }
  }
}
