@import '../../../../styles/colors';
@import '../../../../styles/fonts';
@import '../../../../styles/media_queries';
@import '../../../../styles/themes/themes.scss';

.container {
  display: flex;
  flex: 1;
  justify-content: space-around;
  min-height: 25rem;
  flex-direction: column;
  padding: 3rem 1rem 3rem 1rem;

  &:not(:first-child) {
    border-top: 1px solid;
    @include themify('border-color', 'secondary-secondary');
  }

  h2 {
    @include themify('color', 'primary-primary');
    font-style: normal;
    font-size: 2.8rem;
    margin-bottom: 2.5rem;
  }

  .text {
    @include themify('color', 'text-paragraph_subtle');
    font-style: normal;
    font-size: 1.8rem;
    margin-bottom: 2rem;
  }

  .button {
    margin-left: -1rem;
  }
}

@media (max-width: $width-small) {
  .container {
    padding: 3rem 1rem 3rem 1rem;

    h2 {
      font-size: 2.8rem;
      margin-bottom: 2.5rem;
    }

    .text {
      font-size: 1.8rem;
      margin-bottom: 2rem;
    }
  }
}
