@import "../../../styles/themes/themes.scss";
@import "../../../styles/fonts";

.flightHeader {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
}

.leftContainer {
  display: flex;
  align-items: center;
  flex: 1;
}

.rightContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.realTimeDataDisclaimer {
  font-family: $font-regular;
  font-weight: normal;
  @include themify('color', 'text-paragraph_subtle');
}

.textContainer:not(:first-child) {
  margin-left: 2rem;
}

.text {
  font-size: 20px;
  font-family: $font-regular;
  font-weight: normal;
  margin-bottom: 0;
  @include themify('color', 'primary-primary');
}

.green {
  @include themify('color', 'positive-text');
}