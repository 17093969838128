@import '../../../../styles/fonts';
@import '../../../../styles/colors';
@import '../../../../styles/media_queries';
@import '../../../../styles/themes/themes.scss';

.generate {

  @media (forced-colors: active) {
    border: 1px solid;
  }

  a {
    align-items: flex-end;
    @include themify('color', 'text_and_background');
    display: flex;
    font-size: 25px;

    text-decoration: none !important;
    width: 100%;
    border-radius: 3px;
    height: 85px;
    align-items: center;

    &.blue {
      @include themify('background', 'primary');
      @include themify('color', 'text_and_background');

      &:hover,
      &:focus {
        font-weight: bold;
        font-family: $font-regular;
      }

      .iconArrow {
        @include themify('stroke', 'secondary-inverse');
      }
    }

    &.white {
      @include themify('background', 'text_and_background');
      @include themify('color', 'subtitle');

      &:hover,
      &:focus {
        font-weight: bold;
        font-family: $font-regular;
      }

      .iconArrow {
        @include themify('stroke', 'secondary-secondary');
      }
    }

    .icon {
      width: 90%;
      margin: 0 2% 0 3%;

      svg {
        margin-right: 2%;
        margin-top: -2px;
      }
    }
    .iconArrow {
      margin-right: 2%;
    }
  }

  .separator {
    @include themify('background', 'tertiary');
    height: 1px;
    margin-bottom: 2%;
    width: 100%;

    &.none {
      display: none;
    }
  }

  @media (max-width: $width-medium) {
    a {
      font-size: 18px;
      height: 65px;
      .icon {
        img {
          margin-right: 6%;
          margin-left: 4%;
        }
      }
      .iconArrow {
        margin-right: 5%;
      }
    }
  }
}
