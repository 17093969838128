@import '../../../../styles/style.scss';

.voucher {
  @include themify('background', 'primary-primary');
  padding: 10px 20px;
  width: fit-content;
  margin-top: 30px;
  .number {
    color: #fff;
    font-size: 30px;
    font-family: $font-semibold;
  }
}
