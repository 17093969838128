@import '../../../styles/style.scss';

.footerContainer {
  @include themify-half-color-gradient(100px, "surface-surface", "surface-inverse");
}

.footerContainer {
  color: $white;

  margin-top: 100px;
  width: 100%;

  display: flex;

  @media (forced-colors: active) {
    border: 1px solid;
    margin-bottom: 10px;
  }

  .textArea {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    padding: 150px 45px 50px 90px;
    text-align: center;

    h2 {
      font-family: $font-semibold;
      font-size: 34px;
    }

    p {
      font-family: $font-regular;
    }

    .imagesInline {
      display: flex;
      justify-content: center;

      img {
        width: 160px;
        height: 52px;
        margin: 0 10px;
      }
    }
    @media (min-width: 2500px) {
      flex: 0.7;
    }
  }

  .imageArea {
    img {
      max-height: 440px;
      height: 100%;
      max-width: 490px;
    }
  }

  @media (min-width: 2500px) {
    justify-content: center;
  }
}

@media (max-width: 1300px) {
  .footerContainer {
    margin-bottom: 100px;
  }
}

@media (max-width: 1150px) {
  .footerContainer {
    margin-top: 100px;

    flex-direction: column-reverse;
    align-items: center;

    .textArea {
      padding: 50px;

      h2 {
        font-size: 25px;
      }
    }

    .imageArea {
      img {
        height: 320px;
      }
    }
  }
}

@media (max-width: 500px) {
  .footerContainer {
    .textArea {
      padding: 20px;

      .imagesInline {
        img {
          width: 40%;
          min-width: 100px;
          object-fit: contain;
        }
      }
    }

    .imageArea {
      img {
        height: 260px;
      }
    }
  }
}
