@import '../../../styles/_colors.scss';
@import '../../../styles/fonts';
@import '../../../styles/themes/themes.scss';

.boxSlider {
  overflow: hidden;
  position: relative;
  padding-left: 0px !important;
  .boxCards {
    position: inherit !important;
    padding-right: 0;
  }
  padding-bottom: 2rem;
}

.boxTitleCard {
  order: 2;
  margin-left: 1rem;

  @media screen and (min-width: 992px) {
    order: 1;
    margin-left: 0px;
  }
  .subTitleCard {
    font-size: 1.6rem;
    font-family: $font-regular;
    margin-bottom: 0.8rem;
    display: block;
    margin-left: 5px;
    @include themify('color', 'subtitle');
    font-weight: 600;
    @media screen and (min-width: 992px) {
      margin-bottom: 1.6rem;
      margin-left: 0px;
    }
  }
  .titleCard {
    @include themify('color', 'primary');
    font-size: 5rem;
  }

  .icon {
    @include themify('color', 'secondary');
    font-size: 15px;
    margin-left: 8px;
  }

  button {
    margin-left: -8px;
  }
}
.boxImg {
  opacity: 0.4;
  transition: all linear 0.2s;
  display: flex !important;
  justify-content: center;
  padding: 0 10px;
  max-width: 450px;
  width: 450px;
  height: auto;
  margin: auto;
  @media screen and (max-width: 600px) {
    width: 100%;
    max-width: 280px;
  }
}
.boxCards :global(.slick-current) .boxImg {
  opacity: 1;
}

.slider {
  position: inherit !important;
}

.slider :global(.slick-list) {
  @media screen and (max-width: 400px) {
    padding-left: 50px !important;
  }

  @media screen and (min-width: 400px) and (max-width: 450px) {
    padding-left: 30px !important;
  }
  @media screen and (min-width: 450px) and (max-width: 550px) {
    padding-left: 50px !important;
  }
  @media screen and (min-width: 550px) and (max-width: 768px) {
    padding-left: 60px !important;
  }
  @media screen and (min-width: 992px) and (max-width: 1370px) {
    padding-left: 110px !important;
  }
  @media screen and (min-width: 1370px) {
    padding-left: 100px !important;
  }
}

.boxCards {
  order: 1;
  @media screen and (min-width: 992px) {
    order: 2;
  }
}

.boxCards.theme-master :global(.slick-dots .slick-active) .boxDots {
  border: 1px solid;
  border-color: get-theme-color('master', 'secondary-secondary');
  
  .dots {
    background: get-theme-color('master', 'secondary-secondary');
  }
}

.boxCards.theme-afluent :global(.slick-dots .slick-active) .boxDots {
  border: 1px solid;
  border-color: get-theme-color('afluent', 'secondary-pressed');
  
  .dots {
    background: get-theme-color('afluent', 'secondary-pressed');
  }
}

.boxCards.theme-portoBank :global(.slick-dots .slick-active) .boxDots {
  border: 1px solid;
  border-color: get-theme-color('portoBank', 'secondary-pressed');
  
  .dots {
    background: get-theme-color('portoBank', 'secondary-pressed');
  }
}

.boxCards :global(.slick-dots) {
  list-style: none;
  position: absolute;
  left: 0px;
  top: 230px;

  @media screen and (min-width: 992px) {
    bottom: 0px;
  }
}

.boxCards.theme-master :global(.slick-dots) {
  li {
    &:hover {
      div {
        .dots {
          background: get-theme-color('master', 'secondary-hover');
        }
      }
    }
  }
}
.boxCards.theme-afluent :global(.slick-dots) {
  li {
    &:hover {
      div {
        .dots {
          background: get-theme-color('afluent', 'secondary-hover');
        }
      }
    }
  }
}

.boxCards.theme-portoBank :global(.slick-dots) {
  li {
    &:hover {
      div {
        .dots {
          background: get-theme-color('portoBank', 'secondary-hover');
        }
      }
    }
  }
}

.boxCards :global(.slick-dots li) {
  display: inline-block;
  margin: 0px 10px 0px 0px;
}

.boxDots {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.dots {
  width: 6px;
  height: 6px;
  display: block;
  @include themify('background-color', 'link');
  border-radius: 50%;
  border: none;

  &:focus {
    @include themify('outline-color', 'secondary-hover');
    @include themify('background-color', 'secondary-hover');
  }

  @media (forced-colors: active) {
    border: 1px solid;
  }
}

@media screen and (min-width: 1400px) {
  .boxTitleCard {
    flex: 0 0 35% !important;
    max-width: 35% !important;
  }
  .boxCards {
    flex: 0 0 65% !important;
    max-width: 65% !important;
  }
}
