@import '../../../styles/media_queries';

.container {
  max-width: 700px;

  .instructionsList {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }

  .uploadArea {
    padding: 48px 0;
  }

  .actionNextStep {
    margin-bottom: 3.2rem;
  }
}

@media (max-width: $width-small) {
  .container {
    .actionNextStep {
      .wrapperButton {
        width: 100%;
      }
    }
  }
}
