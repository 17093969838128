@import '../../../../../styles/colors';
@import '../../../../../styles/media_queries';
@import '../../../../../styles/themes/themes.scss';
@import '../../../../../styles/fonts';

.dependents {
  .titleArea {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    h2 {
      margin: 0;
      font-size: 25px;
      @include themify('color', 'primary');
    }
  }
  .flexReverse {
    flex-direction: row-reverse;
  }
  .dependentContent {
    border: 1px solid transparent;
    padding: 16px;
  }
  .dependentContentBorder {
    border: 1px solid;
    @include themify('border-color', 'primary');
    padding: 16px;
  }

  .dependentContentBorder.borderRed {
    @include themify('border-color', 'negative-graphics');
  }

  .errorLabel {
    font-size: 0.75em;
    color: $error-red-alert;
  }

  .footer {
    display: flex;
    width: 100%;

    &.paddingBottom {
      padding-bottom: 1.6rem;
    }

    button {
      display: flex;
      align-items: center;

      width: fit-content;
      margin-right: 8px;
      img {
        margin-right: 12px;
        width: 18px;
      }
    }

    .selectCheckboxWrapper {
      display: flex;
      align-items: center;
      margin-right: 16px;

      div {
        margin: 0;

        label {
          font-weight: 600;
          font-family: $font-regular;
        }
      }
    }

    @media (max-width: 560px) {
      flex-direction: column;
    }
  }

  .emptyMessage {
    @include themify('color', 'subtitle');
    font-size: 16px;
  }
  .divideFooter {
    margin: 2% 0 1%;
  }

  .divideItem {
    margin: 1% 0 4%;
  }
}

@media (max-width: $width-medium) {
  .dependents {
    .titleArea {
      h2 {
        font-size: 20px;
      }
    }
  }
}
