@import '../../../../../../../../styles/colors';
@import '../../../../../../../../styles/themes/themes.scss';
@import '../../../../../../../../styles/fonts';

.concernedItem {
  width: 100%;
  margin-bottom: 0.7rem;

  .item .footer button {
    margin-bottom: 24px;
    margin-left: -8px;
  }
  
  p {
    @include themify('color', 'const_gray');
  }

  .add {
    display: flex;
    justify-content: flex-end;
    button {
      margin-right: -8px;
    }
  }

  .elementRadio {
    @include themify('color', 'subtitle');
    font-size: 14px;
    .labelTitle {
      display: flex;
      margin: 20px 0px 14px;
      font-size: 16px;
      font-family: $font-regular;
      @include themify('color', 'link');
    }
  }

  .none {
    display: none;
  }
  .option {
    label {
      margin-bottom: 0px;
    }
  }

}
