@import '../../../../../../../../styles/colors';
@import '../../../../../../../../styles/themes/themes.scss';
@import '../../../../../../../../styles/fonts';

.incidentAreal {
  margin-top: 0.7rem;
  margin-bottom: 0.5rem;
  h3 {
    color: #333;
    font-size: 1.175rem;
    font-weight: 300;
    margin-top: 0.1rem;
    margin-bottom: 0;
  }
}
.labelTitle {
  font-size: 14px;
  @include themify('color', 'primary');
  margin-left: 15px;
}

.elementRadio {
  @include themify('color', 'tertiary');
  font-size: 14px;
  .labelTitle {
    @include themify('color', 'primary');
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 15px;
    font-family: $font-regular;
  }
}
.option {
}
