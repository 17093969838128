@import '../../../../styles/colors';
@import '../../../../styles/fonts';
@import '../../../../styles/media_queries';
@import '../../../../styles/themes/themes.scss';

.container {
  display: flex;
  flex: 1;
  justify-content: space-around;
  min-height: 25rem;
  flex-direction: column;
  padding: 3rem 1rem 3rem 1rem;

  &:not(:first-child) {
    border-top: 1px solid;
    @include themify('border-color', 'secondary-secondary');
  }

  h2 {
    @include themify('color', 'primary-primary');
    font-style: normal;
    font-size: 2.8rem;
  }

  span {
    @include themify('color', 'text-paragraph_subtle');
    font-style: normal;
    font-size: 1.8rem;
    margin-top: 20px;
  }

  .checkboxes {
    margin-top: 25px;
    .checkboxItem {
      margin-top: 25px;

      .title {
        font-size: 16px;
        font-weight: 600;
        color: $disabled;
        &.active {
          @include themify('color', 'primary-primary');
        }
      }
      .itemDescription {
        margin-left: 35px;

        span {
          font-size: 16px;
          color: $disabled;
          &.active {
            @include themify('color', 'primary-primary');
          }
        }
      }
    }
  }
}

@media (max-width: $width-small) {
  .container {
    padding: 3rem 1rem 3rem 1rem;

    h2 {
      font-size: 2.8rem;
    }

    span {
      font-size: 1.8rem;
    }
  }
}
