@import '../../../styles/style.scss';

.claims {
  .loadingContainer {
    display: flex;
    justify-content: center;
    margin-bottom: 5%;
    width: 100%;
  }

  .claimsHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 70px 0 40px;
    .title {
      h2 {
        font-size: 33px;
        font-weight: 300;
        @include themify('color', 'primary');
      }

      @media screen and (max-width: 992px) {
        h2 {
          font-size: 28px;
        }
      }

      @media screen and (max-width: 768px) {
        h2 {
          margin-top: 20px;
        }
      }
    }
  }

  @media (max-width: $width-medium) {
    padding: 0;
  }
}

@media (max-width: 990px) {
  .claims {
    .claimsHeader {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}
