@import '../../styles/style.scss';

.recentClaimsItem {
  display: flex;
  @include themify('background', 'text_and_background');
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  border-radius: 3px;
  margin-bottom: 15px;
  padding: 30px 21px;
  flex-direction: column;

  @media (forced-colors: active) {
    border: 1px solid;
  }

  @media screen and (min-width: 1300px) {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 20px;
  }

  .left {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 15px;
    @media screen and (min-width: 1300px) {
      width: 35%;
      flex-direction: row;
      justify-content: flex-start;
      margin-bottom: 0;
    }

    .icon {
      @include themify('color', 'text_active_element');
      order: 2;
      @media screen and (min-width: 1300px) {
        order: 1;
      }

      img {
        width: 50px;
        height: 50px;
      }
    }
    .text {
      @include themify('color', 'subtitle');
      display: flex;
      font-size: 16px;
      flex-direction: column;
      padding-right: 10px;

      order: 1;
      @media screen and (min-width: 1300px) {
        order: 2;
        padding-left: 4%;
        padding-right: 4%;
      }

      span {
        font-family: $font-semibold;
        font-weight: 600;
        line-height: 30px;
        word-break: break-word;
      }
      .moreInfo {
        font-family: $font-light;
        font-size: 1.6rem;
        font-weight: normal;
      }
    }
  }
  .disclaimer {
    @media screen and (max-width: 1300px) {
      display: flex;
      flex-direction: column;
      font-size: 14px;
      svg {
        align-self: center;
        margin-right: 10px;
      }
    }
  }

  .center {
    display: flex;
    flex-direction: column;
    width: 100%;
    @media screen and (min-width: 1300px) {
      width: 30%;
    }

    span {
      line-height: 30px;

      strong {
        font-family: $font-semibold;
        font-weight: 600;
      }
    }
  }

  .right {
    display: flex;
    padding-top: 15px;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    
    @media screen and (min-width: 1300px) {
      width: 35%;
      align-items: center;
      height: 40px;
      flex-wrap: nowrap;
      padding-top: 0;
    }

    .viewPDF {
      font-size: 16px;
      @include themify('color', 'primary');
      padding-right: 2rem;
      margin-right: 2rem;
      font-weight: bold;
      margin-bottom: 20px;
      display: block;
      order: 1;

      @media screen and (min-width: 1300px) {
        border-right: 1px solid;
        @include themify('border-color', 'text_and_background');
        margin-bottom: 0px;
        order: 1;
      }
      font-family: $font-regular;
    }

    .claimStatus {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: right;
      width: 100%;
      order: 3;
      flex: 1;
      @media screen and (min-width: 1300px) {
        width: auto;
        order: 2;
      }

      &:not(:only-child) {
        width: calc(100% - 60px);
        order: 2;

        @media screen and (max-width: $width-large) {
          justify-content: left;
        }

        @media screen and (max-width: 360px) {
          width: 100%;
          order: 3;
        }
      }
    }

    span {
      .rotateIcon {
        svg {
          transform: rotate(180deg);
        }
      }

      &.arrow {
        @include themify('color', 'text_active_element');
        margin-left: 30px;
        order: 2;
        cursor: pointer;
        @media screen and (min-width: 1300px) {
          order: 5;
        }
      }
    }

    .button {
      @media screen and (max-width: 1300px) {
        margin-top: 16px;
        width: 100%;
        button {
          width: 100%;
        }
      }
    }

    .buttonPDF {
      margin-right: 16px;
      @media screen and (max-width: 1300px) {
        margin-right: 0;
      }
    }
  }

  .bottom {
    align-self: flex-end;
    color: $primary-master;
    flex-grow: 1;
    width: 100%;
  }
}

.button {
  order: 3;
  @media screen and (min-width: 1300px) {
    order: 2;
  }

  @media screen and (max-width: 360px) {
    order: 3;
  }
}

.labelStatus {
  width: 180px;
  display: flex;
  justify-content: flex-start;
  @media screen and (min-width: 1300px) {
    justify-content: center;
  }
}

.moreInfo {
  font-family: $font-light;
  font-size: 1.6rem;
}

@media screen and (min-width: 1300px) {
  .buttonPDF {
    border-right: 1px solid transparent;
  }

  .verticalDivider {
    @include themify('border-right-color', 'secondary-secondary');
  }
}

@media screen and (max-width: 360px) {
  .buttonPDF {
    order: 2;
  }
}

@media screen and (max-width: 1060px) {
  .buttonPDF {
    border-right: 1px solid transparent;
  }
}

.hideButtons {
  .buttonPDF {
    display: none;
  }
  .buttonCreateClaim {
    display: none;
  }
  button {
    display: none;
  }
  .buttonEcard {
    display: none;
  }
}

.boxButton {
  display: flex;
  align-items: center;
  gap: 16px;
  border-top: 1px solid;
  @include themify('border-color', 'tertiary');

  .buttonCreateClaim {
    order: 1;
  }
  .buttonPDF {
    order: 3;
    border-right: none;
  }

  .buttonEcard {
    order: 4;
    display: flex;
    justify-content: stretch;
    align-items: center;
  }

  @media screen and (max-width: $width-medium) {
    flex-direction: column-reverse;

    .buttonPDF {
      display: flex;
      text-align: left;
    }

    div {
      width: 100%;
      button {  
        width: 100%; 
      }
    }
  }
}

.boxButton :global(.btnSelect) {
  button {
    @include themify('background', 'secondary');

    @include themify('color', 'primary');
    border: 2px solid;
    @include themify('border-color', 'secondary');
    font-weight: 600;
    &:hover {
      border: 2px solid;
      @include themify('border-color', 'primary');
    }
  }
}

.showMoreActive {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.genericButton {
  padding: 0 8px;
  @media (max-width: 1300px) {
    padding: 8px 0;
    width: 100%;
    .button, button {
      width: 100%;
    }
  }
}
.buttonLeftDivider {
  @include themify('border-right-color', 'secondary-secondary');
  border-right: 1px solid;
  @media (max-width: 1300px) {
    width: 100%;
    border-right: none;
  }
}

.containerButtons {
  display: flex;
  flex: 1;
  justify-content: flex-end;

  @media screen and (max-width: $width-small) {
    flex-direction: column;
  }
}

.linkEcard {
  text-align: center;
  flex: 1;
}

.createClaim {
  @media screen and (min-width: $width-medium) {
    width: 100%;
  }
}