@import '../../../../styles/style.scss';

.border {
  border-bottom: 1px solid;
}

.container {
  width: 100%;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  margin: 10px 0;
  padding: 0 2%;
  @include themify('background-color', 'surface-surface');
  border-radius: 3px;

  @media(min-width: 1200px){
    max-width: 1760px;
    margin-left: auto;
    margin-right: auto;
  }

  .accordionHeader {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    height: 76px;
    @include themify('border-color', 'const_gray');
    padding: 0 10px;
    cursor: pointer;

    .name {
      @include themify('color', 'subtitle');
      font-family: $font-regular;
      font-weight: 600;
      font-size: 1.6rem;
    }

    .moreInformation {
      display: flex;
      align-items: center;
      cursor: pointer;

      .icon {
        margin-left: 10px;
      }
    }
  }

  .content {
    margin-top: 30px;
    padding: 0 10px;
  }
}

.footer {
  width: 100%;
  padding-bottom: 1.6rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .divisor {
    margin: 0 0 1.6rem;
  }
}
