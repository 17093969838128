@import '../../styles/colors';
@import '../../styles/themes/themes.scss';

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 8rem;
  position: fixed;
  padding: 0 1rem;
  z-index: 1000;
  transition: all linear 0.4s;
  width: 100%;
  @media screen and (min-width: 400px) and (max-width: 1280px) {
    padding: 0 2rem;
  }

  @media screen and (min-width: 1280px) {
    padding: 0 9rem;
  }
}

.buttonArea {
  margin-left: 30px;
  @media (max-width: 992px) {
    display: none;
  }
}

.headerOnScroll {
  .header {
    @include themify('background', 'text_and_background');
    border-bottom: 1px solid ;
    @include themify('border-color', 'const_gray');
    @media screen and (min-width: 1280px) {
      padding: 0 9rem;
    }
  }
}

.positionRelative {
  position: relative;
  height: 8rem;
  width: 100%;
}

.headerLeft {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  @media screen and (min-width: 1400px) {
    width: 50%;
  }

  @media screen and (min-width: 992px) and (max-width: 1400px) {
    width: 30%;
  }
}

.headerRight {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  @media screen and (max-width: 992px) {
    width: 30%;
    flex: 1 1;
  }
}
