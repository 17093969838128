.addButtonContainer {
  display: flex;
  justify-content: flex-end;
  button {
    width: fit-content;
  }
}

.inputNoBackground {
  input {
    background-color: transparent !important;
  }
}