@import '../../../../../styles/colors';
@import '../../../../../styles/fonts';

.cardContainer {
  padding: 0 5%;
  margin-bottom: 20px;
  min-width: 240px;
  border-right: 2px solid #ccc;
  flex-basis: 33%;

  &:last-child {
    border-right: none;
  }

  .planName {
    color: $const_axa_blue;
    font-family: $font-semibold;
    font-size: 20px;
    text-align: center;
    margin-top: 15px;
  }
  .listBenefits {
    list-style-type: none;
    li {
      .boxTitleBenefit {
        text-align: center;
        border-top: 1px solid #ccc;
        margin-top: 15px;
        padding-top: 15px;
        .planTitleBenefit {
          font-size: 16px;
          font-family: $font-regular;
        }
        .planSubtitleBenefit {
          font-size: 16px;
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .cardContainer {
    flex-basis: 50%;
    border-right: none;

    &:first-child {
      border-right: 2px solid #ccc;
    }
  }
}

@media (max-width: 992px) {
  .cardContainer {
    .planName {
      font-size: 18px;
    }
  }
}

@media (max-width: 720px) {
  .cardContainer {
    flex-basis: 100%;

    &:first-child {
      border-right: none;
    }
  }
}
