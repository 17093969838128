@import '../../../../styles/themes/themes.scss';
@import '../../../../styles/media_queries';
@import '../../../../styles/fonts';

.axaButton {
  width: 100%;
  margin-top: 16px;
}

.isDetail + a.axaButton {
  button {
    width: 100% !important;
    max-width: none;
    min-width: 180px;
    padding: 5px;
    margin: 5px 0 0 0;
    display: inline-block;
  }
}

.boxButtons.default {
  display: flex;
  flex-direction: column;
  width: 100%;

  button, a {
    width: 100%;
    margin-top: 8px;
  }
}

.boxButtons.isDetail {
  width: 100%;
  flex-direction: column;
  align-items: center;

  button,
  a {
    margin: 16px 0 0 0;
    width: 100% !important;
    max-width: none;
    min-width: 180px;
  }
}

.boxButtons.buttonsinBlueBox {
  width: 100%;
  span {
    margin-right: 10px;
  }
  a {
    @include themify('color', 'secondary');
    width: auto;
  }

  .divider {
    @include themify('background', 'secondary');
  }
}

.boxButtons.isDetail.buttonsinBlueBoxDefault {
  width: 100%;
  a {
    @include themify('color', 'secondary');
    width: 100% !important;

    button {
      width: 100% !important;
      margin: 20px 0 0 0;
    }
  }
}

div.boxButtons a.whatsappButton {
  background-color: #61c554 !important;

  &:hover, &:focus-visible {
    background-color: #58b34c !important;
  }

  &:active {
    background-color: #458c3c !important;
  }
}

.upsellAreaTitle {
  padding: 0 !important;
  font-size: 18px !important;
  font-weight: normal !important;
  font-family: $font-regular;
}

.contentContainer {
  .personalInfocontainer {
    padding: 20px 10px;
    margin: 30px 0px;
    border: 1px solid rgba(20, 52, 203, 0.4);
    .fieldContainer {
      display: flex;
      flex-direction: row;

      font-size: 16px;
      p {
        margin: 0px;
      }
    }
  }

  h4 {
    font-size: 14px;
    font-style: italic;
  }
}
