@import '../../styles/colors';
@import '../../styles/fonts';
@import '../../styles/themes/themes.scss';

.container {
  @include themify-half-color-gradient();

  .loadingContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
  }
}

.formWrapper {
  @include themify('background-color', 'surface-surface');
  border-radius: 5px;
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1);
  padding: 40px;
  width: 100%;
  z-index: 1;

  @media (max-width: 992px) {
    padding: 20px;
  }
}
