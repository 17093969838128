@import '../../../styles/media_queries';

.loadingCenter {
  display: flex;
  justify-content: center;
}

.showDetails {
  margin-top: 15px;
  padding: 3rem 3rem 0 0;

  @media screen and (max-width: $width-small) {
    margin-top: 0px;
  }
}
