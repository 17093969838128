@import '../../styles/style.scss';

.showDetails {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 12px;
  padding: 2rem 3rem 0 0rem;
  border-top: 1px solid;
  @include themify('border-color', 'tertiary');
  div {
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
    flex: 1;
    div {
      display: flex;
      flex-direction: column;
      h6 {
        font-size: 16px;
        margin-bottom: 1rem;
      }
      h5 {
        font-size: 16px;
        font-weight: bold;
      }
    }
  }
  @media screen and (max-width: $width-small) {
    div {
      flex-direction: column;
    }
  }
}

.subTitleRecentCertificates {
  @include themify('color', 'primary');
  font-weight: normal;
  margin-bottom: 2rem;
  font-size: 2rem;
}

.subTitleRecentCertificates2 {
  @include themify('color', 'tertiary');
  font-weight: normal;
  margin-bottom: 2rem;
  font-size: 2rem;
}

.button {
  width: 100%;
  order: 3;

  @media screen and (min-width: $width-large) {
    width: 120px;
    order: 2;
  }
  @media screen and (min-width: 1380px) {
    width: 180px;
  }
}
