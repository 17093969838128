@import '../../../styles/colors';
@import '../../../styles/themes/themes.scss';

.cardItem {
  width: 39rem;
  margin: 0 auto 2rem;
  height: auto;
  border-radius: 2px;
  border: 1px solid;
  @include themify('border-color', 'tertiary');
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 1.3rem;
  padding: 2rem 0rem 2rem 0rem;
  background: #fff;

  @media screen and (max-width: 760px) {
    width: 95%;
  }

  img {
    margin: 0px 1.2rem;
    width: 5.3rem;
    height: 3.2rem;
  }
}

.borderGold {
  border: 1px solid;
  @include themify('border-color', 'secondary-secondary');
  cursor: initial;
}

.bin {
  strong {
    width: 100%;
  }
}

.bank {
  font-size: 1.2rem;
}
