.divider {
  margin-bottom: 1.8rem;
  scroll-margin-top: 100px;
}

.button {
  min-width: 240px;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 30px;

  @media screen and (min-width: 900px) {
    width: 180px;
    order: 2;
  }

  @media screen and (max-width: 360px) {
    min-width: auto;
    order: 3;
  }
}
