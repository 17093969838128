@import '../../../../../styles/themes/themes.scss';
@import '../../../../../styles/fonts';

.listItem {
  display: flex;
  justify-content: center;
}

.boxPlans {
  min-width: 240px;
  @include themify('background', 'surface-surface');
  border-radius: 5px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  padding: 25px;
  margin-bottom: 30px;
  z-index: 1;
  text-align: center;
  border: 1px solid transparent;

  .planName {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 60px;
    margin-top: 20px;
    @include themify('color', 'primary-primary');
    h2 {
      text-align: center;
      font-size: 22px;
      font-family: $font-regular;
    }
  }

  .planListBenefit {
    list-style: none;
    margin: 20px 0px 5px;
    li {
      border-bottom: 1px dashed;
      @include themify('color', 'primary-primary');
      margin: 0 0 10px;
      width: 100%;
      .planTitleBenefit {
        h2 {
          @include themify('color', 'text-paragraph_subtle');
          font-size: 16px;
          margin: 0px;
          font-weight: 600;
        }
      }

      .planSubtitleBenefit {
        h3 {
          @include themify('color', 'text-paragraph_subtle');
          font-size: 16px;
        }
      }
      .boxTitleBenefit {
        padding-top: 5px;
      }
    }
    li:last-child {
      border-bottom: none;
      margin-bottom: 0px;
    }
  }

  .planValueBenefit {
    @include themify('color', 'primary-primary');
    .planBoxFinalValue {
      display: flex;
      justify-content: center;
    }
    .planFinalValueInteger {
      font-size: 26px;
      font-family: $font-regular;
    }
    .planFinalValueDecimal {
      font-size: 20px;
      font-family: $font-regular;
      align-self: center;
    }
    .planSymbolValue {
      margin-left: 5px;
      font-size: 26px;
    }
  }
  .planMoreBenefits {
    @include themify('color', 'primary-primary');
    font-size: 13px;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 5px;
    span {
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.boxPlans.planSelected{
  @include themify('border-color', 'primary-primary');
}

.btnSelectedPlan {
  margin-top: 0.8rem;
  button {
    width: 100%;
  }
}

.textForAll {
  font-family: $font-regular;
  font-size: 12px;
  line-height: 15px;
  @include themify('color', 'primary-primary');
}
