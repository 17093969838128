@import '../../styles/style.scss';
@import '../../styles/fonts';

.headingTitle.axa {
  display: flex;
  flex-direction: column;
  padding: 4rem 9rem;
  @include themify('color', 'text_and_background');
  @include themify('background-color', 'const_axa_blue');

  .upArea {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    width: 100%;
    .box {
      display: flex;
      align-items: center;

      h4 {
        font-size: 16px;
        font-family: $font-regular;
        margin: 0;
      }
    }

    img.litleLogo {
      display: none;
      margin-left: 20px;
      width: 50px;
      height: 50px;
    }
  }

  .descriptionArea {
    display: flex;
    justify-content: space-between;

    .text {
      flex: 1 1;

      h1 {
        font-size: 45px;
        margin-bottom: 20px;
      }

      p {
        font-family: $font-light;
        font-size: 18px;
        width: 90%;
        white-space: normal;
        margin: 0;
      }
    }

    img.bigLogo {
      width: 120px;
      height: 120px;
    }
  }

  @media (max-width: 720px) {
    flex-direction: column;
    align-items: flex-start;
    img {
      width: 70px;
    }
  }
}

@media (max-width: 992px) {
  .headingTitle.axa {
    padding: 3rem 2rem;
    .upArea {
      .box {
        .linkBack {
          position: static;
        }
      }

      img.litleLogo {
        display: block;
      }
    }
    .descriptionArea {
      flex-direction: column;
      .text {
        h1 {
          font-size: 33px;
        }
        p {
          display: none;
        }
      }
      img.bigLogo {
        display: none;
      }
    }
  }
}
