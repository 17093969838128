@import '../../../../styles/themes/themes.scss';
@import '../../../../styles/fonts';

.boxPlans :global(.slick-track) {
  display: flex !important;
  justify-content: center;
}

.boxPlans :global(.slick-slide) {
  display: flex !important;
  justify-content: center;
}

.plansMobile {
  position: relative;
  .btnArrow {
    position: absolute;
    top: 50%;
    z-index: 999;
    cursor: pointer;
  }

  .btnLeft {
    left: 30px;
  }
  .btnRight {
    right: 30px;
  }

  @media screen and (max-width: 400px) {
    .btnLeft {
      left: 5px;
    }
    .btnRight {
      right: 5px;
    }
  }
}

.list {
  list-style-type: none;
  display: flex;
  justify-content: space-around;
}

.subtitle {
  text-align: center;
  margin-bottom: 30px;
  font-size: 16px;
  font-family: $font-regular;
  @include themify('color', 'subtitle');
}

.productNotFound {
  font-size: 2.4rem;
  text-align: center;
  margin: 1rem 0px;
  @include themify('color', 'const_axa_blue');
}

.footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid;
  border-color: get-theme-color('axa', 'surface-inverse');
  margin-top: 46px;
  padding-top: 25px;

  .divButton {
    width: 180px;

    button {
      width: 100%;
    }
  }

  @media (max-width: 575px) {
    .divButton {
      button {
        width: 95%;
      }
    }
    .divButton + .divButton {
      display: flex;
      justify-content: flex-end;
    }
    .divButton {
      width: 100%;
    }
  }
}
