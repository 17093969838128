@import '../../../../../../../styles/colors';
@import '../../../../../../../styles/fonts';
@import '../../../../../../../styles/themes/themes.scss';

.title {
  color: #000;
  font-size: 1.4rem;
  font-weight: 300;
  margin-bottom: 0;
}

.radioButtonError {
  .error {
    border-bottom-color: $error-red-alert;
    &:focus {
      border-bottom-color: $error-red-alert;
    }
  }
  .errorLabel {
    font-size: 0.75em;
    color: $error-red-alert;
    font-family: $font-regular;
  }
}
.elementRadio {
  @include themify('color', 'tertiary');
  font-size: 14px;
  .labelTitle {
    @include themify('color', 'primary');
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 15px;
    font-family: $font-regular;
  }
}

.noPadding {
  padding: 0px !important;
}

.none {
  display: none;
}
