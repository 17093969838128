@import '../../../../../../../../styles/themes/themes.scss';

.noFlightsAlert {
  font-size: 1.6rem;
  @include themify('color', 'negative-text');
}

.loadingContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}