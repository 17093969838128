@import '../../../styles/colors';
@import '../../../styles/fonts';
@import '../../../styles/themes/themes.scss';
@import '../../../styles/media_queries';

.tokenex {
  width: 100%;
}

.visaWarning {
  font-size: 13px;
}

.boxForm {
  .tokenexIframe {
    height: 60px;
    margin-top: 1px;
  }
}

.btnFooter {
  display: flex;
  width: 100%;
  margin-top: 3.2rem;
  gap: 0.8rem;
}

@media (max-width: $width-medium) {
  .btnFooter {
    button {
      width: 100%
    }
  }
}

@media (max-width: $width-small) {
  .btnFooter {
    flex-direction: column;
  }
}
