@import '../../../../../../../../styles/themes/themes.scss';

.personalArea {
  .fontGray12 {
    @include themify('color', 'tertiary');
    font-size: 14px;
    .labelTitle {
      @include themify('color', 'subtitle');
      font-size: 1.2rem;
      font-weight: 300;
      margin-bottom: 15px;
    }
  }
  .none {
    display: none;
  }
  .options {
    .option {
    }
  }
}
