@import '../../styles/style.scss';

.menuNavigation {
  display: flex;
  align-items: center;

  a {
    color: inherit;
    margin-right: 2.3rem;
    font-size: 1.5rem;
    border-bottom: 1px solid transparent;

    @media screen and (max-width: 992px) {
      margin-right: 1rem;
    }
    @media screen and (min-width: 1100px) {
      margin-right: 3.3rem;
      font-size: 1.6rem;
    }
    &:hover, &.active {
      color: inherit;
      text-decoration: none;
      border-bottom: 1px solid;
      @include themify('border-bottom-color', 'secondary-inverse');

      &.inverse {
        @include themify('border-bottom-color', 'secondary-secondary');
      }
    }
  }
  .menuOffer {
    margin-right: 1rem;
  }
  @media screen and (min-width: 400px) {
    .menuOffer {
      margin-right: 1.5rem;
    }
  }

  .borderHelp {
    position: relative;
    
    @include themify('background', 'secondary-inverse', '::before');
    @include themify('background', 'secondary-inverse', '::after');

    &.inverse {
      @include themify('background', 'secondary-secondary', '::before');
      @include themify('background', 'secondary-secondary', '::after');
    }

    &::after,
    &::before {
      content: '';
      width: 0.1rem;
      height: 1.3rem;
      position: absolute;
      top: 5px;
    }
    
    &::before {
      left: -1.65rem;
    }
    
    &::after {
      right: -1.65rem;
    }
  }
}

