@import '../../styles/style.scss';
@import '../../styles/themes/themes.scss';
@import '../../styles/colors';

.button {
  width: 100%;
  height: 56px;
  font-family: $font-regular;
  font-weight: 600;
  font-size: 16px;
  outline: none;
  transition: all linear 0.2s;
}

.borderGold {
  background: transparent;
  @include themify('color', 'secondary-secondary');
  border: 2px solid;
  @include themify('border-color', 'secondary-secondary');
  &:hover, &:focus {
    @include themify('color', 'primary-primary');
    @include themify('background', 'secondary-secondary');
  }
}

.borderRed {
  background: transparent;
  color: $error-red-alert;
  border: 2px solid $error-red-alert;
  &:hover, &:focus {
    color: white;
    background: $error-red-alert;
  }
}

.blue {
  @include themify('background', 'primary-primary');
  color: $white;
  border: 2px solid;
  @include themify('border-color', 'primary-primary');
  font-weight: 600;
  &:hover, &:focus {
    border: 2px solid;
    color: $white;
  }
}

.borderBlue {
  background: transparent;
  @include themify('color', 'primary-primary');
  font-family: $font-regular;
  font-weight: 600;
  border: 2px solid;
  @include themify('border-color', 'primary-primary');
  &:hover, &:focus {
    @include themify('color', 'text_and_background');
    @include themify('background', 'primary-primary');
  }
}

.axaBlue {
  @include themify('background', 'const_axa_blue');
  @include themify('color', 'text_and_background');
  font-family: $font-regular;
  font-weight: 600;
  border: 2px solid;
  @include themify('border-color', 'const_axa_blue');
  &:hover, &:focus {
    border: 2px solid;
    color: $white;
  }
}

.axaBlueBorder {
  background: transparent;
  @include themify('color', 'const_axa_blue');
  font-family: $font-regular;
  font-weight: 400;
  border: 2px solid;
  @include themify('border-color', 'const_axa_blue');
  &:hover, &:focus {
    @include themify('color', 'const_axa_blue');
    @include themify('background', 'const_gray');
    @include themify('border-color', 'const_gray');
  }
}

.axaWhiteBorder {
  background: transparent;
  color: white;
  font-family: $font-regular;
  font-weight: 400;
  border: 2px solid;
  border-color: white;
  &:hover, &:focus {
    @include themify('color', 'const_axa_blue');
    background: white;
    @include themify('border-color', 'const_axa_blue');
  }
}

.axaBlueWithoutBorder {
  background: transparent;
  @include themify('color', 'const_axa_blue');
  border: 2px solid transparent;
  &:hover, &:focus {
    text-decoration: underline;
  }
}

.white {
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  color: $white;
  border: 2px solid $white;
}
.whiteWhitoutBorder {
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  color: $white;
  border: 2px solid transparent;
}

.gold {
  @include themify('background', 'secondary-secondary');
  color: $white;
  border: 2px solid;
  @include themify('border-color', 'secondary-secondary');
  &:hover, &:focus {
    border: 2px solid;
  }
}

.noBorder {
  border: none !important;
}

.goldWithoutBorder {
  background: transparent;
  @include themify('color', 'primary-primary');
  border: 2px solid transparent;
  &:hover, &:focus {
    text-decoration: underline;
  }
}

.blueWithoutBorder {
  background: transparent;
  @include themify('color', 'primary-primary');
  border: 2px solid transparent;
  font-weight: 600;
  &:hover, &:focus {
    text-decoration: underline;
  }
}

.whatsappButton {
  background: #61c554;
  color: $white;
  border: 2px solid #61c554;
  font-weight: 600;
  &:hover, &:focus {
    border-color: $white;
  }
}

.disabled {
  @include themify('background', 'disabled-surface');
  border: none !important;
  @include themify('color', 'disabled-text');
}
