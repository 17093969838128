@import '../../../../styles/colors';
@import '../../../../styles/_fonts.scss';
@import '../../../../styles/themes/themes.scss';

.menuCards {
  .menuList {
    width: 100%;
    height: fit-content;
    padding-bottom: 5px;
    margin-bottom: 25px;

    ul {
      list-style: none;
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-bottom: 0;
      gap: 1.6rem;
      transition: all 0.5s;
    }

    .menuActive {
      font-family: $font-semibold;
    }
  }

  .submenuList {
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-top: 10px;

    ul {
      list-style: none;
      gap: 0.8rem;
      transition: all 0.5s;
    }

    .submenuActive {
      border-left: 2px solid;
      @include themify('border-left-color', 'secondary-secondary');
      font-family: $font-semibold;
    }
  }

  @media (max-width: 992px) {
    padding-left: 0;
    padding-right: 0;
    .menuLeft {
      display: none;
    }
    .menuList {
      border-bottom: 1px solid;
      @include themify('border-color', 'const_gray');
      ul {
        flex-direction: row;
        margin-left: 20px;
        li {
          margin-right: 0.8rem;

          .hideMobile {
            display: none;
          }
        }
      }
    }
  }
}
