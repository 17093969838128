@import '../../../styles/colors';
@import '../../../styles/fonts';
@import '../../../styles/media_queries';
@import '../../../styles/themes/themes.scss';

.container {
  .contentOffers {
    margin: 0 1%;
  }

  .contentUpgradeMe {
    padding: 0 6%;
    margin-bottom: 100px;
  }

  @media (max-width: 990px) {
    .contentAllNotifications {
      padding: 0 2rem;
    }
  }
}

.showMore {
  width: 100%;
  margin: 3.2rem 0;
  margin-top: calc(3.2rem - 1.5%);
  display: flex;
  justify-content: center;
  align-items: center;
}
