@import '../../../../../../../../styles/themes/themes.scss';
@import '../../../../../../../../styles/colors';

.certificationArea {
  .none {
    display: none;
  }
  .checkbox {
    @include themify('color', 'tertiary');
    font-weight: 400;
  }
}
.legalAdviceAndConsent {
  display: flex;
  align-items: center;
}

.labelTitle {
  font-size: 16px;
  @include themify('color', 'subtitle');
  margin-bottom: 24px;
}
