@import '../../../../styles/style.scss';

.container {
  margin-top: 40px;
  @include themify('color', 'primary-primary');
  .termsArea {
    margin-bottom: 30px;
    h2 {
      font-size: 26px;
      @include themify('color', 'primary-primary');
    }
    .maxCoverageArea {
      margin-top: 30px;
      h3 {
        @include themify('color', 'text-paragraph_subtle');
        font-weight: bold;
      }
      .secondaryMaxCoverage {
        margin-top: 20px;
      }
    }

    .pdf {
      display: flex;
      margin-top: 30px;
      margin-left: 15px;
      img {
        margin-right: 30px;
      }

      .pdfIcon {
        margin-right: 30px;
      }

      .pdfInfo {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
      }
    }

    .disclaimer {
      @include themify('color', 'text-paragraph_subtle');
      margin: 30px 0 0;
      text-align: justify;

      svg {
        margin-right: 10px;
      }
    }
  }
  .paragraphArea {
    color: black;
  }
  .acceptArea {
    margin: 20px 0 40px;
    padding: 25px 0 25px 15px;
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    width: 100%;

    button {
      width: 180px;
    }
  }
}

.paragraphArea {
  span {
    a {
      text-decoration: underline;
      font-family: 'visa_dialect_regular';
      font-weight: 400;
    }

    @include themify('color', 'primary-primary', ' a');

    @include themify('background-color', 'surface-hover', ' a:hover');
    @include themify('color', 'primary-hover', ' a:hover');

    @include themify('background-color', 'surface-hover', ' a:focus-visible ');
    @include themify('color', 'primary-hover', ' a:focus-visible');
    @include themify('outline-color', 'primary-primary', ' a:focus-visible');

    @include themify('color', 'primary-pressed', ' a:active');
    @include themify('background-color', 'surface-pressed', ' a:active');
  }
}
