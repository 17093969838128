@import '../../styles/style.scss';

.menuCountry {
  display: flex;
  align-items: center;
  @media (max-width: 992px) {
    display: none;
  }
}

.buttonCountry {
  background: transparent;
  border: none;
  color: $white;
  font-size: 1.6rem;
}

