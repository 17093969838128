@import '../../../../styles/style.scss';

.container {
  border-top: 1px solid;
  @include themify('border-color', 'secondary-secondary');
  padding: 3rem 1rem 3rem 1rem;

  .titleArea {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 35px;

    h2 {
      margin: 0;
      margin-bottom: 5px;
      font-size: 25px;
      @include themify('color', 'primary-primary');
    }
  }

  .text {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 20px;
    @include themify('color', 'subtitle');
    font-style: normal;
    font-size: 1.8rem;
  }

  .content {
    margin-top: 25px;
    .reimbursementItem {
      border-bottom: 1px solid;
      @include themify('border-color', 'text-paragraph_subtle:');
      margin-bottom: 40px;

      &:last-child {
        border-bottom: none;
        margin-bottom: 0;
      }

      .footer {
        margin: 0.8rem 0;
        display: flex;
        width: 100%;

        button {
          margin-left: -1rem;
        }
      }
    }
    .emptyMessage {
      @include themify('color', 'subtitle');
      font-size: 16px;
    }
  }
}

@media (max-width: 768px) {
  .container {
    .titleArea {
      h2 {
        font-size: 20px;
      }
    }
  }
}
