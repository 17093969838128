@import '../../../../../../styles/colors';
@import '../../../../../../styles/themes/themes.scss';

.legalAdviceAndConsent {
  .content {
    margin-bottom: 2%;
    h4 {
      font-size: 16px;
      font-weight: 300;
      margin-bottom: 24px;
      @include themify('color', 'subtitle');
    }
    p {
      font-size: 16px;
      font-weight: 300;
      margin-bottom: 24px;
      @include themify('color', 'subtitle');
    }
    h5 {
      font-size: 16px;
      font-weight: 300;
      margin-bottom: 45px;
      @include themify('color', 'subtitle');
    }
  }
  .checkbox {
    @include themify('color', 'const_gray');
    font-weight: 400;
    margin-bottom: 40px;

    .itemCheckBox {
      margin-bottom: 27px;
      line-height: 24px;
    }
  }
}
