@import '../../../../../../../../styles/colors';
@import '../../../../../../../../styles/themes/themes.scss';
@import '../../../../../../../../styles/fonts';

.personalArea {
  .fontGray12 {
    @include themify('color', 'tertiary');
    font-size: 14px;
    .labelTitle {
      @include themify('color', 'subtitle');
      font-size: 1.2rem;
      font-weight: 300;
    }
  }
  .none {
    display: none;
  }

  .elementRadio {
    @include themify('color', 'subtitle');
    font-size: 14px;
    .labelTitle {
      display: flex;
      margin-bottom: 15px;
      font-size: 16px;
      font-family: $font-regular;
      @include themify('color', 'link');
    }
  }
}
