@import '../../../../styles/style.scss';

.contentInfo{
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;

}

.imageInfo {
  width: 40%;
  img {
    max-width: 100%;
  }
}

.cardInfo{
  width: 60%;
}

@media screen and  (max-width: 992px) {
  .imageInfo{
    display: none
  }
}

@media screen and  (min-width: 992px) and (max-width: 1100px){
  .imageInfo{
    width: 30%;
  }
  .cardInfo{
    width: 70%;
  }
}
